import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Progress } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import {
    createDataSource,
    createGridOptions,
    DataGrid,
    indexCellRenderer,
    DataGridRefreshButton,
    refreshDataGrid,
    LinkCellRenderer,
    TextFilterDefaults,
} from '../common/dataGrid/DataGrid';
import { Can } from '../Can';
import CommonContext, { ApiRoutes, agGridConstants } from '../Common';
import {
    FlexRow,
    FlexStartRow,
    FlexEndRow,
    PageHeading,
    SmallButton,
    onReactSelectChanged,
    onFieldChange,
    PageWrap,
    toasty,
} from '../common/forms/FormElements';
import ComplianceTypeForm from './ComplianceTypeForm';
import { util } from '../Util';

class ComplianceTypeIndex extends React.Component {
    // bugbug: the index/edit components were borrowed from an outdated component,
    // so don't go patterning other pieces off of this
    static contextType = CommonContext;

    constructor(props) {
        super(props);

        const params = new URLSearchParams(props.location.search);
        const openNew = params.get('new') === 'true';

        this.state = {
            loading: true,
            types: [],
            selectedComplianceType: openNew ? {} : null,
            showComplianceTypeForm: openNew,
        };
        this.onChange = this.onChange.bind(this);
        this.refreshGrid = this.refreshGrid.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
    }

    componentDidMount() { return this.populateState(); }

    onAddComplianceType = () => {
        this.setState({
            selectedComplianceType: {},
            showComplianceTypeForm: true,
        });
    };

    onChange = onFieldChange;

    onComplianceTypeDeleteCallback = () => {
        this.onComplianceTypeFormClosed();

        this.state.gridApi.setDatasource(this.state.dataSource);
    };

    onComplianceTypeFormClosed = () => {
        this.setState({
            selectedComplianceType: null,
            showComplianceTypeForm: false,
        });
    };

    onComplianceTypeSaveCallback = () => {
        this.setState({ saving: true });
        const { selectedComplianceType } = this.state;

        if (selectedComplianceType.id) {
            this.updateComplianceType();
        } else {
            this.createComplianceType();
        }
    };

    onEditComplianceType = async (complianceTypeId) => {
        const selectedComplianceType = await util.fetch.js(
            ApiRoutes.complianceTypes.byId(complianceTypeId),
        );

        this.setState({
            selectedComplianceType,
            showComplianceTypeForm: true,
        });
    };

    onSelectChange = onReactSelectChanged;

    booleanTranslator = (paramValue) => {
        const value = Boolean(paramValue && paramValue.value);

        return value ? 'Yes' : 'No';
    };

    clearErrors = () => this.setState((state) => ({ errors: {} }));

    complianceTypeForTranslator = (params) => {
        const complianceType = params.node.data;

        if (complianceType) {
            if (
                complianceType.complianceEquipmentTypeIds
                && complianceType.complianceEquipmentTypeIds.length > 0
            ) {
                return 'Equipment Types';
            } if (
                complianceType.complianceApplicationUserGroupIds
                && complianceType.complianceApplicationUserGroupIds.length > 0
            ) {
                return 'Groups';
            }
        }

        return '';
    };

    createComplianceType = async () => {
        const { selectedComplianceType } = this.state;

        const response = await util.fetch
            .post(ApiRoutes.complianceTypes.create(), selectedComplianceType)
            .catch(this.handleSaveError);

        if (response && response > 0) {
            this.notifySuccess();
            this.setState({
                selectedComplianceType: null,
                showComplianceTypeForm: false,
                saving: false,
            });
            this.state.gridApi.setDatasource(this.state.dataSource);
        } else {
            this.notifyError();
            this.setState({ saving: false });
        }
    };

    // TODO: glamourize this
    handleSaveError = (error) => {
        console.error(error);
        alert(`Unable to save Compliance: ${error.toString()}`);
    };

    notifyError = () => toasty.error(
        'Save Unsuccessful',
        'There was a server error saving this compliance type.  Please try your request again or contact support for assistance.',
    );

    notifySuccess = () => toasty.success(
        'Compliance Type Saved',
        'Compliance type saved successfully.',
    );

    async populateState() {
    // Get grid defaults and handlers
        const gridOptions = createGridOptions(this);

        gridOptions.components = {
            nameRenderer: LinkCellRenderer,
        };

        // Provide column definitions
        gridOptions.columnDefs = [
            {
                headerName: '',
                valueGetter: 'node.id',
                sortable: false,
                cellRenderer: indexCellRenderer,
            },
            {
                colId: 'ComplianceName',
                sortable: true,
                headerName: 'Name',
                field: 'name',
                filter: agGridConstants.columnFilterTypes.text,
                floatingFilter: true,
                filterParams: TextFilterDefaults,
                sort: { direction: 'asc', priority: 0 },
                cellRenderer: 'nameRenderer',
                cellRendererParams: {
                    clicked: this.onEditComplianceType,
                    nameField: 'name',
                    idField: 'id',
                    title: 'View this Compliance Type',
                },
            },
            {
                colId: 'isActive',
                sortable: false,
                headerName: 'Active?',
                field: 'active',
                cellRenderer: this.booleanTranslator,
            },
            {
                colId: 'appliesToAll',
                sortable: false,
                headerName: 'Applies to All Dispatches?',
                field: 'appliesToAllDispatches',
                cellRenderer: this.booleanTranslator,
            },
            {
                colId: 'warnWhenMissing',
                sortable: false,
                headerName: 'Warn When Missing?',
                field: 'warnWhenMissing',
                cellRenderer: this.booleanTranslator,
            },
            {
                colId: 'complianceFor',
                sortable: false,
                headerName: 'For',
                cellRenderer: this.complianceTypeForTranslator,
            },
        ];

        // Create datasource.  Is reused and rebound on refresh from the state.
        const dataSource = createDataSource(
            ApiRoutes.complianceTypes.search(),
            gridOptions,
        );

        this.setState((state) => ({
            loading: false,
            gridOptions,
            dataSource,
        }));
    }

    refreshGrid = () => refreshDataGrid;

    updateComplianceType = async () => {
        const { selectedComplianceType } = this.state;

        const response = await util.fetch
            .post(ApiRoutes.complianceTypes.update(), selectedComplianceType)
            .catch(this.handleSaveError);

        if (response && response > 0) {
            this.notifySuccess();
            this.setState({
                selectedComplianceType: null,
                showComplianceTypeForm: false,
                saving: false,
            });
            this.state.gridApi.setDatasource(this.state.dataSource);
        } else {
            this.notifyError();
            this.setState({ saving: false });
        }
    };

    render() {
        if (this.state.loading) return <Progress />;

        const {
            rowData,
            gridOptions,
            showComplianceTypeForm,
            selectedComplianceType,
        } = this.state;

        return (
            <PageWrap>
                <PageHeading>
                    <FontAwesomeIcon
                        icon={faClipboardList}
                        className="mr-2 text-muted"
                    />
                    <span>Compliance Types</span>
                </PageHeading>
                <Row>
                    <Col>
                        <FlexRow>
                            <FlexStartRow>
                                <DataGridRefreshButton
                                    gridApi={this.state.gridApi}
                                    dataSource={this.state.dataSource}
                                />
                            </FlexStartRow>
                            <FlexEndRow>
                                <Can I="create" a="compliancetype">
                                    <CommonContext.Consumer>
                                        {(value) => (
                                            <SmallButton
                                                type="button"
                                                disabled={
                                                    showComplianceTypeForm
                                                }
                                                onClick={
                                                    this.onAddComplianceType
                                                }
                                            >
                                                <i className="fa fa-plus-circle fa-md mr-2" />
                        Add Compliance Type
                                            </SmallButton>
                                        )}
                                    </CommonContext.Consumer>
                                </Can>
                            </FlexEndRow>
                        </FlexRow>
                    </Col>
                </Row>
                <DataGrid
                    domLayout="normal"
                    rowData={rowData}
                    gridOptions={gridOptions}
                />
                <ComplianceTypeForm
                    selectedComplianceType={selectedComplianceType}
                    onChange={this.onChange}
                    onSelectChange={this.onSelectChange}
                    onClose={this.onComplianceTypeFormClosed}
                    show={showComplianceTypeForm}
                    onSaveCallback={this.onComplianceTypeSaveCallback}
                    onDeleteCallback={this.onComplianceTypeDeleteCallback}
                />
            </PageWrap>
        );
    }
}

export default withRouter(ComplianceTypeIndex);
