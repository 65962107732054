import React, { Fragment } from 'react';
import cls from 'classnames';
import {
    Button,
    Col,
    Container,
    Popover,
    PopoverHeader,
    PopoverBody,
    Row,
    Collapse,
    Spinner,
    Progress,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCaretRight,
    faCaretDown,
    faCaretUp,
    faCheckCircle,
    faExclamationTriangle,
    faInfoCircle,
    faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import _ from 'lodash-es';
import { util } from '../../Util';
import ValidationMessageDisplay from './ValidationMessageDisplay';
import CommonContext from '../../Common';
import { Can } from '../../Can';

export function formIsReady() {
    return (
        !this.state?.loading === false
        && !!this.context?.tenant?.tenantSettings
        && !!this.context?.permissions
    );
}

export function getTenantSetting(settingName) {
    const name = _.camelCase(settingName);
    return this.context.tenant.tenantSettings[name].value;
}

/**
 * Generic handler for form fields.  Inputs must have the same name as the property on the object.
 * You must also bind the target method to your class.  Also requires that the name of the input
 * matches your object property hierarchy (ex. entity.prop1.prop2).
 *
 * Updated: RLC 11/2/20
 *
 * @param {any} e
 */
export function onFieldChange(e) {
    const props = e.target.name.split('.');
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState((state) => {
        const newState = util.object.updateByPath({ ...state }, props, value);
        return newState;
    });
}

export function onFloatFieldBlur(e) {
    const props = e.target.name.split('.');
    let { value } = e.target;
    if (value !== '') value = parseFloat(value).toFixed(2);
    this.setState((state) => {
        const newState = util.object.updateByPath({ ...state }, props, value);
        return newState;
    });
}

/**
 * Generic simple select handler for react select input.
 * You must also bind the target method to your class to change the {this} context.
 * Also requires that the name of the input matches your object property hierarchy
 *  (ex. entity.prop1.prop2). Supports multiselects.
 *
 * @param {any} selection
 */
export function onReactSelectChanged(selection, event) {
    const props = event.name.split('.');
    const value = util.select.reduceValue(selection);
    this.setState((state) => {
        const newState = util.object.updateByPath({ ...state }, props, value);
        return newState;
    });
}

export const toasty = {
    success: (header, message) => {
        toast.dismiss();
        toast.success(
            <ToastMessage
                icon={faCheckCircle}
                header={header}
                message={message}
            />,
            { toastId: message },
        );
    },

    error: (header, message, options) => {
        toast.dismiss();
        toast.error(
            <ToastMessage
                icon={faExclamationTriangle}
                header={header}
                message={message}
            />,
            { ...(options ?? {}), toastId: message },
        );
    },

    info: (header, message) => {
        toast.dismiss();
        toast.error(
            <ToastMessage
                icon={faInfoCircle}
                header={header}
                message={message}
            />,
            { toastId: message },
        );
    },

    warning: (header, message, options) => {
        toast.dismiss();
        toast.warning(
            <ToastMessage
                icon={faInfoCircle}
                header={header}
                message={message}
            />,
            { ...(options ?? {}), toastId: message },
        );
    },
};

export class AppPageForm extends React.Component {
    render() {
        const {
            viewOnly,
            saving,
            formShown,
            loading,
            parentClass,
            formId,
            formName,
        } = this.props;

        const isDisabled = Boolean(viewOnly || saving || formShown || loading);

        return (
            <>  
                <Progress  
                    className={cls('m-0', { 'opacity-0': !saving })} 
                    color="danger" 
                />

                <fieldset
                    className={cls(
                        `${
                            parentClass ?? ''
                        } flex-fill overflow-x-hidden container opacity-1 position-relative`,
                        {
                            'overflow-y-auto': !loading,
                            'overflow-y-hidden': loading,
                        },
                    )}
                    disabled={isDisabled}
                >
                    <FormBlocker show={loading} showProgress />
                    <Row>
                        <FormValidated
                            className={cls('page-hidden col', {
                                ready: !loading,
                            })}
                            id={formId}
                            name={formName}
                            ref={this.props.formRef}
                            onSubmit={this.props.onSubmit}
                            setIsValidated={this.props.setIsValidated}
                            isValidated={this.props.isValidated}
                        >
                            <Container fluid className="m-0">
                                <Row>
                                    <Col>
                                        <PageHeading className="mt-3">
                                            <FontAwesomeIcon
                                                icon={
                                                    this.props.formHeadingIcon
                                                }
                                                className="mr-2 text-muted"
                                            />
                                            {this.props.formHeading}
                                        </PageHeading>
                                    </Col>
                                </Row>
                                {!!this.props.onBack && (
                                    <FlexStartRow className="mb-2">
                                        <small
                                            className="text-info cursor-pointer text-right"
                                            onClick={this.props.onBack}
                                        >
                                            <i className="fa fa-angle-double-left mr-1" />
                                            {this.props.backLabel ?? 'Back'}
                                        </small>
                                    </FlexStartRow>
                                )}
                                <Row>
                                    <ValidationMessageDisplay
                                        onClear={this.props.onClearErrors}
                                        errors={this.props.errors}
                                    />
                                </Row>
                                {this.props.children}
                            </Container>
                        </FormValidated>
                    </Row>
                </fieldset>
            </>
        );
    }
}

export const CollapseUnderlineHeader = ({
    toggleCollapse,
    headerText,
    isOpen,
    className,
    children,
}) => (
    <>
        <h4 onClick={toggleCollapse}>
            <span>{headerText}</span>
            <FontAwesomeIcon
                icon={isOpen ? faCaretUp : faCaretDown}
            />
        </h4>
        <Collapse
            isOpen={isOpen}
            className={cls(
                className ?? '',
                'pl-3 pr-1 site-collapser',
            )}
        >
            {children}
        </Collapse>
    </>
);

export const CollapseUnderlineHeaderWithIcon = ({
    headerText,
    showIcon,
    onIconClick,
    isOpen,
    className,
    children,
}) => (
    <>
        <h4 style={{ justifyContent: 'start' }}>
            <span>{headerText}</span>
            {showIcon && (
                <span>
                    <button
                        style={{ paddingLeft: '2px' }}
                        type="button"
                        className="btn shadow-none"
                        onClick={onIconClick}
                    >
                            &nbsp;
                        <span className="mt-1">
                            <i
                                className="fa fa-plus-circle"
                                style={{ fontSize: '12px' }}
                            />
                        </span>
                    </button>
                </span>
            )}
        </h4>
        <Collapse
            isOpen={isOpen}
            className={cls(
                className ?? '',
                'pl-3 pr-1 site-collapser',
            )}
        >
            {children}
        </Collapse>
    </>
);

export const CollapseTableElement = ({
    toggleCollapse,
    isOpen,
    headerText,
    className,
    children,
}) => (
    <>
        <div onClick={toggleCollapse}>
                &nbsp;
            <FontAwesomeIcon
                icon={isOpen ? faCaretDown : faCaretRight}
            />
            <span>{headerText}</span>
        </div>
        <Collapse
            isOpen={isOpen}
            className={cls(
                className ?? '',
                'pl-3 pr-1 site-collapser',
            )}
        >
            {children}
        </Collapse>
    </>
);

export const FlexColumnStart = ({
    className,
    children,
    component: Component = 'div',
    ...restProps
}) => (
    <Component
        className={`d-flex flex-column justify-content-start ${className}`}
        {...restProps}
    >
        {children}
    </Component>
);

export const FlexColumnCenter = ({
    id,
    className,
    children,
}) => (
    <div
        id={id}
        className={`d-flex flex-column justify-content-center ${
            className ?? ''
        }`}
    >
        {children}
    </div>
);

export const FlexRow = ({
    className,
    children,
}) => (
    <div
        className={`d-flex flex-row align-items-center ${className}`}
    >
        {children}
    </div>
);

export const FlexBetweenRow = ({
    id,
    className,
    children,
}) => (
    <div
        id={id}
        className={`d-flex flex-row align-items-center justify-content-between ${
            className ?? ''
        }`}
    >
        {children}
    </div>
);

export const FlexCenterRow = ({
    id,
    className,
    children,
}) => (
    <div
        id={id}
        className={`d-flex flex-row align-items-center justify-content-center ${
            className ?? ''
        }`}
    >
        {children}
    </div>
);

export const FlexStartRow = ({
    id,
    className,
    children,
    component: Component = 'div',
    ...restProps
}) => (
    <Component
        id={id}
        className={`flex-fill d-flex flex-row align-items-center justify-content-start ${
            className ?? ''
        }`}
        {...restProps}
    >
        {children}
    </Component>
);

export const FlexEndRow = ({
    id,
    className,
    children,
}) => (
    <div
        id={id}
        className={`flex-fill d-flex flex-row align-items-center justify-content-end ${
            className ?? ''
        }`}
    >
        {children}
    </div>
);

export const FormBlocker = (props) => {
    const marginTop = props.allowMenu ? '42px' : '0';
    return (
        <div
            style={{ marginTop }}
            className={cls(`formblocker ${props.className ?? ''}`, {
                show: !!props.show,
                'no-progress': !props.showProgress,
            })}
            onClick={(event) => {
                event.stopPropagation();
            }}
        >
            {!!props.showProgress && (
                <h1 className="formblocker-inner-progress">
                    <i className="fa fa-spin fa-circle-notch text-danger" />
                </h1>
            )}
        </div>
    );
};

export const FormCheckbox = ({
    className,
    id,
    name,
    checked,
    onChange,
    disabled,
    labelClass,
    small,
    labelText,
    children,
}) => (
    <div
        className={cls(
            'form-check form-check-inline mr-0',
            className,
        )}
    >
        <div>
            <input
                id={`${id}-checkbox`}
                name={name}
                checked={checked}
                type="checkbox"
                onChange={onChange}
                disabled={disabled}
            />

            <label
                id={id}
                htmlFor={`${id}-checkbox`}
                disabled={disabled}
                className={labelClass}
            >
                {small ? (
                    <small>
                        {labelText}
                    </small>
                ) : (
                    <span>
                        {labelText}
                    </span>
                )}
            </label>

            {children}
        </div>
    </div>
);

export const FormCircularProgress = () => (
    <div className="d-flex flex-row h-100 align-items-center justify-content-center">
        <Spinner />
    </div>
);

export const FormDivider = () => (
    <Row className="m-0 pt-0">
        <Col>
            <hr />
        </Col>
    </Row>
);

export const FormGroupColumn = ({
    single,
    children,
}) => (
    <div
        className={cls({
            'col-xl-6 col-lg-6 col-md-6 col-sm-12': !single,
        })}
    >
        {children}
    </div>
);

export class FormLabel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hidden: false,
            helpOpen: false,
        };
    }

    toggle = () => this.setState({ helpOpen: !this.state.helpOpen });

    renderHelp() {
        return (this.props.helpMessage ?? '').length ? (
            <>
                <span
                    id={`${this.props.id}_help_popover`}
                    style={{ cursor: 'pointer', marginBottom: '.5rem' }}
                >
                    <FontAwesomeIcon className="ml-2" icon={faQuestionCircle} />
                </span>
                <Popover
                    placement="right"
                    isOpen={this.state.helpOpen}
                    target={`${this.props.id}_help_popover`}
                    toggle={this.toggle}
                >
                    <PopoverHeader>{`${this.props.text} Help`}</PopoverHeader>
                    <PopoverBody>{this.props.helpMessage}</PopoverBody>
                </Popover>
            </>
        ) : (
            ''
        );
    }

    render() {
        const classNames = cls('control-label', this.props.className, {
            required: !!this.props.required,
        });
        return (
            <div className="d-flex flex-row flex-nowrap align-items-center">
                <label
                    id={this.props.id}
                    className={classNames}
                    htmlFor={this.props.htmlFor}
                    hidden={this.state.hidden}
                >
                    {this.props.text}
                </label>
                {this.renderHelp()}
            </div>
        );
    }
}

/**
 * Form component that handles form validation errors.
 * Uses HTML5 checkValidity and toggles bootstrap styles
 * accordingly.
 * */
export class FormValidated extends React.Component {
    // Wrap the handler and call it as provided if the form was valid.
    submitHandler = (event) => {
        event.preventDefault();

        const valid = this.validate();

        if (valid) {
            this.props.onSubmit();
        }

        // isValid is an optional function that will be used to tell if the form was valid
        // during validation.
        if (typeof this.props.isValid === 'function') {
            this.props.isValid(valid);
        }

        this.props.setIsValidated(true);
    };

    validate = () => {
        const { formEl } = this; // the form dom element.

        for (const elem of formEl) {
            // Handle react select controls.
            if (elem.classList.contains('react-select-validated-input')) {
                const form_group = elem.parentNode;
                const select = form_group.querySelector('.react-select');
                if (select) {
                    if (elem.validity.valid) {
                        select.classList.remove('is-invalid');
                        select.classList.add('is-valid');
                    } else {
                        select.classList.add('is-invalid');
                        select.classList.remove('is-valid');
                    }
                }
            }
        }

        const valid = formEl.checkValidity();

        return valid;
    };

    render() {
        let classNames = [];
        classNames = cls(this.props.className, {
            'was-validated': !!this.props.isValidated,
        });
        return (
            <form
                id={this.props.id}
                className={cls('page-form', classNames)}
                noValidate
                ref={(form) => (this.formEl = form)}
                onSubmit={this.submitHandler} // take the submit handler defined by props.
                onKeyDown={(event) => {
                    // Prevent enter key submitting the form.
                    if (event.key === 'Enter' && event.shiftKey === false) {
                        event.preventDefault();
                    }
                }}
            >
                {this.props.children}
            </form>
        );
    }
}

export const GroupedRow = ({
    children,
}) => <Row className="grouped-row">{children}</Row>;

export class InputPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hidden: false,
        };
    }

    componentDidMount() {}

    render() {
        return (
            <input
                id={this.props.id}
                autoComplete={
                    this.props.new ? 'new-password' : 'current-password'
                }
                type="password"
                className="form-control"
                required
                defaultValue={this.props.value}
                hidden={this.state.hidden}
            />
        );
    }
}

export const PageButtonToolbar = ({
    children,
}) => (
    <Row>
        <div className="d-flex flex-row align-items-center justify-content-end text-muted bottom-underline col mb-2 pb-1">
            {children}
        </div>
    </Row>
);

export const PageHeading = ({
    className,
    children,
}) => (
    <Row className="page-heading-row">
        <Col>
            <h5
                className={cls(
                    'w-100 bottom-underline mb-1 pb-2',
                    className,
                )}
            >
                {children}
            </h5>
        </Col>
    </Row>
);

export const PageWrap = ({
    id,
    children,
}) => (
    <div
        id={id ?? 'sitePageWrap'}
        className="d-flex flex-fill flex-column flex-nowrap p-3 site-page-wrap"
    >
        {children}
    </div>
);

export const SimpleTable = ({
    permission,
    leftToolbarContent,
    onAddFunction,
    addButtonEnabled,
    addLabelText,
    id,
    noTopBorder,
    scrollable,
    className,
    tableHeaderLabels,
    editable,
    maxBodyHeight,
    entities,
    noDataText,
    rowRenderer,
}) => (
    <Can I="view" a={permission}>
        <div className="container-fluid simple-table-container">
            <Row>
                <Col className="d-flex flex-row">
                    <div className="flex-fill d-flex flex-row align-items-center justify-content-start">
                        {leftToolbarContent}
                    </div>
                    <div className="d-flex flex-row align-items-center justify-content-end">
                        {onAddFunction ? (
                            <Can I="create" a={permission}>
                                <CommonContext.Consumer>
                                    {(value) => (
                                        <SmallButton
                                            type="button"
                                            disabled={
                                                !!value.formIsOpen
                                                          || addButtonEnabled
                                                              === false
                                            }
                                            onClick={() => {
                                                onAddFunction();
                                            }}
                                        >
                                            <i className="fa fa-plus-circle fa-md mr-2" />
                                            {
                                                addLabelText
                                            }
                                        </SmallButton>
                                    )}
                                </CommonContext.Consumer>
                            </Can>
                        ) : (
                            ''
                        )}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <table
                        id={id}
                        className={cls(
                            'table table-sm',
                            {
                                'border-top-0': noTopBorder,
                                'scrollable-table':
                                          !!scrollable,
                            },
                            className,
                        )}
                    >
                        <thead>
                            <tr key="headerRow">
                                {tableHeaderLabels.map(
                                    (h) => (
                                        <th
                                            key={h.name}
                                            className={h.class}
                                            style={{
                                                width:
                                                          h.width ?? 'auto',
                                            }}
                                        >
                                            {h.name}
                                        </th>
                                    ),
                                )}
                                {!!editable && (
                                    <Can
                                        I="edit"
                                        a={permission}
                                    >
                                        <th
                                            className="text-center"
                                            style={{ width: '30px' }}
                                        />
                                    </Can>
                                )}
                            </tr>
                        </thead>
                        <tbody
                            style={{
                                maxHeight:
                                          maxBodyHeight ?? 'auto',
                                overflowY: maxBodyHeight
                                    ? 'scroll'
                                    : 'unset',
                            }}
                        >
                            {!(entities ?? []).length ? (
                                <tr key="emptyRow">
                                    <td
                                        colSpan={
                                            tableHeaderLabels
                                                .length
                                                  + (editable
                                                      ? 1
                                                      : 0)
                                        }
                                        className="text-center"
                                    >
                                        {noDataText}
                                    </td>
                                </tr>
                            ) : (
                                rowRenderer()
                            )}
                        </tbody>
                    </table>
                </Col>
            </Row>
        </div>
    </Can>
);

export const SmallButton = (props) => {
    const classNames = cls('site-button-small', props.className);
    return (
        <Button
            color="outline-primary"
            style={{ border: 'none', borderRadius: '0 !important' }}
            type="button"
            size="sm"
            className={classNames}
            {...props}
        >
            {props.children}
        </Button>
    );
};

export const SmallPrimaryButton = (props) => {
    const classNames = cls('site-button-small', props.className);
    return (
        <Button
            color="primary"
            style={{ border: 'none', borderRadius: '0 !important' }}
            type="button"
            size="sm"
            className={classNames}
            {...props}
        >
            {props.children}
        </Button>
    );
};

export const SmallOutlineButton = (props) => {
    const classNames = cls('site-button-small', props.className);
    return (
        <Button
            type="button"
            size="sm"
            color="outline-primary"
            className={classNames}
            {...props}
        >
            {props.text ?? props.children}
        </Button>
    );
};

export const SubHeading = ({
    className,
    children,
}) => {
    const classNames = cls('page-subheading mb-1 mt-0', className);
    return (
        <Row className={classNames}>
            <Col className="d-flex flex-row justify-content-center">
                <div className="h6 text-center p-1 m-0">
                    {children}
                </div>
            </Col>
        </Row>
    );
};

export const ToastMessage = ({
    icon,
    header,
    message,
}) => (
    <div className="d-flex flex-column flex-nowrap">
        <div className="pb-2">
            <strong>
                <FontAwesomeIcon
                    className="mr-2"
                    size="lg"
                    icon={icon ?? faInfoCircle}
                />
                {header}
            </strong>
        </div>
        <div>{message}</div>
    </div>
);

export const ValidationErrorMessage = ({
    children,
}) => (
    <small className="invalid-feedback text-danger">
        {children}
    </small>
);

export const YesNoBadge = ({
    value,
}) => (
    <span
        className={`badge badge-yes-no ${
            value ? 'badge-success' : 'badge-secondary'
        }`}
    >
        {value ? 'YES' : 'NO'}
    </span>
);
