import { faTasks, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withRouter } from 'react-router-dom';

import {
    Button,
    FormGroup,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormText,
    Progress,
} from 'reactstrap';
import CommonContext, { ApiRoutes } from '../Common';
import {
    getFilterModel,
    createDataSource,
    createGridOptions,
    DataGrid,
    indexCellRenderer,
    LinkCellRenderer,
    IconCellRenderer,
    VariableLinkCellRenderer,
    TextFilterDefaults,
    DateFilterDefaults,
    FlagIconCellRenderer,
} from '../common/dataGrid/DataGrid';
import BillingChargeCategorySlideout from './BillingChargeCategorySlideout';
import BillingChargeEmployeeSlideout from './BillingChargeEmployeeSlideout';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import {
    PageHeading,
    PageWrap,
    toasty,
    FormLabel,
    onFieldChange,
} from '../common/forms/FormElements';
import { handleFormSaveError } from '../common/forms/ValidationError';
import { util } from '../Util';
import CustomCircularProgress from '../common/CustomCircularProgress';
import { NotAuthorizedPage } from '../status/StatusCodes';
import { BillingFlags } from '../billing/Billing';
import TimesheetDetails from '../timesheet/TimesheetDetails';
import InvoiceSlideout from '../invoice/InvoiceSlideout';

class BillingChargeCategoryIndex extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.billingChargeCategorySlideoutRef = React.createRef();
        this.billingChargeEmployeeSlideoutRef = React.createRef();
        this.timesheetDetailsRef = React.createRef();
        this.invoiceSlideoutRef = React.createRef();

        const yesNo = [
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
        ];

        this.state = {
            loading: true,
            rowData: [],
            rowsSelected: [],
            billingChargeCategorySlideoutOpen: false,
            showRejectBillingChargeCategoryModal: false,
            showApproveModal: false,
            selectedDetails: [],
            isRejectingBilling: false,
            selectedRowRejectionNotes: '',
            loadingReport: false,
            showExportModal: false,
            isExporting: false,
            yesNo,
            isApproving: false,
        };

        this.onRowClicked = this.onRowClicked.bind(this);
        this.onCellClicked = this.onCellClicked.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onRowSelected = this.onRowSelected.bind(this);
    }

    componentDidMount() { return this.populateState(); }

    componentWillUnmount() {
        return this.setState = (state, callback) => {

        };
    }

    onApprove = async () => {
        this.setState({ isApproving: true });

        const { rowsSelected } = this.state;

        // call accept
        if (rowsSelected) {
            const model = rowsSelected.map((x) => x.billingId);
            const response = await util.fetch
                .post(ApiRoutes.billingChargeCategory.approve(), model)
                .catch(this.handleSaveError);

            if (response) {
                toasty.error(`Error approving billing records: ${response}`);
            } else {
                toasty.success('Billing records approved');
            }
        }

        this.state.gridOptions.refresh();

        this.setState({
            showApproveModal: false,
            rowsSelected: [],
            isApproving: false,
        });
    };

    onApproveButtonClick = async () => {
        const rowsSelected = this.state.rowsSelected.length;

        this.setState({
            showApproveModal: true,
            approveModalCount: rowsSelected,
        });
    };

    onBillingChargeCategorySlideoutClosed = async () => {
        await this.context.setFormOpened(false);
        this.setState({ selectedRow: null });
        this.state.gridOptions.refresh();
    };

    onBillingChargeCategorySlideoutOpen = async (id) => {
        if (id) {
            await this.context.setFormOpened(true);
            this.billingChargeCategorySlideoutRef.current.open(id);
        }
    };

    onBillingChargeEmployeeOpenInvoice = async (id) => {
        if (id) {
            const model = {
                billingIds: null,
                invoiceId: id,
            };

            await this.context.setFormOpened(true);
            this.invoiceSlideoutRef.current.open(model);
        }
    };

    onBillingChargeEmployeeOpenTimesheet = async (timesheetId) => {
        await this.context.setFormOpened(false);
        this.setState({ selectedRow: null });

        if (timesheetId) {
            await this.context.setFormOpened(true);
            this.timesheetDetailsRef.current.open(timesheetId);
        }
    };

    onBillingChargeEmployeeSlideoutClosed = async () => {
        await this.context.setFormOpened(false);
        this.setState({ selectedRow: null });
    // this.state.gridOptions.refresh();
    };

    onBillingChargeEmployeeSlideoutOpen = async (id) => {
        this.setState({ selectedChargeId: id });

        if (id) {
            await this.context.setFormOpened(true);
            this.billingChargeEmployeeSlideoutRef.current.open(id);
        }
    };

    onCellClicked = (data) => {
    // test
        alert(data);
    };

    onChange = onFieldChange;

    async onExportClick() {
    // this.setState({ loadingReport: true });
        this.setState({ isExporting: true });
        const { gridOptions } = this.state;
        let model = {};
        if (gridOptions.api.isAnyFilterPresent()) model = getFilterModel(gridOptions.api.getFilterModel());
        try {
            const response = await util.fetch.post(
                ApiRoutes.billing.export(),
                model,
            );
            if (response) {
                toasty.error(response);
            } else {
                toasty.success('Billing Report complete');
            }
        } catch {
            toasty.error('Error exporting billing');
        }
        // this.setState({ loadingReport: false });
        this.state.gridOptions.refresh();
        this.setState({ isExporting: false, showExportModal: false });
    }

    onInvoiceSlideoutClosed = async () => {
        const { selectedChargeId } = this.state;

        await this.context.setFormOpened(false);
        this.setState({ selectedRow: null });

        this.onBillingChargeEmployeeSlideoutOpen(selectedChargeId);
    };

    onRejectBilling = async () => {
        alert('not implemented.');

        // let { rowsSelected, selectedRowRejectionNotes } = this.state;

        // if (selectedRowRejectionNotes.length === 0 || !selectedRowRejectionNotes.trim())
        //    return;
        // this.setState({ isRejectingBilling: true });

        /// /call reject
        // if (!!rowsSelected) {
        //    let model = {
        //        billingIds: rowsSelected.map(x => x.billingId),
        //        note: selectedRowRejectionNotes
        //    };

        //    let response = await util.fetch.post(ApiRoutes.billingChargeCategory.reject(), model)
        //        .catch(this.handleSaveError);

        //    if (response) {
        //        toasty.success('Billing: Charges Rejected');
        //    }
        // }

        // this.state.gridOptions.refresh();

    // this.setState({ selectedRow: null, showRejectBillingChargeCategoryModal: false, selectedRowRejectionNotes: '', isRejectingBilling: false });
    };

    onRowClicked = (event) => {
        const selection = event.api.getSelectedRows();
        const row = selection.length ? selection[0] : null;

        if (row) {
            this.setState({ selectedRow: row });
            // this.openTimesheetDetails();
        }
    };

    // https://stackoverflow.com/questions/44263350/count-number-of-selected-rows-in-ag-grid
    // Possibly use lodash dequeue?
    onRowSelected(e) {
        const rs = e.api.getSelectedRows();

        this.setState({
            rowsSelected: rs,
        });
    }

    onTimesheetDetailsClosed = async () => {
        const { selectedChargeId } = this.state;

        await this.context.setFormOpened(false);
        this.setState({ selectedRow: null });

        this.onBillingChargeEmployeeSlideoutOpen(selectedChargeId);
    };

    downloadExport = async (id) => {
        if (id) {
            this.setState({ loadingReport: true });
            /* var model = { id: id }; */
            await util.fetch
                .downloadFile(
                    ApiRoutes.billing.download(id),
                    null,
                    'Billing.pdf',
                )
                .catch(this.handleSaveError);
            this.setState({ loadingReport: false });
        }
    };

    handleSaveError = (err) => handleFormSaveError(this, err);

    populateState = async () => {
    // var [dispatchLocations, billingStatuses, payrollStatuses, operationsCenters, customers] = await Promise.all([
    //    util.fetch.js(ApiRoutes.typeAheads.dispatchLocations()),
    //    util.fetch.js(ApiRoutes.typeAheads.billingStatuses()),
    //    util.fetch.js(ApiRoutes.typeAheads.payrollStatuses()),
    //    util.fetch.js(ApiRoutes.typeAheads.operationsCenters()),
    //    util.fetch.js(ApiRoutes.typeAheads.customers())
    // ]);

        const [dispatchLocations, chargeTypeUnits, flags] = await Promise.all([
            util.fetch.js(ApiRoutes.typeAheads.dispatchLocations()),
            util.fetch.js(ApiRoutes.typeAheads.chargeTypeUnits()),
            util.fetch.js(ApiRoutes.typeAheads.billingCategoryFlags()),
        ]);

        const gridOptions = createGridOptions(this);

        // https://www.ag-grid.com/documentation/javascript/row-selection/#checkbox-selection
        // One column must have "checkboxSelection" set to true.
        // Note: headerCheckboxSelection not available with infinite scroll.
        gridOptions.rowSelection = 'multiple';

        gridOptions.postProcessData = this.transformRowData;
        gridOptions.onRowSelected = this.onRowSelected;

        gridOptions.rowClassRules = {
            'ag-row-warning': (params) => {
                const matches = ((params.data ?? {}).flag ?? []).filter((f) => f === BillingFlags.BillNotEqualToCharge);

                return matches.length > 0;
            },
            'ag-row-success': (params) => {
                const matches = ((params.data ?? {}).flag ?? []).filter((f) => (
                    f === BillingFlags.ChargeTypeThresholdExceeded
                        || f === BillingFlags.BreakThresholdExceeded
                ));

                return matches.length > 0;
            },
            'ag-row-otjobstart': (params) => {
                const matches = ((params.data ?? {}).flag ?? []).filter((f) => f === BillingFlags.OTJobStartRange);

                return matches.length > 0;
            },
            'ag-row-regoutoforder': (params) => {
                const matches = ((params.data ?? {}).flag ?? []).filter((f) => f === BillingFlags.RegOutOfOrder);

                return matches.length > 0;
            },
            'ag-row-otoutoforder': (params) => {
                const matches = ((params.data ?? {}).flag ?? []).filter((f) => f === BillingFlags.OTOutOfOrder);

                return matches.length > 0;
            },
        };

        gridOptions.components = {
            selectFilter: DataGridSelectFilter,
            selectFloatingFilter: DataGridSelectFloatingFilter,
            nameRenderer: LinkCellRenderer,
            iconRenderer: IconCellRenderer,
            flagIconRenderer: FlagIconCellRenderer,
            variableLinkRenderer: VariableLinkCellRenderer,
        };
        gridOptions.onRowClicked = this.onRowClicked;

        const dispatchFilterParams = {
            suppressFilterButton: true,
            options: dispatchLocations,
            optionsLabel: 'label',
            optionsValue: 'value',
        };
        const chargeTypeUnitsFilterParams = {
            suppressFilterButton: true,
            options: chargeTypeUnits,
            optionsLabel: 'label',
            optionsValue: 'value',
        };
        const processingTypeUnitsFilterParams = {
            suppressFilterButton: true,
            options: [
                { label: 'Regular hours out of order', value: 1 },
                { label: 'OT hours out of order', value: 2 },
            ],
            optionsLabel: 'label',
            optionsValue: 'value',
        };
        const flagsFilterParams = {
            suppressFilterButton: true,
            options: flags,
            optionsLabel: 'label',
            optionsValue: 'value',
        };

        gridOptions.columnDefs = [
            {
                headerName: '',
                valueGetter: 'node.id',
                sortable: false,
                hide: true,
                flex: 1,
                maxWidth: 35,
                cellRenderer: indexCellRenderer,
            },
            {
                colId: 'Billing.JobDate',
                headerName: 'Date',
                field: 'date',
                sortable: true,
                flex: 1.5,
                sort: { direction: 'asc', priority: 0 },
                filter: 'agDateColumnFilter',
                filterParams: DateFilterDefaults,
                // floatingFilterComponentParams: {
                //    suppressFilterButton: true,
                // }
            },
            {
                colId: 'Billing.Timesheet.TimesheetNumber',
                headerName: 'Timesheet #',
                field: 'timesheetNumber',
                sortable: true,
                flex: 2,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'Billing.CustomerName',
                headerName: 'Customer Name',
                field: 'customerName',
                sortable: true,
                flex: 2,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'Billing.DispatchLocationId',
                headerName: 'Dispatching',
                field: 'dispatchOfficeName',
                sortable: true,
                flex: 2,
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: dispatchFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: dispatchFilterParams,
            },
            {
                colId: 'TimesheetDetail.ResourceName',
                headerName: 'Resource',
                field: 'resourceName',
                sortable: true,
                flex: 2,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'outOfOrderType',
                minWidth: 85,
                headerName: '',
                sortable: false,
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: processingTypeUnitsFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: processingTypeUnitsFilterParams,
                cellRenderer: (params) => {
                    if (params.data
                        && params.data.resourceTypeId == 1
                        && ((params.data ?? {}).flag ?? []).filter((f) => f === BillingFlags.RegOutOfOrder).length == 0
                        && ((params.data ?? {}).flag ?? []).filter((f) => f === BillingFlags.OTOutOfOrder).length == 0) {
                        return (

                            <span
                                className="site-button-small btn btn-outline-primary btn-sm"
                                style={{ border: 'none' }}
                                title="View all charges this period"
                                onClick={() => {
                                    this.onBillingChargeEmployeeSlideoutOpen(params.data.id);
                                }}
                            >
                                <i className="fas fa-history" />
                            </span>
                        );
                    }

                    if (params.data
                        && params.data.resourceTypeId == 1
                        && ((params.data ?? {}).flag ?? []).filter((f) => f === BillingFlags.RegOutOfOrder).length > 0) {
                        return (

                            <span
                                className="site-button btn btn-outline-primary"
                                style={{ border: 'none' }}
                                title="Reg charge is out of order.  View..."
                                onClick={() => {
                                    this.onBillingChargeEmployeeSlideoutOpen(params.data.id);
                                }}
                            >
                                <i className="fas fa-exclamation-triangle" />
                            </span>
                        );
                    }

                    if (params.data
                        && params.data.resourceTypeId == 1
                        && ((params.data ?? {}).flag ?? []).filter((f) => f === BillingFlags.OTOutOfOrder).length > 0) {
                        return (

                            <span
                                className="site-button btn btn-outline-primary"
                                style={{ border: 'none' }}
                                title="OT charge is out of order.  View..."
                                onClick={() => {
                                    this.onBillingChargeEmployeeSlideoutOpen(params.data.id);
                                }}
                            >
                                <i className="fas fa-exclamation-circle" />
                            </span>
                        );
                    }

                    return null;
                },
            },
            {
                colId: 'TimesheetDetail.ChargeTypeName',
                headerName: 'Charge Type',
                field: 'chargeTypeName',
                sortable: true,
                flex: 3,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
                cellRenderer: (params) => {
                    if (params.data) {
                        if (params.data.hasBreak) {
                            return (
                                <span>
                                    {params.data.chargeTypeName}
                                    <span
                                        className="ml-2 badge badge-info"
                                        style={{
                                            fontSize: '1em',
                                            marginTop: '-2px',
                                        }}
                                        title={`Hours: ${params.data.breakHours}`}
                                    >
                    B
                                    </span>
                                </span>
                            );
                        }

                        return params.data.chargeTypeName;
                    }

                    return null;
                },
            },
            {
                colId: 'TimesheetDetail.ChargeTypeUnitsId',
                headerName: 'Units',
                field: 'chargeTypeUnitsName',
                minWidth: 105,
                sortable: true,
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: chargeTypeUnitsFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: chargeTypeUnitsFilterParams,
            },
            {
                colId: 'Bill',
                headerName: 'Bill Amt',
                field: 'bill',
                minWidth: 95,
                sortable: false,
            },
            {
                colId: 'Start',
                headerName: 'Start',
                field: 'start',
                minWidth: 65,
                sortable: false,
            },
            {
                colId: 'End',
                headerName: 'End',
                field: 'end',
                minWidth: 65,
                sortable: false,
            },
            {
                colId: 'Reg',
                headerName: 'Reg',
                field: 'reg',
                minWidth: 65,
                sortable: false,
            },
            // {
            //    colId: 'WTDReg',
            //    headerName: 'WTD Reg',
            //    field: 'wtdReg',
            //    minWidth: 95,
            //    sortable: false

            // },
            {
                colId: 'OT',
                headerName: 'OT',
                field: 'ot',
                minWidth: 65,
                sortable: false,
            },
            // {
            //    colId: 'WTDOT',
            //    headerName: 'WTDOT',
            //    field: 'wtdot',
            //    minWidth: 95,
            //    sortable: false

            // },
            {
                colId: 'Id',
                minWidth: 65,
                headerName: 'Edit',
                sortable: false,
                cellRenderer: 'iconRenderer',
                cellRendererParams: {
                    clicked: this.onBillingChargeCategorySlideoutOpen,
                    idField: 'id',
                    iconClass: 'fa-edit',
                },
            },
            {
                colId: 'Flag',
                headerName: 'Flag',
                field: 'flag',
                flex: 1,
                minWidth: 65,
                sortable: false,
                tooltipField: 'flag',
                tooltipComponentParams: { color: '#ececec' },
                cellRenderer: 'flagIconRenderer',
                cellRendererParams: {
                    bodyField: 'flag',
                },
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: flagsFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: flagsFilterParams,
            },
            {
                colId: 'SelectionPlaceholder',
                headerName: '',
                field: 'selectionPlaceholder',
                sortable: false,
                minWidth: 50,
                checkboxSelection: true,
            },
        ];

        const dataSource = createDataSource(
            ApiRoutes.billingChargeCategory.search(),
            gridOptions,
        );

        this.setState({
            loading: false,
            gridOptions,
            dataSource,
            isRejectingTimesheet: false,
        });
    };

    printTimesheet = async (id) => {
        if (id) {
            window.open(ApiRoutes.report.timesheetBilling(id), '_self');
        }
    };

    async toggleApproveModal() {
        const { showApproveModal } = this.state;
        this.setState({ showApproveModal: !showApproveModal });
    }

    async toggleExportModal() {
        const { showExportModal } = this.state;
        this.setState({ showExportModal: !showExportModal });
    }

    toggleRejectBillingChargeCategoryModal = () => {
        const { showRejectBillingChargeCategoryModal } = this.state;
        this.setState({
            showRejectBillingChargeCategoryModal:
                !showRejectBillingChargeCategoryModal,
        });
    };

    render() {
        const {
            rowData,
            gridOptions,
            isApproving,
            isRejectingBilling,
            selectedRowRejectionNotes,
            rowsSelected,
            isExporting,
            showExportModal,
            showRejectBillingChargeCategoryModal,
            showApproveModal,
            approveModalCount,
        } = this.state;

        // TODO: Eliminate inline styles.

        return (
            <CommonContext.Consumer>
                {(value) => {
                    const { tenantSettings } = (value ?? {}).tenant ?? {};

                    if (this.state.loading || !tenantSettings) {
                        return (
                            <Progress />
                        );
                    }

                    if (
                        !tenantSettings.billingEnabled
                        || !tenantSettings.invoicingEnabled
                    ) return <NotAuthorizedPage />;

                    return (
                        <PageWrap>
                            <PageHeading>
                                <FontAwesomeIcon
                                    icon={faTasks}
                                    className="mr-2 text-muted"
                                />
                                <span>Billing: Charge Categories</span>
                                <span
                                    style={{
                                        float: 'right',
                                        position: 'relative',
                                        top: '-5px',
                                    }}
                                >
                                    {/*    <Button */}
                                    {/*        size="sm" */}
                                    {/*        style={{ marginRight: "10px" }} */}
                                    {/*        color="danger" */}
                                    {/*        onClick={() => this.toggleExportModal()} */}
                                    {/*    > */}
                                    {/*        Export */}
                                    {/* </Button> */}
                                    <Button
                                        size="sm"
                                        color="success"
                                        style={{ marginRight: '10px' }}
                                        onClick={() => this.onApproveButtonClick()}
                                        disabled={
                                            rowsSelected.length === 0
                                            || isApproving
                                        }
                                    >
                    Approve Selected
                                    </Button>
                                    {/* <Button */}
                                    {/*    size="sm" */}
                                    {/*    color="danger" */}
                                    {/*    onClick={() => this.toggleRejectBillingChargeCategoryModal()} */}
                                    {/*    disabled={rowsSelected.length !== 1 || isRejectingBilling} */}
                                    {/* > */}
                                    {/*    Reject Selected */}
                                    {/* </Button> */}
                                </span>
                            </PageHeading>

                            <DataGridToolbar
                                entity="Billing"
                                gridApi={this.state.gridApi}
                                dataSource={this.state.dataSource}
                                hideAdd
                                hideExcelButton
                                gridOptions={this.state.gridOptions}
                                serverExport={{
                                    apiPath:
                                        ApiRoutes.billingChargeCategory.excelExport(),
                                    filePrefix: 'BillingChargeCategorySearch',
                                }}
                                serverExportDisabled={
                                    !!this.state.saving
                                    || !!this.state.loading
                                    || !!this.state.loadingData
                                }
                            />
                            {!!this.state.loadingReport && (
                                <CustomCircularProgress />
                            )}

                            {!this.state.loadingReport && (
                                <DataGrid
                                    domLayout="normal"
                                    rowData={rowData}
                                    gridOptions={gridOptions}
                                    gridStatus={this.state.gridStatus}
                                />
                            )}
                            <BillingChargeCategorySlideout
                                ref={this.billingChargeCategorySlideoutRef}
                                show={
                                    this.state.billingChargeCategorySlideoutOpen
                                }
                                toggleShow={(open) => this.setState({
                                    billingChargeCategorySlideoutOpen: open,
                                })}
                                onClose={
                                    this.onBillingChargeCategorySlideoutClosed
                                }
                            />
                            <BillingChargeEmployeeSlideout
                                ref={this.billingChargeEmployeeSlideoutRef}
                                show={
                                    this.state.billingChargeEmployeeSlideoutOpen
                                }
                                toggleShow={(open) => this.setState({
                                    billingChargeEmployeeSlideoutOpen: open,
                                })}
                                onClose={
                                    this.onBillingChargeEmployeeSlideoutClosed
                                }
                                onOpenInvoice={(invoiceId) => {
                                    this.onBillingChargeEmployeeOpenInvoice(invoiceId);
                                }}
                                onOpenTimesheet={(timesheetId) => {
                                    this.onBillingChargeEmployeeOpenTimesheet(timesheetId);
                                }}
                            />
                            <InvoiceSlideout
                                ref={this.invoiceSlideoutRef}
                                show={this.state.invoiceSlideoutOpen}
                                toggleShow={(open) => this.setState({ invoiceSlideoutOpen: open })}
                                onClose={this.onInvoiceSlideoutClosed}
                            />
                            <TimesheetDetails
                                ref={this.timesheetDetailsRef}
                                show={this.state.timesheetDetailsOpen}
                                toggleShow={(open) => this.setState({
                                    timesheetDetailsOpen: open,
                                })}
                                onClose={this.onTimesheetDetailsClosed}
                                onAccept={this.onAcceptTimesheet}
                                onReject={this.onRejectTimesheet}
                                onlyBillable
                                isBilling
                            />
                            <Modal
                                isOpen={showRejectBillingChargeCategoryModal}
                                toggle={
                                    this.toggleRejectBillingChargeCategoryModal
                                }
                            >
                                <ModalHeader
                                    toggle={
                                        this
                                            .toggleRejectBillingChargeCategoryModal
                                    }
                                >
                  Billing: Reject Timesheet
                                </ModalHeader>
                                <ModalBody>
                                    <FormText style={{ marginBottom: '20px' }}>
                    This action will reject the timesheet
                    from billing charge categories and
                    remove all charges from payroll charges.
                                    </FormText>
                                    <FormGroup>
                                        <FormLabel
                                            required
                                            text="Notes"
                                        />
                                        <textarea
                                            id="selectedRowRejectionNotes"
                                            name="selectedRowRejectionNotes"
                                            className="form-control"
                                            defaultValue={
                                                selectedRowRejectionNotes ?? ''
                                            }
                                            onChange={this.onChange}
                                            required
                                            placeholder="Enter notes regarding the rejection."
                                            type="text"
                                            maxLength="500"
                                            rows="5"
                                        />
                                        <small className="text-danger">
                      Notes are required.
                                        </small>
                                    </FormGroup>
                                </ModalBody>
                                <ModalFooter>
                                    {isRejectingBilling && (
                                        <FontAwesomeIcon
                                            icon={faCircleNotch}
                                            className="fa-spin mr-2"
                                            size="sm"
                                        />
                                    )}
                                    <Button
                                        color="primary"
                                        disabled={isRejectingBilling}
                                        onClick={this.onRejectBilling}
                                    >
                    Ok
                                    </Button>
                                    {' '}
                                </ModalFooter>
                            </Modal>
                            <Modal
                                isOpen={showExportModal}
                                toggle={() => this.toggleExportModal()}
                            >
                                <ModalHeader
                                    toggle={() => this.toggleExportModal()}
                                >
                  Export Billing
                                </ModalHeader>
                                <ModalBody>
                                    <p>
                    Export will be generated based upon
                    search criteria entered on the billing
                    screen. If the result set is too large
                    the export may timeout.
                                    </p>
                                    {/* <FormGroup> */}
                                    {/*    <label>Dispatching</label> */}
                                    {/*    <Select */}
                                    {/*        placeholder={'Select Dispatch Location'} */}
                                    {/*        id="selectedDispatchLocation" */}
                                    {/*        name="selectedDispatchLocation" */}
                                    {/*        styles={CompactSelectStyles} */}
                                    {/*        isClearable={false} */}
                                    {/*        className="react-select" */}
                                    {/*        options={dispatchLocations} */}
                                    {/*        value={(dispatchLocations ?? []).find(x => x.value === selectedDispatchLocation) ?? ''} */}
                                    {/*        onChange={this.onSelectedDispatchLocationChanged} */}
                                    {/*    /> */}
                                    {/* </FormGroup> */}
                                    {/* <FormGroup> */}
                                    {/*    <label>Week Of</label> */}
                                    {/*    <input */}
                                    {/*        id="weekOf" */}
                                    {/*        name="selectedWeekOf" */}
                                    {/*        className="form-control" */}
                                    {/*        defaultValue={selectedWeekOf ?? ''} */}
                                    {/*        onChange={this.onChange} */}
                                    {/*        type="date" */}
                                    {/*    /> */}
                                    {/* </FormGroup> */}
                                </ModalBody>
                                <ModalFooter>
                                    {isExporting && (
                                        <FontAwesomeIcon
                                            icon={faCircleNotch}
                                            className="fa-spin mr-2"
                                            size="sm"
                                        />
                                    )}
                                    <Button
                                        color="primary"
                                        disabled={isExporting}
                                        onClick={() => this.onExportClick()}
                                    >
                    Ok
                                    </Button>
                                    {' '}
                                    <Button
                                        color="secondary"
                                        onClick={() => this.setState({
                                            showExportModal: false,
                                        })}
                                    >
                    Cancel
                                    </Button>
                                </ModalFooter>
                            </Modal>
                            <Modal
                                isOpen={showApproveModal}
                                toggle={() => this.toggleApproveModal()}
                            >
                                <ModalHeader
                                    toggle={() => this.toggleApproveModal()}
                                >
                  Approve
                                </ModalHeader>
                                <ModalBody>
                                    <p>
                    Are you sure you would like to approve
                                        {' '}
                                        {approveModalCount}
                                        {' '}
                    records
                                    </p>
                                </ModalBody>
                                <ModalFooter>
                                    {isApproving && (
                                        <FontAwesomeIcon
                                            icon={faCircleNotch}
                                            className="fa-spin mr-2"
                                            size="sm"
                                        />
                                    )}
                                    <Button
                                        color="primary"
                                        disabled={isApproving}
                                        onClick={() => this.onApprove()}
                                    >
                    Ok
                                    </Button>
                                    {' '}
                                    <Button
                                        color="secondary"
                                        onClick={() => this.setState({
                                            showApproveModal: false,
                                        })}
                                    >
                    Cancel
                                    </Button>
                                </ModalFooter>
                            </Modal>
                        </PageWrap>
                    );
                }}
            </CommonContext.Consumer>
        );
    }
}

export default withRouter(BillingChargeCategoryIndex);
