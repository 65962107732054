import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons';
import { Progress } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import {
    createDataSource,
    createGridOptions,
    DataGrid,
    indexCellRenderer,
    TextFilterDefaults,
    LinkCellRenderer,
    ButtonCellRenderer,
} from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { PageHeading, PageWrap } from '../common/forms/FormElements';

class JobIndex extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            rowData: [],
            selectedRow: null,
        };
    }

    componentDidMount() { return this.populateState(); }

    componentWillUnmount() { return this.setState = (state, callback) => { }; }

    async populateState() {
        const { search } = { ...this.props.location };
        const contractNumber = search ? new URLSearchParams(search).get('contractNumber') ?? '' : '';

        const gridOptions = createGridOptions(this);

        gridOptions.components = {
            statusFilter: DataGridSelectFilter,
            statusFloatingFilter: DataGridSelectFloatingFilter,
            nameRenderer: LinkCellRenderer,
            buttonRenderer: ButtonCellRenderer,
        };

        gridOptions.columnDefs = [
            {
                headerName: '',
                valueGetter: 'node.id',
                sortable: false,
                cellRenderer: indexCellRenderer,
                flex: 0,
                maxWidth: 80,
                minWidth: 80,
            },
            {
                colId: 'ContractSequence',
                headerName: 'Job Number',
                field: 'jobNumber',
                sortable: true,
                sort: { direction: 'asc', priority: 0 },
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
                cellRenderer: 'nameRenderer',
                cellRendererParams: {
                    to: (id) => `${AppNavPaths.Job}/${id}`,
                    nameField: 'jobNumber',
                    idField: 'id',
                    title: 'View this Job',
                },
            },
            {
                colId: 'WeekOfDate',
                headerName: 'Week of',
                field: 'weekOf',
                flex: 0.5,
            },
            {
                colId: 'Contract.Company.CompanyName',
                headerName: 'Customer Name',
                field: 'customerName',
                sortable: true,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'Contract.Number',
                headerName: 'Contract Number',
                field: 'contractNumber',
                sortable: true,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'JobLocation.City',
                headerName: 'City',
                field: 'city',
                sortable: true,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'JobLocation.State',
                maxWidth: 100,
                headerName: 'State',
                field: 'state',
                sortable: true,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
        ];

        gridOptions.onGridReady = (params) => {
            // default filter values

            if (this.state.preFilters?.contractNumber) {
                const filterComponent = params.api.getFilterInstance('Contract.Number');
                filterComponent.setModel({
                    filter: contractNumber,
                    filterType: 'text',
                    key: 'Contract.Number',
                    type: 'contains',
                });
                params.api.onFilterChanged();
            }
            gridOptions.api = params.api;
            params.api.setDatasource(this.state.dataSource, gridOptions);
        };

        const preFilters = {
            contractNumber,
        };

        const dataSource = createDataSource(ApiRoutes.job.search(), gridOptions);// , this.gridDataFailureCallback);
        this.setState({
            loading: false, gridOptions, dataSource, preFilters,
        });
    }

    render() {
        if (this.state.loading) return (<Progress />);

        const { rowData, gridOptions } = this.state;

        return (
            <PageWrap>
                <PageHeading>
                    <FontAwesomeIcon icon={faAddressCard} className="mr-2 text-muted" />
                    <span>Jobs</span>
                </PageHeading>
                <DataGridToolbar
                    entity="job"
                    gridApi={this.state.gridApi}
                    dataSource={this.state.dataSource}
                    hideAdd
                />
                <DataGrid
                    domLayout="normal"
                    rowData={rowData}
                    gridOptions={gridOptions}
                    gridStatus={this.state.gridStatus}
                />
            </PageWrap>
        );
    }
}
export default withRouter(JobIndex);
