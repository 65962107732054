import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { toast } from 'react-toastify';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom'

// This lib is hungry.  Only pull what we need here.
import { utils as XLSXUtils, writeFile } from 'xlsx';
import moment from 'moment';
import { debounce } from 'lodash-es';
import DataGridSelectFloatingFilter from './DataGridSelectFloatingFilter';
import DataGridSelectFilter from './DataGridSelectFilter';
import { SmallButton, ToastMessage, toasty } from '../forms/FormElements';
import { Can } from '../../Can';
import CommonContext from '../../Common';
import { util } from '../../Util';
import '../../../style/DataGrid.scss';

// Converts AG Grid model to a form needed by our API.
export function getFilterModel(filterModel) {
    const filter_model = {};
    for (const key of Object.keys(filterModel)) {
    // Dropdown selections
        if (filterModel[key].value) {
            // e.g., Converts: "DispatchLocation: {value: 25}" to "DispatchLocation: 25"
            filter_model[key] = filterModel[key].value;
        } else if (filterModel[key].filterType) {
            // 2022-09-08 - M. Nicol
            // Sets the key property of the value to the key of the object.
            // The example I looked at was "Date: {<object data>, key: 'Date'}",
            // so there was no change.
            // I assume there are cases like the following somewhere:
            //  Original: "SomeDate: {<object data>, key: 'Date'}"
            //  Converted: "SomeDate: {<object data>, key: 'SomeDate'}"
            filter_model[key] = filterModel[key];
            filter_model[key].key = key;
        }
    }

    return filter_model;
}

/*
 * RLC: Remap the values from the Aggrid filter object.  Instead of filterModel.key.value,
 * we want filterModel.key = value, to make it easier on .NET modeling and to avoid
 * JSON parsing in the service layer.
 *
 */
export function getMappedFilterModel(filterModel, gridOptions) {
    let filter_model = {};
    for (const key of Object.keys(filterModel)) {
    // Dropdown selections
        if (filterModel[key].value) {
            filter_model[key] = filterModel[key].value;
        } else if (filterModel[key].filterType) {
            filter_model[key] = filterModel[key];
            filter_model[key].key = key;
        }
    }

    if (gridOptions.getCustomFilters) filter_model = { ...filter_model, ...gridOptions.getCustomFilters() };

    return filter_model;
}

// Gets the way the grid is currently sorted and filtered according to how we
// need it in the API.
export function getServerSideRowRequest(gridOptions) {
    const filterModel = gridOptions.api.getFilterModel();
    const sortModel = gridOptions.api.columnModel.getColumnState();
    const filterModelMapped = getMappedFilterModel(
        { ...filterModel },
        { ...gridOptions }, /* RLC: Don't mutate grid options. */
    );
    const req = new ServerSideScrollRowsRequest();
    req.startRow = 0;
    req.endRow = 9999999;
    req.filterModel = { ...filterModelMapped };
    req.sortModel = Object.keys(sortModel).length ? sortModel : [];
    return req;
}

/**
 * Creates a universal data source object for serverside model.
 *
 * Author - R. Li Casanova
 *
 * Remarks - Since Aggrid is deficient in many customizations (purposely, to invoke purchases)
 *           This method aims to ease some boilerplate for filtering, sorting, and calls to the
 *           API.
 * */
export function createDataSource(url, gridOptions) {
    return {
        rowCount: null,
        // called by the grid when more rows are required
        getRows(params) {
            if (gridOptions.useLoading) {
                gridOptions.api.showLoadingOverlay();
            }

            // Override the boxed overlay with custom, if desired.
            // gridOptions.api && gridOptions.api.hideOverlay();
            // gridOptions.api &&
            //    !!gridOptions.showLoadingOverlay &&
            //    gridOptions.api.showLoadingOverlay();

            // Remap the parameters to simplify calls to .NET API.
            let filter_model = getMappedFilterModel(
                params.filterModel,
                gridOptions,
            );

            // If a custom object is provided to add parameters outside of the scope of the grid,
            // pull from that here. See example in MyTimesheets.js component.
            if (gridOptions.customParametersFunction) {
                filter_model = {
                    ...filter_model,
                    ...gridOptions.customParametersFunction(),
                };
            }

            // If you need to validate any parameters before passing to the API, provide
            // a custom function here.  Ensure the function returns an empty string if
            // params are valid, an error message if they aren't.
            if (gridOptions.validateParametersFunction) {
                const msg = gridOptions.validateParametersFunction(filter_model);
                if (msg) {
                    toasty.error('Invalid search criteria', msg);

                    // Invoke any post-load-event-callbacks.
                    // Hide overlays as needed.
                    // if (gridOptions.setLoading) gridOptions.setLoading(false);
                    gridOptions.api && gridOptions.api.hideOverlay();
                    gridOptions.api && gridOptions.api.showNoRowsOverlay();
                    params.successCallback([], 0);
                    return false;
                }
            }

            params.filterModel = { ...filter_model };

            util.fetch
                .post(url, params)
                .then(async (data) => {
                    // If any custom transformation is needed, invoke the provided custom function here.
                    if (gridOptions.postProcessData) data = gridOptions.postProcessData(data);

                    // Provide counts to the grid's API.
                    let lastRow = -1;
                    if (params.endRow >= data.filterCount) {
                        lastRow = data.filterCount;
                    }

                    // Set Status row footer metadata.
                    gridOptions.setStatus({
                        filterCount: data.filterCount,
                        maxRows: data.maxRows,
                    });

                    // If any callback post API-fetch success is provided, call that here.
                    params.successCallback(data.rows, lastRow);

                    // Invoke any post-load-event-callbacks.
                    // if (gridOptions.setLoading) gridOptions.setLoading(false);

                    // Hide overlays as needed.
                    gridOptions.api && gridOptions.api.hideOverlay();

                    if ((data.filterCount ?? 0) === 0) {
                        gridOptions.api && gridOptions.api.showNoRowsOverlay();
                    }
                })
                .catch((error) => {
                    console.error(error);
                    params.failCallback();
                    toast.error(
                        <ToastMessage
                            icon={faExclamationTriangle}
                            header="Server API Error"
                            message="Unable to load table data."
                        />,
                    );
                });
        },
    };
}

/**
 * Adds a keydown listener to copy the focused AG Grid cell's value to the clipboard
 * when `CTRL+C` or `CMD+C` is pressed. Handles special cases for the first column
 * (e.g., row index) and ensures null/undefined values are gracefully managed.
 */
export const handleCellCopy = (event) => {
    if ((event.event.ctrlKey || event.event.metaKey) && event.event.key === 'c') {
        const cellValue = event.value;

        // Check if the column is the index column and handle accordingly
        const colId = event.column?.colId;

        let valueToCopy;
        if (colId === '0' || colId === 'firstColumn' || colId === 'rowNumber') {
            // Handle the index column by using the row index (1-based)
            valueToCopy = event.node.rowIndex + 1;
        } else {
            // For other columns, use the cell value
            valueToCopy = cellValue;
        }

        // Ensure the value is not null or undefined before copying
        if (valueToCopy !== null && valueToCopy !== undefined) {
            navigator.clipboard.writeText(valueToCopy.toString())
                .catch((err) => console.error('Clipboard error:', err));
        } else {
            console.log('No value in the selected cell to copy.');
        }
    }
};


// Boilerplate to create a default grid options object.
export function createGridOptions(context, failureCallback) {
    return {
        domLayout: 'normal',
        suppressMenuHide: true,
        headerHeight: 30,
        rowHeight: 34,
        suppressPropertyNamesCheck: true,
        rowModelType: 'infinite',
        cacheBlockSize: 50,
        defaultColDef: {
            sortable: true,
            resizable: true,
            flex: 1,
            suppressMenu: true,
            floatingFilter: true,
            minWidth: 150,
        },
        filter: true,
        unSortIcon: true,
        rowSelection: 'single',
        multiSortKey: 'ctrl',
        onSelectionChanged: (event) => {
            const selection = event.api.getSelectedRows();
            context.setState({
                selectedRow: selection.length ? selection[0] : null,
            });
        },
        onGridReady: (params) => {
            params.api.setDatasource(context.state.dataSource);
            context.setState({ gridApi: params.api });      
        },
        onCellKeyDown: handleCellCopy,
        api: () => context.state.gridApi,
        refresh: () => {
            context.state.gridApi.setDatasource(context.state.dataSource);
        },
        setStatus: (status) => {
            context.setState({ gridStatus: status });
        },
    };
}

// Grid options defaults for non-serverside model.
export function createClientSideGridOptions(context, failureCallback) {
    return {
        domLayout: 'normal',
        suppressMenuHide: true,
        headerHeight: 30,
        rowHeight: 34,
        suppressPropertyNamesCheck: true,
        rowModelType: 'clientSide',
        cacheBlockSize: 50,
        defaultColDef: {
            sortable: true,
            flex: 1,
            suppressMenu: true,
            floatingFilter: false,
        },
        filter: true,
        unSortIcon: true,
        rowSelection: 'multiple',
        multiSortKey: 'ctrl',
        onSelectionChanged: (event) => {
            const selections = event.api.getSelectedRows();
            context.setState({
                selectedRows: selections.length ? selections : null,
            });
        },
        onGridReady: (params) => {
            context.setState({ gridApi: params.api });
        },
        onCellKeyDown: handleCellCopy,
        setStatus: (status) => {
            context.setState({ gridStatus: { ...status } });
        },
    };
}

export function refreshDataGrid() {
    this.state.gridApi.setDatasource(this.state.dataSource);
}

export class ServerSideScrollRowsRequest {
    // first row requested
    startRow = 0;

    endRow = 0;

    rowSize = 0;

    sortModel = null;

    filterModel = null;
}

export const DataGrid = ({ gridOptions, gridStatus }) => (
    <>
        <div
            className="grid-wrapper"
            style={{ flex: '1 1 auto', overflow: 'hidden' }}
        >
            <div
                style={{
                    height: '100%',
                    width: '100%',
                    overflow: 'hidden',
                }}
                className="ag-theme-alpine"
            >
                <AgGridReact
                    gridOptions={gridOptions}
                    overlayLoadingTemplate={
                        gridOptions.loadingTemplate
            ?? '<span className="ag-overlay-loading-center">Loading; Please wait ...</span>'
                    }
                />
            </div>
        </div>
        {!!gridStatus && !!gridStatus.maxRows && (
            <div className="mt-1 d-flex flex-row flex-wrap align-items-center grid-status-bar">
      Displaying&nbsp;
                <strong>{gridStatus.filterCount}</strong>
                    &nbsp;items&nbsp;of&nbsp;
                <strong>{gridStatus.maxRows}</strong>
                    &nbsp;total results.
                {' '}
                {gridStatus.filterCount > 0
                    ? `(${
                        gridStatus.maxRows
                              - gridStatus.filterCount
                    } filtered)`
                    : ''}
            </div>
        )}
    </>
);

export class DataGridExportExcelButton extends React.Component {
    constructor(props) {
        super(props);

        this.onExportClick = this.onExportClick.bind(this);
        this.getExportColumns = this.getExportColumns.bind(this);

        this.exportColumns = null;
    }

    onExportClick() {
    // https://www.ag-grid.com/documentation/javascript/infinite-scrolling/#block-cache
    // https://www.ag-grid.com/documentation/react/grid-properties/
    // https://www.ag-grid.com/documentation/javascript/accessing-data/
    // https://github.com/SheetJS/sheetjs/tree/master/demos/react

        // 'accessing-data' link says that the only supported operation in infinite mode
        // is forEachNode.  Does not account for filtering/sorting.

        // https://www.npmjs.com/package/xlsx
        // https://www.npmjs.com/package/xlsx#utility-functions
        // https://www.ag-grid.com/documentation/javascript/grid-api/

        const sheetData = [];

        if (!this.exportColumns) {
            const colDefs = this.props.gridApi.getColumnDefs();
            this.exportColumns = this.getExportColumns(colDefs);
        }

        const api = this.props.gridApi;

        api.forEachNode((node, index) => {
            const d = {};

            this.exportColumns.forEach((colId, headerValue) => {
                // Cannot get from just node.data since the column defs may not actually contain the field name
                // as it appears in the data due to groupings, etc.
                // https://stackoverflow.com/questions/41381213/how-to-access-a-cell-value-when-the-column-has-been-defined-with-ag-grid-express?rq=1
                d[headerValue] = api.getValue(colId, node);
            });

            sheetData.push(d);
        });

        const ws = XLSXUtils.json_to_sheet(sheetData);

        // https://sheetjs.com/demos/writexlsx.html
        const wb = XLSXUtils.book_new();

        XLSXUtils.book_append_sheet(wb, ws);

        const appendToExportFileName = this.props.appendToExportFileName ?? '';

        const fileName = this.props.entity
            ? `${this.props.entity}${appendToExportFileName}.xlsx`
            : 'export.xlsx';

        writeFile(wb, fileName);
    }

    getExportColumns(colDefs) {
        const colMap = new Map();

        const { gridApi } = this.props;

        const colApi = gridApi.columnController
            ? gridApi.columnController.columnApi
            : gridApi.columnModel;

        colDefs.forEach((cd) => {
            if (cd.doNotExport) {
                return;
            }

            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map
            // https://www.ag-grid.com/documentation/javascript/column-api/
            const col = colApi.getColumn(`${cd.colId}`, colApi.gridColumns, colApi.gridColumnsMap);
            const displayName = colApi.getDisplayNameForColumn(col);

            // Display for row number may be visible, but the displayName is an empty string.
            // We take advantage of the falsey value of empty strings.
            if (col.visible && displayName) colMap.set(displayName, cd.colId);
        });

        return colMap;
    }

    render() {
        return (
            <CommonContext.Consumer>
                {(value) => (
                    <SmallButton
                        title="Export Loaded Results"
                        disabled={!!value.formIsOpen}
                        onClick={() => {
                            this.onExportClick(this.props.gridApi);
                        }}
                    >
                        <i className="fa fa-file-excel fa-md mr-2" />
                        <span className="ml-2 small-viewport-hide">
              Export Loaded Results
                        </span>
                    </SmallButton>
                )}
            </CommonContext.Consumer>
        );
    }
}

export class DataGridRefreshButton extends React.Component {
    render() {
        return (
            <CommonContext.Consumer>
                {(value) => (
                    <SmallButton
                        title="Refresh"
                        disabled={
                            !!value.formIsOpen || !!this.props.loading
                        }
                        onClick={() => {
                            this.props.gridApi.setDatasource(
                                this.props.dataSource,
                            );
                        }}
                    >
                        {!this.props.loading && (
                            <i className="fa fa-sync-alt fa-md" />
                        )}
                        <span className="ml-2 small-viewport-hide">
                            {this.props.loading
                                ? 'Loading Data...'
                                : 'Refresh'}
                        </span>
                    </SmallButton>
                )}
            </CommonContext.Consumer>
        );
    }
}

export class DataGridFilterClearButton extends React.Component {
    render() {
        return (
            <CommonContext.Consumer>
                {(value) => (
                    <SmallButton
                        disabled={
                            !!value.formIsOpen || !!this.props.loading
                        }
                        onClick={() => {
                            this.props.gridApi.setFilterModel(null);
                            this.props.gridApi.onFilterChanged();
                        }}
                    >
                        <i className="fa fa-minus-circle fa-md" />
                        <span className="ml-2 small-viewport-hide">
              Clear Filters
                        </span>
                    </SmallButton>
                )}
            </CommonContext.Consumer>
        );
    }
}

export function indexCellRenderer(params) {
    if (params.value === undefined) {
        return <span className="fa fa-spin fa-circle-notch text-danger" />;
    }
    return params.rowIndex + 1;
}

export function booleanCellRenderer(params) {
    return (
        <span
            className={`text-uppercase badge badge-yes-no ${
                params.value ? 'badge-success' : 'badge-secondary'
            } p-1`}
        >
            {params.value ? 'Yes' : 'No'}
        </span>
    );
}

export function onSelectionChanged(event) {
    const selection = event.api.getSelectedRows();
    this.setState({ selectedRow: selection.length ? selection[0] : null });
}

export const TextFilterDefaults = {
    maxNumConditions: 1,
    filterOptions: ['contains'],
    suppressFilterButton: true,
};

export const DateFilterDefaults = {
    maxNumConditions: 1,
    filterOptions: ['inRange'],
    suppressFilterButton: true,
    debounceMs: 500,
};

export const BooleanFilterDefaults = {
    suppressFilterButton: true,
    options: [
        { name: 'Yes', id: 'true' },
        { name: 'No', id: 'false' },
    ],
    optionsLabel: 'name',
    optionsValue: 'id',
};

export class TimesheetNumberCellRenderer extends React.Component {
    static contextType = CommonContext;

    render() {
        if (!this.props.data) return null;

        return (
            <span>
                {this.props.data.timesheetNumber}
                {!!(!!this.props.data.timesheetNumber
                        && !!this.props.data.isSelfDispatching) && (
                    <span
                        className="ml-2 badge badge-info"
                        style={{ fontSize: '1em', marginTop: '-2px' }}
                    >
                        {this.props.data.isNonFlagging ? 'NF' : 'SD'}
                    </span>
                )}
            </span>
        );
    }
}

export const EmployeeAccountStatusRenderer = ({ data, nameField, className = '' }) => {
    if (!data) return null;

    return (
        <span className={data.onBoarding ? `text-danger ${className}` : ''}>
            {data[nameField]}
        </span>
    );
}

// uses an array accessor, but has enough smarts to
// traverse the object graph to fulfill nested lookups
// will also deal with an or situation
function getTraversedValue(object, nameField) {
    if (nameField.includes('||')) {
        const splitNameFields = nameField.split('||');

        let discoveredValue = null;

        splitNameFields.forEach((nf) => {
            const splitValue = getTraversedValue(object, nf.trim());

            if (splitValue) {
                discoveredValue = splitValue;
            }
        });

        if (discoveredValue) {
            return discoveredValue;
        }
    }

    let returnValue = object[nameField];

    if (nameField.includes('.')) {
        const splitStringArray = nameField.split('.');

        let traversedValue = object;

        splitStringArray.forEach(
            (propName) => (traversedValue = traversedValue
                ? traversedValue[propName]
                : null),
        );

        returnValue = traversedValue;
    }

    return returnValue;
}

export class LinkCellRenderer extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick = () => {
        if (this.props.clicked) {
            this.props.clicked(this.props.data[this.props.idField])
        }
    };

    renderLink() {
        if (!this.props.data) return null;
    
        if (this.props.to) {
            return (
                <Link
                    className="site-link"
                    to={this.props.to(this.props.data[this.props.idField])}
                    onClick={this.handleClick}
                >
                    {this.props.data[this.props.nameField]}
                </Link>
            )
        }

        if (this.props.href) {
            return (
                <a 
                    className="site-link"
                    rel="noopener noreferrer"
                    target="_blank"
                    href={this.props.href(this.props.data[this.props.idField])}
                    onClick={this.handleClick}
                >
                    {this.props.data[this.props.nameField]}
                </a>
            )
        }

        if (this.props.clicked) {
            return (
                <span
                    className="site-link"
                    onClick={this.handleClick}
                    role="button"
                >
                    {this.props.data[this.props.nameField]}
                </span>
            )
        }

        return null;
    }

    render() {
        const { entity } = this.props;

        if (!this.props.data) return null;


        if (entity) {
            return (
                <>
                    <Can I="edit" a={entity}>
                        {this.renderLink()}
                    </Can>

                    <Can not I="edit" a={entity}>
                        {this.props.data[this.props.nameField]}
                    </Can>
                </>
            );
        }

        return this.renderLink();
    }
}

export class EditLinkCellRenderer extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.onClicked = this.onClicked.bind(this);
    }

    onClicked = () => this.props.clicked(this.props.data[this.props.idField]);

    render() {
        if (!this.props.data || !this.props.entity) return null;

        const {
            data, entity, nameField, title,
        } = this.props;

        const displayName = getTraversedValue(data, nameField);

        return (
            <>
                <Can I="edit" a={entity}>
                    <span
                        className="site-link"
                        disabled={!!this.context.formIsOpen}
                        title={title}
                        onClick={this.onClicked}
                    >
                        {displayName}
                    </span>
                </Can>
                <Can not I="edit" a={entity}>
                    {displayName}
                </Can>
            </>
        );
    }
}

export class ConditionalEditLinkCellRenderer extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.onClicked = this.onClicked.bind(this);
    }

    onClicked = () => this.props.clicked(this.props.data[this.props.idField]);

    render() {
        if (!this.props.data || !this.props.entity) return null;

        const { show, warn } = this.props.value;

        const displayValueObject = this.props.displayValues.find(
            (dv) => dv.value === show,
        );
        const displayValue = displayValueObject
            ? displayValueObject.text
            : 'NOT FOUND';

        return this.props.value ? (
            <>
                <Can I="edit" a={this.props.entity}>
                    <span
                        className="site-link"
                        disabled={!!this.context.formIsOpen}
                        title={this.props.title}
                        onClick={this.onClicked}
                    >
                        {displayValue}
                        {warn && (
                            <FontAwesomeIcon
                                className="ml-1"
                                icon={faExclamationTriangle}
                            />
                        )}
                    </span>
                </Can>
                <Can not I="edit" a={this.props.entity}>
                    {displayValue}
                    {warn && (
                        <FontAwesomeIcon
                            className="ml-1"
                            icon={faExclamationTriangle}
                        />
                    )}
                </Can>
            </>
        ) : (
            <>
                {displayValue}
                {warn && (
                    <FontAwesomeIcon
                        className="ml-1"
                        icon={faExclamationTriangle}
                    />
                )}
            </>
        );
    }
}

export class VariableLinkCellRenderer extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.onClicked = this.onClicked.bind(this);
    }

    onClicked = () => this.props.clicked(this.props.data[this.props.idField]);

    render() {
        if (!this.props.data) return null;

        const vg = this.props.valueGetter;

        // 2022-09-09 - M. Nicol
        // Added check for valueGetter so we can use custom text if needed.
        // Basically use the valueGetter function if one is supplied or the value of the data's nameField
        // otherwise.
        const displayText = vg
            ? vg(this.props)
            : this.props.data[this.props.nameField];

        if (this.props.isLink) {
            return (
                <span
                    className="site-link"
                    disabled={!!this.context.formIsOpen}
                    title={this.props.title}
                    onClick={this.onClicked}
                >
                    {displayText}
                </span>
            );
        }
        return <>{displayText}</>;
    }
}

export class ButtonCellRenderer extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.onClicked = this.onClicked.bind(this);
    }

    onClicked = () => this.props.clicked(this.props.data[this.props.idField]);

    render() {
        if (!this.props.data || !!this.props.hide) return null;
        return (
            <SmallButton
                type="button"
                disabled={!!this.props.formIsOpen}
                onClick={this.onClicked}
            >
                <i className="fa fa-plus-circle fa-md mr-2" />
                {this.props.buttonText}
            </SmallButton>
        );
    }
}

export class PermissionButtonCellRenderer extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.onClicked = this.onClicked.bind(this);
    }

    onClicked = () => this.props.clicked(this.props.data[this.props.idField]);

    render() {
        if (!this.props.data) return null;
        if (!!this.props.renderCondition && !this.props.renderCondition(this)) return null;
        return (
            <Can do={this.props.action} on={this.props.category}>
                <SmallButton
                    type="button"
                    disabled={!!this.props.formIsOpen}
                    onClick={this.onClicked}
                >
                    <i className="fa fa-plus-circle fa-md mr-2" />
                    {this.props.buttonText}
                </SmallButton>
            </Can>
        );
    }
}

export class IconCellRenderer extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.onClicked = this.onClicked.bind(this);
    }

    onClicked = () => this.props.clicked(this.props.data[this.props.idField]);

    render() {
        if (!this.props.data) return null;
        return (
            <SmallButton
                type="button"
                disabled={!!this.props.formIsOpen}
                onClick={this.onClicked}
            >
                <i className={`fa ${this.props.iconClass} fa-md mr-2`} />
            </SmallButton>
        );
    }
}

export class FlagIconCellRenderer extends React.Component {
    static contextType = CommonContext;

    render() {
        if (
            !this.props.data
            || this.props.data[this.props.bodyField].length == 0
        ) return null;

        const title = this.props.data.flag.map((f) => f).join('\u000d'); // line breaks
        return (
            <Button
                color="outline-primary"
                style={{
                    border: 'none',
                    borderRadius: '0 !important',
                    whiteSpace: 'pre-line',
                }}
                type="button"
                size="sm"
                data-html="true"
                title={title}
            >
                <i className="fa fa-flag fa-md mr-2" />
            </Button>
        );
    }
}

/*
 * RLC: Prints the range selected in the date range filter, since AgGrid's
 * native implementation only displays UTC.........
 */
export class DateRangeReadOnlyFloatingFilterComponent {
    init(params) {
        this.eGui = document.createElement('div');
        this.eGui.classList.add('ag-wrapper');
        this.eGui.classList.add('ag-input-wrapper');
        this.eGui.classList.add('ag-text-field-input-wrapper');
        this.eGui.innerHTML = '<input class="ag-input-field-input ag-text-field-input" type="text" disabled readonly />';
        this.currentValue = null;
        this.eFilterInput = this.eGui.querySelector('input');
        this.eFilterInput.style.color = params.color;

        const onInputBoxChanged = debounce(async (event) => {
            if (this.eFilterInput.value === '') {
                // clear the filter
                params.parentFilterInstance((instance) => {
                    instance.onFloatingFilterChanged(null, null);
                });
                return;
            }

            this.currentValue = this.eFilterInput.value;
            params.parentFilterInstance((instance) => {
                instance.onFloatingFilterChanged(this.currentValue);
            });
        }, 2000);
        this.eFilterInput.addEventListener('input', onInputBoxChanged);
    }

    onParentModelChanged(parentModel) {
    // When the filter is empty we will receive a null message here
        if (!parentModel) {
            this.eFilterInput.value = '';
            this.currentValue = null;
        } else {
            const rangeFormatted = `${moment(parentModel.dateFrom).format(
                'M/D/YY',
            )} -  ${moment(parentModel.dateTo).format('M/D/YY')}`;
            this.eFilterInput.value = rangeFormatted;
            this.currentValue = rangeFormatted;
        }
    }

    getGui() {
        return this.eGui;
    }
}

// Standardized components for common data grid use.
export function getComponents() {
    return {
        selectFilter: DataGridSelectFilter,
        selectFloatingFilter: DataGridSelectFloatingFilter,
        nameRenderer: LinkCellRenderer,
        iconRenderer: IconCellRenderer,
    };
}
