import { faTasks, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withRouter } from 'react-router-dom';

import {
    Button,
    FormGroup,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormText,
    Progress,
} from 'reactstrap';
import Select from 'react-select';
import * as moment from 'moment';
import CommonContext, { ApiRoutes, ApplicationPermissions } from '../Common';
import {
    createDataSource,
    createGridOptions,
    DataGrid,
    indexCellRenderer,
    LinkCellRenderer,
    IconCellRenderer,
    VariableLinkCellRenderer,
    TextFilterDefaults,
    DateFilterDefaults,
    FlagIconCellRenderer,
} from '../common/dataGrid/DataGrid';
import { CompactSelectStyles } from '../common/forms/ValidatedSelect';
import BillingChargeSlideout from './BillingChargeSlideout';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import {
    PageHeading,
    PageWrap,
    toasty,
    FormLabel,
    onFieldChange,
} from '../common/forms/FormElements';
import { handleFormSaveError } from '../common/forms/ValidationError';
import { util } from '../Util';
import CustomCircularProgress from '../common/CustomCircularProgress';
import { NotAuthorizedPage } from '../status/StatusCodes';
import TimesheetDetails from '../timesheet/TimesheetDetails';
import { BillingFlags } from '../billing/Billing';
import { ChargeTypeUnits } from '../chargeType/ChargeType';
import TimeEntry from '../common/forms/TimeEntry';
import BillingChargeEmployeeSlideout from '../billingChargeCategory/BillingChargeEmployeeSlideout';
import InvoiceSlideout from '../invoice/InvoiceSlideout';
import { getTenantUserProfile } from '../common/TenantUserProfile';

class BillingChargeIndex extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.billingChargeSlideoutRef = React.createRef();
        this.timesheetDetailsRef = React.createRef();
        this.billingChargeEmployeeSlideoutRef = React.createRef();
        this.invoiceSlideoutRef = React.createRef();

        this.state = {
            loading: true,
            rowData: [],
            rowsSelected: [],
            billingChargeSlideoutOpen: false,
            timesheetDetailsOpen: false,
            selectedDetails: [],
            showRejectBillingChargeModal: false,
            showProcessModal: false,
            isProcessing: false,
            isRejectingBilling: false,
            adminRejecting: false,
            selectedRowRejectionNotes: '',
            loadingReport: false,
            showExportModal: false,
            isExporting: false,
            isApproving: false,
            selectedDispatchLocation: null,
            selectedWeekOf: '',
            showAddChargeModal: false,
            isAddingCharge: false,
            isVoidingBilling: false,
            isUnVoidingBilling: false,
            voidNotes: '',
            isAdmin: false,
        };

        this.onRowClicked = this.onRowClicked.bind(this);
        this.onCellClicked = this.onCellClicked.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onRowSelected = this.onRowSelected.bind(this);
    }

    componentDidMount() { return this.populateState(); }

    componentWillUnmount() {
        return this.setState = (state, callback) => {

        };
    }

    onAddCharge = async () => {
        const {
            selectedTimesheet,
            selectedChargeType,
            selectedResource,
            chargeStartTime,
            chargeEndTime,
            totalChargeTime,
            chargeNotes,
        } = this.state;

        if (
            selectedTimesheet == null
            || selectedChargeType == null
            || selectedResource == null
            || totalChargeTime == 0
            || !chargeNotes
            || chargeNotes.length == 0
        ) {
            toasty.error('Error: All fields must have a value');
            return;
        }

        this.setState({ isAddingCharge: true });

        try {
            const data = {
                timesheetId: selectedTimesheet.value,
                chargeTypeId: selectedChargeType.value,
                chargeTypeUnitsId: selectedChargeType.type,
                resourceId: selectedResource.value,
                start: chargeStartTime,
                end: chargeEndTime,
                value: totalChargeTime,
                notes: chargeNotes,
            };

            await util.fetch.post(ApiRoutes.billingCharge.add(), data);

            toasty.success('Charge Added.');
            this.state.gridOptions.refresh();
        } catch {
            toasty.error(
                'There was an issue adding the charge record.  Please try your request again or contact support for assistance.',
            );
        } finally {
            this.setState({ isAddingCharge: false, showAddChargeModal: false });
        }
    };

    onApproveButtonClick = async () => {
        this.setState({ isApproving: true });

        const { rowsSelected } = this.state;

        // call accept
        if (rowsSelected) {
            const model = rowsSelected.map((x) => x.billingId);
            const response = await util.fetch
                .post(ApiRoutes.billingCharge.approve(), model)
                .catch(this.handleSaveError);

            if (response) {
                toasty.error(`Error approving billing records: ${response}`);
            } else {
                toasty.success('Billing records approved');
            }
        }

        this.state.gridOptions.refresh();

        this.setState({ rowsSelected: [], isApproving: false });
    };

    onBillingChargeEmployeeOpenInvoice = async (id) => {
        if (id) {
            const model = {
                billingIds: null,
                invoiceId: id,
            };

            await this.context.setFormOpened(true);
            this.invoiceSlideoutRef.current.open(model);
        }
    };

    onBillingChargeEmployeeOpenTimesheet = async (timesheetId) => {
        await this.context.setFormOpened(false);
        this.setState({ selectedRow: null });

        if (timesheetId) {
            await this.context.setFormOpened(true);
            this.timesheetDetailsRef.current.open(timesheetId);
        }
    };

    onBillingChargeEmployeeSlideoutClosed = async () => {
        await this.context.setFormOpened(false);
        this.setState({ selectedRow: null });
    // this.state.gridOptions.refresh();
    };

    onBillingChargeEmployeeSlideoutOpen = async (id) => {
        this.setState({ selectedChargeId: id });

        if (id) {
            await this.context.setFormOpened(true);
            this.billingChargeEmployeeSlideoutRef.current.open(id);
        }
    };

    onBillingChargeSlideoutClosed = async () => {
        await this.context.setFormOpened(false);
        this.setState({ selectedRow: null });
        this.state.gridOptions.refresh();
    };

    onBillingChargeSlideoutOpen = async (id) => {
        if (id) {
            await this.context.setFormOpened(true);
            this.billingChargeSlideoutRef.current.open(id);
        }
    };

    onCellClicked = (data) => {
    // test
        alert(data);
    };

    onChange = onFieldChange;

    onChargeNotesChanged = (ev) => {
        const val = ev.target.value;
        this.setState({ chargeNotes: val });
    };

    onChargeValueChanged = (ev) => {
        const val = ev.target.value;
        this.setState({ totalChargeTime: val });
    };

    onInvoiceSlideoutClosed = async () => {
        const { selectedChargeId } = this.state;

        await this.context.setFormOpened(false);
        this.setState({ selectedRow: null });

        this.onBillingChargeEmployeeSlideoutOpen(selectedChargeId);
    };

    onProcessClick = async () => {
        const { selectedWeekOf, selectedDispatchLocation } = this.state;

        if (!selectedWeekOf || !selectedDispatchLocation) return;

        this.setState({ isProcessing: true });

        const model = {
            weekOf: new Date(selectedWeekOf),
            dispatchLocationId: selectedDispatchLocation,
        };

        try {
            const response = await util.fetch.post(
                ApiRoutes.billingCharge.process(),
                model,
            );
            if (response) {
                toasty.error(response);
            } else {
                toasty.success('Processing complete');
            }
        } catch {
            toasty.error('Error processing records');
        } finally {
            this.state.gridOptions.refresh();

            this.setState({
                rowsSelected: [],
                isProcessing: false,
                showProcessModal: false,
                selectedWeekOf: '',
                selectedDispatchLocation: null,
            });
        }
    };

    onRejectBilling = async () => {
        const { rowsSelected, selectedRowRejectionNotes, adminRejecting } = this.state;

        if (
            selectedRowRejectionNotes.length === 0
            || !selectedRowRejectionNotes.trim()
        ) return;
        this.setState({ isRejectingBilling: true });

        // call reject
        if (rowsSelected) {
            const model = {
                billingId: rowsSelected[0].billingId,
                note: selectedRowRejectionNotes,
            };

            const endPoint = adminRejecting == true
                ? ApiRoutes.billingCharge.adminReject()
                : ApiRoutes.billingCharge.reject();
            const response = await util.fetch
                .post(endPoint, model)
                .catch(this.handleSaveError);

            if (response) {
                toasty.success('Billing: Charges Rejected');
            }
        }

        this.state.gridOptions.refresh();

        this.setState({
            selectedRow: null,
            showRejectBillingChargeModal: false,
            selectedRowRejectionNotes: '',
            isRejectingBilling: false,
            adminRejecting: false,
        });
    };

    onRowClicked = (event) => {
        const selection = event.api.getSelectedRows();
        const row = selection.length ? selection[0] : null;

        if (row) {
            this.setState({ selectedRow: row });
            // this.openTimesheetDetails();
        }
    };

    // https://stackoverflow.com/questions/44263350/count-number-of-selected-rows-in-ag-grid
    // Possibly use lodash dequeue?
    onRowSelected(e) {
        const rs = e.api.getSelectedRows();

        this.setState({
            rowsSelected: rs,
        });
    }

    onSelectedChargeTypeChanged = async (selection) => {
        const { selectedTimesheet } = this.state;
        this.loadChargeResourcesList(selectedTimesheet, selection);
    };

    onSelectedDispatchLocationChanged = async (selection) => {
        const loc = { ...selection };
        this.setState({ selectedDispatchLocation: loc.value });
    };

    onSelectedResourceChanged = async (selection) => {
        this.setState({ selectedResource: selection });
    };

    onSelectedTimesheetChanged = async (selection) => {
        const billingChargeTypes = await util.fetch.js(
            ApiRoutes.typeAheads.billingChargeTypes(selection.value),
        );

        this.setState({
            selectedTimesheet: selection,
            billingChargeTypes,
            selectedChargeType: null,
            selectedResource: null,
            billingChargeResources: [],
        });
    };

    onTimeEntryEndChanged = (ev) => {
        const val = ev.target.value;
        const timeValue = ev.target.value
            ? moment(val, ['h:mm A']).format('HH:mm')
            : '';

        const { selectedTimesheet, chargeStartTime } = { ...this.state };
        const total = this.getTotalHours(
            selectedTimesheet,
            chargeStartTime,
            timeValue,
        );

        this.setState({ totalChargeTime: total, chargeEndTime: timeValue });
    };

    onTimeEntryStartChanged = (ev) => {
        const val = ev.target.value;
        const timeValue = ev.target.value
            ? moment(val, ['h:mm A']).format('HH:mm')
            : '';

        const { selectedTimesheet, chargeEndTime } = { ...this.state };
        const total = this.getTotalHours(
            selectedTimesheet,
            timeValue,
            chargeEndTime,
        );

        this.setState({ totalChargeTime: total, chargeStartTime: timeValue });
    };

    onTimesheetDetailsClosed = async () => {
        const { selectedChargeId } = this.state;

        await this.context.setFormOpened(false);
        this.setState({ selectedRow: null });

        this.onBillingChargeEmployeeSlideoutOpen(selectedChargeId);
    };

    onUnVoidBilling = async () => {
        const { rowsSelected } = this.state;

        this.setState({ isUnVoidingBilling: true });

        if (rowsSelected) {
            const response = await util.fetch
                .js(ApiRoutes.billingCharge.unvoid(rowsSelected[0].billingId))
                .catch(this.handleSaveError);

            if (response != null) {
                toasty.success('Billing: Charges set Pending');
            }
        }

        this.state.gridOptions.refresh();

        this.setState({ rowsSelected: [], isUnVoidingBilling: false });
    };

    onVoidBilling = async () => {
        const { rowsSelected, voidNotes } = this.state;

        this.setState({ isVoidingBilling: true });

        // call reject
        if (rowsSelected) {
            const model = {
                billingId: rowsSelected[0].billingId,
                notes: voidNotes,
            };

            const response = await util.fetch.post(
                ApiRoutes.billingCharge.void(),
                model,
            );

            if (response) {
                toasty.error(response);
            } else {
                toasty.success('Billing: Charges Voided');
            }
        }

        this.state.gridOptions.refresh();

        this.setState({
            rowsSelected: [],
            showVoidBillingChargeModal: false,
            isVoidingBilling: false,
        });
    };

    getTotalHours = function (timesheet, start, end) {
    // Calculate the duration between Start and End.  Factor in if this enters into the next day.
    // Use the job start date/time as opposed to moment defaulting to the current day when getting duration.
        if (!!start && !!end) {
            const time_format = 'HH:mm';

            const mStartTime = moment(start, time_format);
            const mEndTime = moment(end, time_format);
            if (mEndTime.isBefore(mStartTime)) {
                mEndTime.add(1, 'day');
            }

            const dur = moment.duration(mEndTime.diff(mStartTime));
            const value = dur.asHours().toFixed(2);

            return value;
        }
    };

    downloadExport = async (id) => {
        if (id) {
            this.setState({ loadingReport: true });
            /* var model = { id: id }; */
            await util.fetch
                .downloadFile(
                    ApiRoutes.billing.download(id),
                    null,
                    'Billing.pdf',
                )
                .catch(this.handleSaveError);
            this.setState({ loadingReport: false });
        }
    };

    handleSaveError = (err) => handleFormSaveError(this, err);

    loadChargeResourcesList = async (selectedTimesheet, selectedChargeType) => {
        let billingChargeResources = [];

        if (
            selectedTimesheet
            && selectedChargeType
            && selectedTimesheet.dispatchLocationId > 0
        ) {
            if (selectedChargeType.resourceTypeId == 1) {
                billingChargeResources = await util.fetch.js(
                    ApiRoutes.invoice.slideoutStaff(
                        selectedTimesheet.dispatchLocationId,
                    ),
                );
                billingChargeResources = billingChargeResources.map((x) => ({ label: x.name, value: x.id }));
            } else {
                billingChargeResources = await util.fetch.js(
                    ApiRoutes.invoice.slideoutEquipment(
                        selectedTimesheet.dispatchLocationId,
                    ),
                );
                billingChargeResources = billingChargeResources.map((x) => ({ label: x.description, value: x.id }));
            }
        }

        this.setState({
            chargeStartTime: '',
            chargeEndTime: '',
            chargeNotes: '',
            totalChargeTime: 0,
            selectedResource: null,
            selectedTimesheet,
            selectedChargeType,
            billingChargeResources,
        });
    };

    openTimesheetDetails = async (id) => {
        if (id) {
            await this.context.setFormOpened(true);
            this.timesheetDetailsRef.current.open(id);
        }
    };

    populateState = async () => {
        const [
            tenantUserProfile,
            dispatchLocations,
            billingChargeStatuses,
            flags,
            chargeTypeUnits,
            pendingTimesheets,
        ] = await Promise.all([
            getTenantUserProfile(),
            util.fetch.js(ApiRoutes.typeAheads.dispatchLocations()),
            util.fetch.js(ApiRoutes.typeAheads.billingChargeStatuses()),
            util.fetch.js(ApiRoutes.typeAheads.billingChargeFlags()),
            util.fetch.js(ApiRoutes.typeAheads.chargeTypeUnits()),
            util.fetch.js(ApiRoutes.typeAheads.pendingBillingTimesheets()),
        ]);

        const { userPermissions } = { ...tenantUserProfile };
        const canVoid = userPermissions.includes(ApplicationPermissions.billing_void);

        const gridOptions = createGridOptions(this);

        // https://www.ag-grid.com/documentation/javascript/row-selection/#checkbox-selection
        // One column must have "checkboxSelection" set to true.
        // Note: headerCheckboxSelection not available with infinite scroll.
        gridOptions.rowSelection = 'multiple';

        gridOptions.postProcessData = this.transformRowData;
        gridOptions.onRowSelected = this.onRowSelected;

        gridOptions.rowClassRules = {
            'ag-row-warning': (params) => {
                const matches = ((params.data ?? {}).flag ?? []).filter((f) => f === BillingFlags.BillNotEqualToCharge);

                return matches.length > 0;
            },
            'ag-row-otjobstart': (params) => {
                const matches = ((params.data ?? {}).flag ?? []).filter((f) => f === BillingFlags.OTJobStartRange);

                return matches.length > 0;
            },
            'ag-row-info': (params) => {
                const matches = ((params.data ?? {}).flag ?? []).filter((f) => f === BillingFlags.MultipleJobs);

                return matches.length > 0;
            },
            'ag-row-success': (params) => {
                const matches = ((params.data ?? {}).flag ?? []).filter((f) => (
                    f === BillingFlags.ChargeTypeThresholdExceeded
                        || f === BillingFlags.BreakThresholdExceeded
                ));

                return matches.length > 0;
            },
        };

        gridOptions.components = {
            selectFilter: DataGridSelectFilter,
            selectFloatingFilter: DataGridSelectFloatingFilter,
            nameRenderer: LinkCellRenderer,
            iconRenderer: IconCellRenderer,
            flagIconRenderer: FlagIconCellRenderer,
            variableLinkRenderer: VariableLinkCellRenderer,
        };
        gridOptions.onRowClicked = this.onRowClicked;

        const dispatchFilterParams = {
            suppressFilterButton: true,
            options: dispatchLocations,
            optionsLabel: 'label',
            optionsValue: 'value',
        };
        const billingStatusFilterParams = {
            suppressFilterButton: true,
            options: billingChargeStatuses,
            optionsLabel: 'label',
            optionsValue: 'value',
        };
    
        const flagsFilterParams = {
            suppressFilterButton: true,
            options: flags,
            optionsLabel: 'label',
            optionsValue: 'value',
        };
        const chargeTypeUnitsFilterParams = {
            suppressFilterButton: true,
            options: chargeTypeUnits,
            optionsLabel: 'label',
            optionsValue: 'value',
        };

        gridOptions.columnDefs = [
            {
                headerName: '',
                valueGetter: 'node.id',
                sortable: false,
                hide: true,
                flex: 1,
                maxWidth: 35,
                cellRenderer: indexCellRenderer,
            },
            {
                colId: 'Billing.JobDate',
                headerName: 'Date',
                field: 'date',
                sortable: true,
                flex: 1.5,
                sort: { direction: 'asc', priority: 0 },
                filter: 'agDateColumnFilter',
                filterParams: DateFilterDefaults,
                // floatingFilterComponentParams: {
                //    suppressFilterButton: true,
                // }
            },
            {
                colId: 'Billing.Timesheet.TimesheetNumber',
                headerName: 'Timesheet #',
                field: 'timesheetNumber',
                sortable: true,
                flex: 2,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
                cellRenderer: (params) => {
                    if (params.data) {
                        if (params.data.timesheetId) {
                            return (
                                <span
                                    className="site-link"
                                    disabled={!!this.context.formIsOpen}
                                    title="OpenTimesheet"
                                    onClick={() => {
                                        this.openTimesheetDetails(
                                            params.data.timesheetId,
                                        );
                                    }}
                                >
                                    {params.data.timesheetNumber}
                                </span>
                            );
                        }

                        return params.data.timesheetNumber;
                    }

                    return null;
                },
            },
            {
                colId: 'customerName',
                headerName: 'Customer Name',
                field: 'customerName',
                sortable: true,
                flex: 2,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'Billing.DispatchLocationId',
                headerName: 'Dispatching',
                field: 'dispatchOfficeName',
                sortable: true,
                flex: 2,
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: dispatchFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: dispatchFilterParams,
            },
            {
                colId: 'TimesheetDetail.ResourceName',
                headerName: 'Resource',
                field: 'resourceName',
                sortable: true,
                flex: 2,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'outOfOrderType',
                minWidth: 85,
                headerName: '',
                sortable: false,
                cellRenderer: (params) => {
                    if (
                        params.data
                        && params.data.resourceTypeId == 1
                        && params.data.employeeHasBillPeriod
                    ) {
                        return (
                            <span
                                className="site-button-small btn btn-outline-primary btn-sm"
                                style={{ border: 'none' }}
                                title="View all charges this period"
                                onClick={() => {
                                    this.onBillingChargeEmployeeSlideoutOpen(
                                        params.data.id,
                                    );
                                }}
                            >
                                <i className="fas fa-history" />
                            </span>
                        );
                    }

                    return null;
                },
            },
            {
                colId: 'TimesheetDetail.ChargeTypeName',
                headerName: 'Charge Type',
                field: 'chargeTypeName',
                sortable: true,
                flex: 3,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
                cellRenderer: (params) => {
                    if (params.data) {
                        if (params.data.hasBreak) {
                            return (
                                <span>
                                    {params.data.chargeTypeName}
                                    <span
                                        className="ml-2 badge badge-info"
                                        style={{
                                            fontSize: '1em',
                                            marginTop: '-2px',
                                        }}
                                        title={`Hours: ${params.data.breakHours}`}
                                    >
                    B
                                    </span>
                                </span>
                            );
                        }

                        return params.data.chargeTypeName;
                    }

                    return null;
                },
            },
            {
                colId: 'Start',
                headerName: 'Start',
                field: 'start',
                sortable: true,
                flex: 1.5,
                filter: 'agDateColumnFilter',
                filterParams: DateFilterDefaults,
            },
            {
                colId: 'End',
                headerName: 'End',
                field: 'end',
                sortable: true,
                flex: 2,
                filter: 'agDateColumnFilter',
                filterParams: DateFilterDefaults,
            },
            {
                colId: 'TimesheetDetail.ChargeTypeUnitsId',
                headerName: 'Units',
                field: 'chargeTypeUnitsName',
                minWidth: 105,
                sortable: true,
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: chargeTypeUnitsFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: chargeTypeUnitsFilterParams,
            },
            {
                colId: 'Charge',
                headerName: 'Charge',
                field: 'charge',
                minWidth: 85,
                sortable: false,
            },
            {
                colId: 'Bill',
                headerName: 'Bill',
                field: 'bill',
                minWidth: 65,
                sortable: false,
            },
            // {
            //    colId: 'Pay',
            //    headerName: 'Pay',
            //    field: 'pay',
            //    minWidth: 65,
            //    sortable: false

            // },
            {
                colId: 'BillingStatusId',
                headerName: 'Status',
                field: 'billingStatus',
                sortable: true,
                flex: 1.5,
                tooltipField: 'status',
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: billingStatusFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: billingStatusFilterParams,
            },
            {
                colId: 'Flag',
                headerName: 'Flag',
                field: 'flag',
                flex: 1,
                minWidth: 65,
                sortable: false,
                tooltipField: 'flag',
                tooltipComponentParams: { color: '#ececec' },
                cellRenderer: 'flagIconRenderer',
                cellRendererParams: {
                    bodyField: 'flag',
                },
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: flagsFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: flagsFilterParams,
            },
            {
                colId: 'Id',
                minWidth: 65,
                headerName: 'Edit',
                sortable: false,
                cellRenderer: 'iconRenderer',
                cellRendererParams: {
                    clicked: this.onBillingChargeSlideoutOpen,
                    idField: 'id',
                    iconClass: 'fa-edit',
                },
            },
            {
                colId: '',
                headerName: '',
                field: 'selectionPlaceholder',
                sortable: false,
                minWidth: 50,
                checkboxSelection: true,
            },
        ];

        const dataSource = createDataSource(
            ApiRoutes.billingCharge.search(),
            gridOptions,
        );

        this.setState({
            loading: false,
            gridOptions,
            dataSource,
            isRejectingTimesheet: false,
            dispatchLocations,
            pendingTimesheets,
            billingChargeTypes: [],
            billingChargeResources: [],
            selectedTimesheet: null,
            selectedChargeType: null,
            isAdmin: this.context?.user?.isAdmin,
            canVoid,
        });
    };

    printTimesheet = async (id) => {
        if (id) {
            window.open(ApiRoutes.report.timesheetBilling(id), '_self');
        }
    };

    toggleAddChargeModal = async () => {
        const { showAddChargeModal } = this.state;

        this.setState({
            showAddChargeModal: !showAddChargeModal,
            billingChargeResources: [],
            selectedTimesheet: null,
            selectedChargeType: null,
            selectedResource: null,
            chargeStartTime: 0,
            chargeEndTime: 0,
            totalChargeTime: 0,
            chargeNotes: '',
        });
    };

    toggleProcessBillingChargeModal = () => {
        const { showProcessModal } = this.state;
        this.setState({ showProcessModal: !showProcessModal });
    };

    toggleRejectBillingChargeModal = async () => {
        const { showRejectBillingChargeModal, rowsSelected } = this.state;

        const adminRequired = await util.fetch.get(
            ApiRoutes.billingCharge.needsAdminReject(rowsSelected[0].billingId),
        );

        // if admin not needed just show regular
        if (adminRequired == false) {
            this.setState({
                showRejectBillingChargeModal: !showRejectBillingChargeModal,
            });
            return;
        }

        // if admin needed and user is not admin
        if (adminRequired == true && !this.context?.user?.isAdmin) {
            toasty.info(
                'An admin needs to reject this timesheet due to it being approved already',
            );
            return;
        }

        // if admin needed and is an admin
        if (adminRequired == true && this.context?.user?.isAdmin) {
            this.setState({
                showRejectBillingChargeModal: !showRejectBillingChargeModal,
                adminRejecting: true,
            });
        }
    };

    toggleVoidBillingChargeModal = () => {
        const { showVoidBillingChargeModal } = this.state;
        this.setState({
            showVoidBillingChargeModal: !showVoidBillingChargeModal,
        });
    };

    render() {
        const {
            rowData,
            gridOptions,
            showRejectBillingChargeModal,
            showProcessModal,
            isRejectingBilling,
            isProcessing,
            selectedRowRejectionNotes,
            voidNotes,
            showVoidBillingChargeModal,
            rowsSelected,
            isApproving,
            isVoidingBilling,
            dispatchLocations,
            selectedDispatchLocation,
            selectedWeekOf,
            adminRejecting,
            showAddChargeModal,
            isAddingCharge,
            pendingTimesheets,
            billingChargeTypes,
            billingChargeResources,
            selectedResource,
            selectedChargeType,
            chargeStartTime,
            chargeEndTime,
            totalChargeTime,
            chargeNotes,
            isUnVoidingBilling,
            isAdmin,
            canVoid,
        } = this.state;

        // TODO: Eliminate inline styles.

        return (
            <CommonContext.Consumer>
                {(value) => {
                    const { tenantSettings } = (value ?? {}).tenant ?? {};

                    if (this.state.loading || !tenantSettings) {
                        return (
                            <Progress />
                        );
                    }

                    if (
                        !tenantSettings.billingEnabled
                        || !tenantSettings.invoicingEnabled
                    ) return <NotAuthorizedPage />;

                    return (
                        <PageWrap>
                            <PageHeading>
                                <FontAwesomeIcon
                                    icon={faTasks}
                                    className="mr-2 text-muted"
                                />
                                <span>Billing: Charges</span>
                                <span
                                    style={{
                                        float: 'right',
                                        position: 'relative',
                                        top: '-5px',
                                    }}
                                >
                                    {rowsSelected.length > 1 && (
                                        <Button
                                            size="sm"
                                            color="success"
                                            style={{ marginRight: '40px' }}
                                            onClick={() => this.onApproveButtonClick()}
                                            disabled={
                                                rowsSelected.length === 0
                                                || isApproving
                                            }
                                        >
                    Approve Selected
                                        </Button>
                                    )}
                                    {rowsSelected.length === 1 && (
                                        <>
                                            {(
                                                rowsSelected[0] ?? {
                                                    billingStatus:
                                                        'Not Selected',
                                                }
                                            ).billingStatus != 'Approved' && (
                                                <Button
                                                    size="sm"
                                                    color="success"
                                                    style={{
                                                        marginRight: '10px',
                                                    }}
                                                    onClick={() => this.onApproveButtonClick()}
                                                    disabled={
                                                        rowsSelected.length
                                                            === 0 || isApproving
                                                    }
                                                >
                      Approve Selected
                                                </Button>
                                            )}
                                            <Button
                                                size="sm"
                                                color="danger"
                                                style={{ marginRight: '10px' }}
                                                onClick={() => this.toggleRejectBillingChargeModal()}
                                                disabled={
                                                    rowsSelected.length !== 1
                                                    || isRejectingBilling
                                                }
                                            >
                      Reject Selected
                                            </Button>
                                            {(isAdmin || canVoid)
                                                && (
                                                    rowsSelected[0] ?? {
                                                        billingStatus:
                                                            'Not Selected',
                                                    }
                                                ).billingStatus != 'Void' && (
                                                <Button
                                                    size="sm"
                                                    color="warning"
                                                    style={{
                                                        marginRight: '40px',
                                                    }}
                                                    onClick={() => this.toggleVoidBillingChargeModal()}
                                                    disabled={
                                                        rowsSelected.length
                                                            !== 1
                                                            || isVoidingBilling
                                                    }
                                                >
                                                  Void Selected
                                                </Button>
                                            )}
                                            {(
                                                rowsSelected[0] ?? {
                                                    billingStatus:
                                                        'Not Selected',
                                                }
                                            ).billingStatus == 'Void' && (
                                                <Button
                                                    size="sm"
                                                    color="warning"
                                                    style={{
                                                        marginRight: '40px',
                                                    }}
                                                    onClick={() => this.onUnVoidBilling()}
                                                    disabled={
                                                        rowsSelected.length
                                                            !== 1
                                                            || isUnVoidingBilling
                                                    }
                                                >
                      Set Selected Pending
                                                </Button>
                                            )}
                                        </>
                                    )}

                                    <Button
                                        size="sm"
                                        color="success"
                                        style={{ marginRight: '10px' }}
                                        onClick={() => this.toggleAddChargeModal()}
                                        disabled={isApproving}
                                    >
                    Add Charge
                                    </Button>

                                    <Button
                                        size="sm"
                                        color="success"
                                        onClick={() => this.toggleProcessBillingChargeModal()}
                                    >
                    Process
                                    </Button>
                                </span>
                            </PageHeading>

                            <DataGridToolbar
                                entity="Billing"
                                gridApi={this.state.gridApi}
                                dataSource={this.state.dataSource}
                                hideAdd
                                hideExcelButton
                                gridOptions={this.state.gridOptions}
                                serverExport={{
                                    apiPath:
                                        ApiRoutes.billingCharge.excelExport(),
                                    filePrefix: 'BillingChargeSearch',
                                }}
                                serverExportDisabled={
                                    !!this.state.saving
                                    || !!this.state.loading
                                    || !!this.state.loadingData
                                }
                            />
                            {!!this.state.loadingReport && (
                                <CustomCircularProgress />
                            )}

                            {!this.state.loadingReport && (
                                <DataGrid
                                    domLayout="normal"
                                    rowData={rowData}
                                    gridOptions={gridOptions}
                                    gridStatus={this.state.gridStatus}
                                />
                            )}
                            <BillingChargeSlideout
                                ref={this.billingChargeSlideoutRef}
                                show={this.state.billingChargeSlideoutOpen}
                                toggleShow={(open) => this.setState({
                                    billingChargeSlideoutOpen: open,
                                })}
                                onClose={this.onBillingChargeSlideoutClosed}
                            />
                            <BillingChargeEmployeeSlideout
                                ref={this.billingChargeEmployeeSlideoutRef}
                                show={
                                    this.state.billingChargeEmployeeSlideoutOpen
                                }
                                toggleShow={(open) => this.setState({
                                    billingChargeEmployeeSlideoutOpen: open,
                                })}
                                onClose={
                                    this.onBillingChargeEmployeeSlideoutClosed
                                }
                                onOpenInvoice={(invoiceId) => {
                                    this.onBillingChargeEmployeeOpenInvoice(
                                        invoiceId,
                                    );
                                }}
                                onOpenTimesheet={(timesheetId) => {
                                    console.log(timesheetId);
                                    this.onBillingChargeEmployeeOpenTimesheet(
                                        timesheetId,
                                    );
                                }}
                            />
                            <InvoiceSlideout
                                ref={this.invoiceSlideoutRef}
                                show={this.state.invoiceSlideoutOpen}
                                toggleShow={(open) => this.setState({ invoiceSlideoutOpen: open })}
                                onClose={this.onInvoiceSlideoutClosed}
                            />
                            <TimesheetDetails
                                ref={this.timesheetDetailsRef}
                                show={this.state.timesheetDetailsOpen}
                                toggleShow={(open) => this.setState({
                                    timesheetDetailsOpen: open,
                                })}
                                onClose={this.onTimesheetDetailsClosed}
                                onAccept={this.onAcceptTimesheet}
                                onReject={this.onRejectTimesheet}
                                onlyBillable
                                isBilling
                            />
                            <Modal
                                isOpen={showRejectBillingChargeModal}
                                toggle={this.toggleRejectBillingChargeModal}
                            >
                                <ModalHeader
                                    toggle={this.toggleRejectBillingChargeModal}
                                >
                  Billing: Reject Charges
                                </ModalHeader>
                                <ModalBody>
                                    {adminRejecting ? (
                                        <small
                                            style={{ marginBottom: '20px' }}
                                            className="form-text text-danger"
                                        >
                      This administrative action will
                      delete any approved payroll or
                      billing records, and reset the
                      timesheet. Only administrators will
                      be allowed to adjust and re-approve
                      this timesheet. Please take note of
                      Payroll and Billing implications.
                                        </small>
                                    ) : (
                                        <FormText
                                            style={{ marginBottom: '20px' }}
                                        >
                      This action will reject the
                      timesheet from billing review and
                      remove all charges from payroll
                      review.
                                        </FormText>
                                    )}
                                    <FormGroup>
                                        <FormLabel
                                            required
                                            text="Notes"
                                        />
                                        <textarea
                                            id="selectedRowRejectionNotes"
                                            name="selectedRowRejectionNotes"
                                            className="form-control"
                                            defaultValue={
                                                selectedRowRejectionNotes ?? ''
                                            }
                                            onChange={this.onChange}
                                            required
                                            placeholder="Enter notes regarding the rejection."
                                            type="text"
                                            maxLength="500"
                                            rows="5"
                                        />
                                        <small className="text-danger">
                      Notes are required.
                                        </small>
                                    </FormGroup>
                                </ModalBody>
                                <ModalFooter>
                                    {isRejectingBilling && (
                                        <FontAwesomeIcon
                                            icon={faCircleNotch}
                                            className="fa-spin mr-2"
                                            size="sm"
                                        />
                                    )}
                                    <Button
                                        color="primary"
                                        disabled={isRejectingBilling}
                                        onClick={this.onRejectBilling}
                                    >
                    Ok
                                    </Button>
                                    {' '}
                                </ModalFooter>
                            </Modal>

                            <Modal
                                isOpen={showVoidBillingChargeModal}
                                toggle={this.toggleVoidBillingChargeModal}
                            >
                                <ModalHeader
                                    toggle={this.toggleVoidBillingChargeModal}
                                >
                  Billing: Void Charges
                                </ModalHeader>
                                <ModalBody>
                                    <small
                                        style={{ marginBottom: '20px' }}
                                        className="form-text text-danger"
                                    >
                    This administrative action will void all
                    billing charges with the associated
                    timesheet. Only administrators will be
                    allowed to unvoid and re-approve this
                    voided charges.
                                    </small>

                                    <FormGroup>
                                        <FormLabel
                                            required
                                            text="Notes"
                                        />
                                        <textarea
                                            id="voidNotes"
                                            name="voidNotes"
                                            className="form-control"
                                            onChange={(evt) => {
                                                const { value } = evt.target;
                                                this.setState({
                                                    voidNotes: value,
                                                });
                                            }}
                                            required
                                            placeholder="Enter notes regarding the void."
                                            type="text"
                                            maxLength="500"
                                            rows="5"
                                        />
                                        <small className="text-danger">
                      Notes are required.
                                        </small>
                                    </FormGroup>
                                </ModalBody>
                                <ModalFooter>
                                    {isVoidingBilling && (
                                        <FontAwesomeIcon
                                            icon={faCircleNotch}
                                            className="fa-spin mr-2"
                                            size="sm"
                                        />
                                    )}
                                    <Button
                                        color="primary"
                                        disabled={
                                            isVoidingBilling
                                            || voidNotes.length == 0
                                        }
                                        onClick={this.onVoidBilling}
                                    >
                    Ok
                                    </Button>
                                    {' '}
                                </ModalFooter>
                            </Modal>

                            <Modal
                                isOpen={showAddChargeModal}
                                toggle={() => this.toggleAddChargeModal()}
                            >
                                <ModalHeader
                                    toggle={() => this.toggleAddChargeModal()}
                                >
                  Add Charge
                                </ModalHeader>
                                <ModalBody>
                                    <FormGroup>
                                        <label>Timesheet</label>
                                        <Select
                                            placeholder="Select Timesheet"
                                            id="selectedChargeTimesheet"
                                            name="selectedChargeTimesheet"
                                            styles={CompactSelectStyles}
                                            isClearable={false}
                                            className="react-select"
                                            options={pendingTimesheets}
                                            onChange={
                                                this.onSelectedTimesheetChanged
                                            }
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <label>Charge Type</label>
                                        <Select
                                            placeholder="Select Charge Type"
                                            id="selectedChargeType"
                                            name="selectedChargeType"
                                            styles={CompactSelectStyles}
                                            isClearable={false}
                                            className="react-select"
                                            value={(
                                                billingChargeTypes ?? []
                                            ).find(
                                                (x) => x.value
                                                    === selectedChargeType?.value,
                                            )}
                                            options={billingChargeTypes}
                                            onChange={
                                                this.onSelectedChargeTypeChanged
                                            }
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        {!!selectedChargeType
                                            && selectedChargeType.resourceTypeId
                                            == 1 && <label>Employee</label>}
                                        {!!selectedChargeType
                                            && selectedChargeType.resourceTypeId
                                            == 2 && <label>Equipment</label>}
                                        <Select
                                            placeholder="Select Resource"
                                            id="selectedChargeResource"
                                            name="selectedChargeResource"
                                            styles={CompactSelectStyles}
                                            isClearable={false}
                                            className="react-select"
                                            value={
                                                (
                                                    billingChargeResources ?? []
                                                ).find(
                                                    (x) => x.value
                                                        === selectedResource?.value,
                                                ) ?? ''
                                            }
                                            options={billingChargeResources}
                                            onChange={
                                                this.onSelectedResourceChanged
                                            }
                                        />
                                    </FormGroup>
                                    {!!selectedChargeType
                                        && selectedChargeType.type
                                        == ChargeTypeUnits.Hours && (
                                        <>
                                            <FormGroup
                                                className="payrollstart"
                                                style={{ width: '60px' }}
                                            >
                                                <label>Start</label>
                                                <TimeEntry
                                                    required
                                                    name="chargeStartTime"
                                                    increment={
                                                        selectedChargeType.increment
                                                            ?? 0.25
                                                    }
                                                    value={chargeStartTime}
                                                    onChange={(ev) => this.onTimeEntryStartChanged(
                                                        ev,
                                                    )}
                                                />
                                            </FormGroup>
                                            <FormGroup
                                                className="payrollend"
                                                style={{ width: '60px' }}
                                            >
                                                <label>End</label>
                                                <TimeEntry
                                                    required
                                                    name="chargeEndTime"
                                                    increment={
                                                        selectedChargeType.increment
                                                            ?? 0.25
                                                    }
                                                    value={chargeEndTime}
                                                    onChange={(ev) => this.onTimeEntryEndChanged(
                                                        ev,
                                                    )}
                                                />
                                            </FormGroup>
                                        </>
                                    )}

                                    <FormGroup>
                                        <label>Charge</label>
                                        <input
                                            id="chargeValue"
                                            name="chargeValue"
                                            value={totalChargeTime}
                                            disabled={
                                                !!selectedChargeType
                                                && selectedChargeType.type
                                                == ChargeTypeUnits.Hours
                                            }
                                            className="form-control"
                                            onChange={(ev) => this.onChargeValueChanged(ev)}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <FormLabel text="Notes" />
                                        <textarea
                                            id="chargeNotes"
                                            name="chargeNotes"
                                            className="form-control"
                                            value={chargeNotes}
                                            onChange={(ev) => this.onChargeNotesChanged(ev)}
                                            placeholder="Enter notes"
                                            type="text"
                                            maxLength="500"
                                            rows="5"
                                        />
                                    </FormGroup>
                                </ModalBody>
                                <ModalFooter>
                                    {isAddingCharge && (
                                        <FontAwesomeIcon
                                            icon={faCircleNotch}
                                            className="fa-spin mr-2"
                                            size="sm"
                                        />
                                    )}
                                    <Button
                                        color="primary"
                                        disabled={isAddingCharge}
                                        onClick={() => this.onAddCharge()}
                                    >
                    Add Charge
                                    </Button>
                                    {' '}
                                    <Button
                                        color="secondary"
                                        onClick={() => this.setState({
                                            showAddChargeModal: false,
                                        })}
                                    >
                    Cancel
                                    </Button>
                                </ModalFooter>
                            </Modal>

                            <Modal
                                isOpen={showProcessModal}
                                toggle={() => this.toggleProcessBillingChargeModal()}
                            >
                                <ModalHeader
                                    toggle={() => this.toggleProcessBillingChargeModal()}
                                >
                  Process Approved Charges
                                </ModalHeader>
                                <ModalBody>
                                    <FormGroup>
                                        <label>Dispatching</label>
                                        <Select
                                            placeholder="Select Dispatching"
                                            id="selectedDispatchLocation"
                                            name="selectedDispatchLocation"
                                            styles={CompactSelectStyles}
                                            isClearable={false}
                                            className="react-select"
                                            options={dispatchLocations}
                                            value={
                                                (dispatchLocations ?? []).find(
                                                    (x) => x.value
                                                        === selectedDispatchLocation,
                                                ) ?? ''
                                            }
                                            onChange={
                                                this
                                                    .onSelectedDispatchLocationChanged
                                            }
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <label>Week Of</label>
                                        <input
                                            id="weekOf"
                                            name="selectedWeekOf"
                                            className="form-control"
                                            defaultValue={selectedWeekOf ?? ''}
                                            onChange={this.onChange}
                                            type="date"
                                        />
                                    </FormGroup>
                                </ModalBody>
                                <ModalFooter>
                                    {isProcessing && (
                                        <FontAwesomeIcon
                                            icon={faCircleNotch}
                                            className="fa-spin mr-2"
                                            size="sm"
                                        />
                                    )}
                                    <Button
                                        color="primary"
                                        disabled={isProcessing}
                                        onClick={() => this.onProcessClick()}
                                    >
                    Ok
                                    </Button>
                                    {' '}
                                    <Button
                                        color="secondary"
                                        onClick={() => this.setState({
                                            showProcessModal: false,
                                        })}
                                    >
                    Cancel
                                    </Button>
                                </ModalFooter>
                            </Modal>
                        </PageWrap>
                    );
                }}
            </CommonContext.Consumer>
        );
    }
}

export default withRouter(BillingChargeIndex);
