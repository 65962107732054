import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToolbox } from '@fortawesome/free-solid-svg-icons';
import { Progress } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import {
    createDataSource,
    createGridOptions,
    DataGrid,
    indexCellRenderer,
    EditLinkCellRenderer,
    TextFilterDefaults,
    ConditionalEditLinkCellRenderer,
} from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { PageHeading, PageWrap } from '../common/forms/FormElements';
import { EquipmentForm } from './EquipmentForm';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import { util } from '../Util';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import { isComplianceAssociatedWithEquipmentType } from '../complianceType/ComplianceType';

class EquipmentIndex extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.eqForm = React.createRef();

        const params = new URLSearchParams(props.location.search);
        const openNew = params.get('new') === 'true';

        this.state = {
            loading: true,
            roles: [],
            showEquipmentForm: openNew,
            showComplianceForm: false,
        };

        this.onAddEquipment = this.onAddEquipment.bind(this);
        this.onEditEquipment = this.onEditEquipment.bind(this);
        this.onEquipmentFormClosed = this.onEquipmentFormClosed.bind(this);
        this.onEditCompliance = this.onEditCompliance.bind(this);
    }

    componentDidMount() { return this.populateState(); }

    componentWillUnmount() {
        return this.setState = (state, callback) => {

        };
    }

    onAddEquipment = () => {
        this.context.setFormOpened(true);
        this.eqForm.current.open();
    };

    onEditCompliance = (id) => this.props.history.push(
        `${AppNavPaths.EquipmentCompliances}?equipmentId=${id}`,
    );

    onEditEquipment = (id) => {
        this.context.setFormOpened(true);
        this.eqForm.current.open(id);
    };

    onEquipmentFormClosed = () => {
    // doesnt fire the form closed hanlder in datagridtoolbar for some reason
        this.context.setFormOpened(false);
        this.state.gridOptions.refresh();
    };

    async populateState() {
        const [types, statuses, dispatchLocations, allEquipmentComplianceTypes] = await Promise.all([
            util.fetch.js(ApiRoutes.typeAheads.equipmentTypes()),
            util.fetch.js(ApiRoutes.typeAheads.equipmentTypeStatuses()),
            util.fetch.js(ApiRoutes.typeAheads.dispatchLocations()),
            util.fetch.js(
                ApiRoutes.complianceTypes.allEquipmentComplianceTypes(),
            ),
        ]);

        const gridOptions = createGridOptions(this);

        gridOptions.components = {
            selectFilter: DataGridSelectFilter,
            selectFloatingFilter: DataGridSelectFloatingFilter,
            nameRenderer: EditLinkCellRenderer,
            complianceAvailableRenderer: ConditionalEditLinkCellRenderer,
        };

        const dispatchFilterParams = {
            suppressFilterButton: true,
            labelText: 'Filter by Dispatch',
            options: dispatchLocations,
            optionsLabel: 'label',
            optionsValue: 'value',
        };

        const anyComplianceAssociatedWithEquipmentType = (equipmentTypeId) => allEquipmentComplianceTypes.some((ec) => isComplianceAssociatedWithEquipmentType(ec, equipmentTypeId));

        const shouldWarn = (equipment) => {
            const { equipmentTypeId } = equipment;

            const allConfiguredEquipmentComplianceTypeIds = equipment.compliances.map((c) => c.complianceTypeId);

            const upToDateComplianceTypeIds = equipment.compliances
                .filter((c) => moment(new Date()).isBetween(
                    c.effectiveDate,
                    c.expirationDate || '2099-12-31',
                ))
                .map((c) => c.complianceTypeId);

            return allEquipmentComplianceTypes
                .filter((ec) => ec.warnWhenMissing)
                .some((ec) => ec.complianceEquipmentTypes.some(
                    (cet) => cet.equipmentTypeId == equipmentTypeId
                            && allConfiguredEquipmentComplianceTypeIds.includes(
                                cet.complianceTypeId,
                            )
                            && !upToDateComplianceTypeIds.includes(
                                cet.complianceTypeId,
                            ),
                ));
        };

        const hasAssociatedComplianceAndIsUpToDate = (equipmentParam) => {
            if (!equipmentParam || !equipmentParam.data) {
                return false;
            }

            const { equipmentTypeId } = equipmentParam.data;

            if (!anyComplianceAssociatedWithEquipmentType(equipmentTypeId)) {
                return {
                    show: false,
                    warn: false,
                };
            }

            return {
                show: true,
                warn: shouldWarn(equipmentParam.data),
            };
        };

        const complianceDisabled = !this.context.tenant?.tenantSettings?.complianceEnabled;

        gridOptions.columnDefs = [
            {
                flex: 0,
                maxWidth: 80,
                headerName: '',
                valueGetter: 'node.id',
                sortable: false,
                cellRenderer: indexCellRenderer,
            },
            {
                colId: 'Description',
                sortable: true,
                headerName: 'Description',
                field: 'description',
                sort: { direction: 'asc', priority: 0 },
                cellRenderer: 'nameRenderer',
                cellRendererParams: {
                    clicked: this.onEditEquipment,
                    nameField: 'description',
                    idField: 'id',
                    title: 'View this Equipment',
                    entity: 'equipment',
                },
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'Identifier',
                sortable: true,
                headerName: 'Number',
                field: 'number',
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'EquipmentTypeId',
                sortable: true,
                headerName: 'Type',
                field: 'type',
                filter: 'selectFilter',
                filterParams: {
                    suppressMenu: true,
                    floatingFilter: true,
                    labelText: 'Filter by type',
                    options: types,
                    optionsLabel: 'description',
                    optionsValue: 'id',
                },
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                    options: types,
                    optionsLabel: 'description',
                    optionsValue: 'id',
                },
            },
            {
                colId: 'EquipmentStatusId',
                sortable: true,
                headerName: 'Status',
                field: 'status',
                filter: 'selectFilter',
                filterParams: {
                    suppressMenu: true,
                    floatingFilter: true,
                    labelText: 'Filter by type',
                    options: statuses,
                    optionsLabel: 'label',
                    optionsValue: 'value',
                },
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                    options: statuses,
                    optionsLabel: 'label',
                    optionsValue: 'value',
                    initialFilterValue: 1,
                },
            },
            {
                colId: 'DispatchLocation.CompanyName',
                headerName: 'Dispatching',
                sortable: true,
                field: 'dispatchLocation',
                tooltipField: 'dispatchLocation',
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: dispatchFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: dispatchFilterParams,
            },
            {
                colId: 'AssignedTo',
                sortable: true,
                headerName: 'Assigned To',
                field: 'assignedTo',
                sort: { direction: 'asc', priority: 0 },
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'associatedCompliances',
                headerName: 'Associated Compliances',
                hide: complianceDisabled,
                field: 'equipmentTypeId',
                cellRenderer: 'complianceAvailableRenderer',
                valueGetter: hasAssociatedComplianceAndIsUpToDate,
                cellRendererParams: {
                    clicked: this.onEditCompliance,
                    idField: 'id',
                    title: 'Update this Equipment\'s Compliances',
                    entity: 'equipmentcompliance',
                    displayValues: [
                        { value: true, text: 'Manage' },
                        { value: false, text: '' },
                    ],
                },
            },
        ];

        const dataSource = createDataSource(
            ApiRoutes.equipment.search(),
            gridOptions,
        );

        this.setState({
            loading: false,
            gridOptions,
            dataSource,
        });
    }

    toggleComplianceForm = (show) => this.setState((state) => ((state.showComplianceForm = show), state));

    toggleEquipmentForm = (show) => this.setState((state) => ((state.showEquipmentForm = show), state));

    render() {
        const {
            loading, rowData, gridOptions, saving, loadingData,
        } = this.state;

        if (loading) {
            return <Progress />;
        }

        return (
            <PageWrap>
                <PageHeading>
                    <FontAwesomeIcon
                        icon={faToolbox}
                        className="mr-2 text-muted"
                    />
                    <span>Equipment</span>
                </PageHeading>
                <DataGridToolbar
                    entity="equipment"
                    gridApi={this.state.gridApi}
                    dataSource={this.state.dataSource}
                    onAdd={this.onAddEquipment}
                    addLabel="Add Equipment"
                    hideExcelButton
                    gridOptions={this.state.gridOptions}
                    serverExport={{
                        apiPath: ApiRoutes.equipment.excelExport(),
                        filePrefix: 'EquipmentSearch',
                    }}
                    serverExportDisabled={Boolean(
                        saving || loading || loadingData,
                    )}
                />
                <DataGrid
                    domLayout="normal"
                    rowData={rowData}
                    gridOptions={gridOptions}
                    onNameCellClicked={this.onEditEquipment}
                    gridStatus={this.state.gridStatus}
                />
                <EquipmentForm
                    show={
                        this.state
                            .showEquipmentForm /* controls form open state */
                    }
                    toggleShow={
                        this
                            .toggleEquipmentForm /* getter/setter for form open state */
                    }
                    ref={this.eqForm}
                    onClose={this.onEquipmentFormClosed}
                />
            </PageWrap>
        );
    }
}
export default withRouter(EquipmentIndex);
