import React from 'react';
import { Button, FormGroup, Input, Progress } from 'reactstrap';
import { Prompt, withRouter } from 'react-router-dom';
import { isEqual } from 'lodash-es';
import { toast } from 'react-toastify';
import {
    faSave,
    faFileInvoiceDollar,
    faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import {
    AppPageForm,
    FlexCenterRow,
    FormGroupColumn,
    FormLabel,
    GroupedRow,
    onFieldChange,
    onReactSelectChanged,
    SubHeading,
    ToastMessage,
    ValidationErrorMessage,
} from '../common/forms/FormElements';
import { util } from '../Util';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import { handleFormSaveError } from '../common/forms/ValidationError';
import AsyncSelect from 'react-select/async';

class USCountyGroup {
    id = null;
    description = '';
    groupType = null;
    counties = [];
}

class USCountyGroupForm extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.formRef = React.createRef();

        this.state = {
            originalData: new USCountyGroup(),
            countyGroup: new USCountyGroup(),
            groupTypes: [],
            counties: [],
            loading: true,
            saving: false,
        };

        this.handlers.change = this.handlers.change.bind(this);
        this.handlers.select = this.handlers.select.bind(this);
    }

    componentDidMount() { return this.populateState(); }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps
            && this.props.match.params.id !== (prevProps.match.params ?? {}).id
        ) {
            this.populateState();
        }
    }

    onClearErrors = () => this.setState((state) => ({ errors: {} }));

    onSubmit = (e) => {
        this.onClearErrors();
        this.setSaving(true);

        const cloned = util.object.clone(this.state.countyGroup);

        this.props.location.pathname == AppNavPaths.USCountyGroupNew
            ? this.create(cloned)
            : this.update(cloned);
    };

    setSaving = (b) => this.setState({ saving: b });

    create = async (countyGroup) => {
        countyGroup.id = 0;

        const response = await util.fetch
            .post(ApiRoutes.USCounties.group(), countyGroup)
            .catch(this.handleSaveError);

        if (response && !isNaN(response.data)) {
            toast.success(
                <ToastMessage
                    icon={faCheckCircle}
                    header="Save Successful"
                    message="Saved."
                />,
            );

            util.navigation.localRedirect(
                this,
                `${AppNavPaths.USCountyGroup}/${response.data}`,
            );
        }

        this.setSaving(false);
    };

    handleSaveError = (err) => handleFormSaveError(this, err);

    handlers = {
        change: onFieldChange,
        select: onReactSelectChanged,
    };

    async populateState() {
        const { id } = this.props.match.params;

        const [groupTypes, countyGroup] = await Promise.all([
            util.fetch.js(ApiRoutes.USCounties.groupTypes()),
            id
                ? util.fetch.js(ApiRoutes.USCounties.byGroupId(id))
                : new USCountyGroup(),
        ]);

        const originalData = util.object.clone(countyGroup);

        this.setState({
            originalData,
            groupTypes: groupTypes.map((x) => ({ label: x.description, value: x.id })),
            countyGroup: originalData,
            loading: false,
            saving: false,
        });
    }

    update = async (countyGroup) => {
        const response = await util.fetch
            .put(ApiRoutes.USCounties.byGroupId(countyGroup.id), countyGroup)
            .catch(this.handleSaveError);

        if (response && parseInt(response.id ?? 0) >= 0) {
            this.setState({
                saving: false,
                formValidated: false,
                originalData: countyGroup,
            });

            toast.success(
                <ToastMessage
                    icon={faCheckCircle}
                    header="Save Successful"
                    message="County Group saved."
                />,
            );
        }

        this.setSaving(false);
    };

    onCountyChange = (value) => {
        let { countyGroup } = { ...this.state };

        countyGroup.counties = value;

        this.setState({ countyGroup });
    };

    loadOptions = (searchString, callback) => {
        util.fetch.post(ApiRoutes.USCounties.all(), { searchString })
            .then((data) => callback(data));
    };

    renderForm() {
        const {
            groupTypes,
            countyGroup,
            originalData,
            saving,
            errors,
            loading,
            formValidated,
        } = this.state;

        if (!countyGroup) {
            return '';
        }

        const newCountyGroup = parseInt(countyGroup.id ?? 0) <= 0;

        return (
            <>
                <Prompt
                    when={!saving && !isEqual(originalData, countyGroup)}
                    message="You have unsaved changes, are you sure you want to leave?"
                />
                <AppPageForm
                    formShown={this.context.formIsOpen}
                    formId="countyGroupForm"
                    formHeadingIcon={faFileInvoiceDollar}
                    formHeading={
                        newCountyGroup
                            ? 'New County Group'
                            : 'Edit County Group'
                    }
                    formName="countyGroupForm"
                    formRef={this.formRef}
                    onSubmit={this.onSubmit}
                    setIsValidated={(value) => {
                        this.setState({ formValidated: value });
                    }}
                    isValidated={formValidated}
                    saving={saving}
                    errors={errors}
                    loading={loading}
                >
                    <SubHeading first>Details</SubHeading>
                    <GroupedRow>
                        <FormGroupColumn>
                            <FormGroup>
                                <FormLabel
                                    htmlFor="description"
                                    text="County Group Name"
                                    required
                                />
                                <Input
                                    id="description"
                                    name="countyGroup.description"
                                    value={countyGroup.description ?? ''}
                                    onChange={this.handlers.change}
                                    placeholder="Enter Name"
                                    type="text"
                                    required
                                />
                                <ValidationErrorMessage>
                                    Name is required.
                                </ValidationErrorMessage>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel
                                    htmlFor="usCountyGroupTypeId"
                                    text="Group Type"
                                    required
                                />
                                <ValidatedSelect
                                    id="usCountyGroupTypeId"
                                    name="countyGroup.usCountyGroupTypeId"
                                    required
                                    options={groupTypes}
                                    value={
                                        (groupTypes ?? []).find(
                                            (s) => s.value
                              === countyGroup.usCountyGroupTypeId,
                                        ) ?? ''
                                    }
                                    onChange={this.handlers.select.bind(this)}
                                    validationMessage="A Group Type selection is required."
                                />
                            </FormGroup>
                        </FormGroupColumn>

                        <FormGroupColumn>
                            <FormGroup>
                                <FormLabel
                                    htmlFor="counties"
                                    text="Counties"
                                    required
                                />
                                <ValidatedSelect
                                    id="counties"
                                    name="counties"
                                    component={AsyncSelect}
                                    isMulti
                                    required
                                    loadOptions={this.loadOptions}
                                    value={countyGroup.counties ?? ''}
                                    getOptionLabel={(option) => `${option.name} (${option.usState.name})`}
                                    getOptionValue={(option) => option.id}
                                    onChange={this.onCountyChange}
                                    validationMessage="At least one group selection is required."
                                />
                            </FormGroup>
                        </FormGroupColumn>
                    </GroupedRow>
          
                    <FlexCenterRow className="mb-3">
                        <Button
                            size="sm"
                            type="submit"
                            color="primary"
                            name="countyGroupForm"
                        >
                            <FontAwesomeIcon className="mr-2" icon={faSave} />
                            {newCountyGroup
                                ? 'Save New County Group'
                                : 'Save'}
                        </Button>
                    </FlexCenterRow>
                </AppPageForm>
            </>
        );
    }

    render() {
        const { loading } = this.state;

        if (loading) {
            return <Progress />;
        }

        return this.renderForm();
    }
}
export default withRouter(USCountyGroupForm);
