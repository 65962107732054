import { faTasks, faCalculator, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withRouter } from 'react-router-dom';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Progress,
} from 'reactstrap';
import Select from 'react-select';
import * as moment from 'moment';
import CommonContext, { ApiRoutes } from '../Common';
import {
    createDataSource,
    createGridOptions,
    DataGrid,
    IconCellRenderer,
    LinkCellRenderer,
    TextFilterDefaults,
    DateFilterDefaults,
    VariableLinkCellRenderer,
    FlagIconCellRenderer,
} from '../common/dataGrid/DataGrid';
import { CompactSelectStyles } from '../common/forms/ValidatedSelect';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import {
    FlexCenterRow,
    onFieldChange,
    PageHeading,
    PageWrap,
    toasty,
    SmallButton,
    FormLabel,
} from '../common/forms/FormElements';
import { util } from '../Util';
import TimesheetDetails from '../timesheet/TimesheetDetails';
import { NotAuthorizedPage } from '../status/StatusCodes';
import PayrollCalculationSlideout from '../payrollManagement/PayrollCalculationSlideout';
import { getTenantUserProfile } from '../common/TenantUserProfile';
import { PayrollFlags } from './Payroll';

class PayrollIndex extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.timesheetDetailsRef = React.createRef();
        this.payrollCalculationRef = React.createRef();

        this.state = {
            loading: true,
            rowData: [],
            rowsSelected: [],
            selectedPayrollCompany: null,
            selectedDispatchLocation: null,
            selectedMultiDispatchLocations: null,
            isRejectingPayrollTimesheet: false,
            showRejectPayrollTimesheetModal: false,
            selectedWeekOf: '',
            selectEmployee: 0,
            showExportModal: false,
            isExporting: false,
            isDownloading: false,
            isRecalculating: false,
            showDownloadModal: false,
            showRecalcModal: false,
            selectedExportId: null,
            selectedRowRejectionNotes: '',
        };

        this.onRowClicked = this.onRowClicked.bind(this);
        this.onRowSelected = this.onRowSelected.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() { return this.populateState(); }

    componentWillUnmount() {
        return this.setState = (state, callback) => {

        };
    }

    async onAcceptButtonClick() {
        const { rowsSelected } = this.state;

        // call accept
        if (rowsSelected) {
            const model = rowsSelected.map((x) => x.id);
            const response = await util.fetch.post(
                ApiRoutes.payroll.approve(),
                model,
            );

            if (response) {
                toasty.error(`Error approving payroll records: ${response}`);
            } else {
                toasty.success('Payroll records approved');
            }
        }

        this.state.gridOptions.refresh();

        this.setState({ rowsSelected: [] });
    }

    onChange = onFieldChange;

    onDownloadExportClick = async () => {
        const { selectedExportId } = this.state;
        this.setState({ isDownloading: true });
        if (selectedExportId) {
            /* var model = { id: id }; */
            await util.fetch
                .downloadFile(
                    ApiRoutes.payroll.download(selectedExportId),
                    null,
                    'Export',
                )
                .catch(this.handleSaveError);
        }
        this.setState({
            selectedExportId: null,
            isDownloading: false,
            showDownloadModal: false,
        });
    };

    onDownloadWeeklyClick = async () => {
        const { selectedExportId } = this.state;
        this.setState({ isDownloading: true });
        if (selectedExportId) {
            /* var model = { id: id }; */
            await util.fetch
                .downloadFile(
                    ApiRoutes.payroll.downloadWeekly(selectedExportId),
                    null,
                    'WeeklyEmployee.xlsx',
                )
                .catch(this.handleSaveError);
        }
        this.setState({
            selectedExportId: null,
            isDownloading: false,
            showDownloadModal: false,
        });
    };

    async onExportClick() {
        const {
            selectedWeekOf,
            selectedMultiDispatchLocations,
        } = this.state;

        if (!selectedWeekOf || !selectedMultiDispatchLocations) return;

        this.setState({ isExporting: true });

        const model = {
            weekOf: new Date(selectedWeekOf),
            dispatchLocationIds: selectedMultiDispatchLocations.map(
                (e) => e.id,
            ),
        };

        try {
            const response = await util.fetch.post(
                ApiRoutes.payroll.export(),
                model,
            );
            if (response) {
                toasty.error(response);
            } else {
                toasty.success('Export complete');
            }
        } catch {
            toasty.error('Error exporting payroll records');
        }

        this.state.gridOptions.refresh();

        this.setState({
            rowsSelected: [],
            isExporting: false,
            showExportModal: false,
        });
    }

    async onHourlyExportClick() {
        const { exporting } = this.state;

        if (!exporting) {
            const { selectedWeekOf } = this.state;

            if (!selectedWeekOf) return;

            this.setState({ isExporting: true });

            const model = {
                weekOf: new Date(selectedWeekOf),
            };

            try {
                const fileName = `HourlyReport_${moment().format(
                    'YYYY-M-D_hhmmss',
                )}.xlsx`;

                await util.fetch.downloadFile(
                    ApiRoutes.payroll.excelHoursExport(),
                    model,
                    fileName,
                );
            } catch (error) {
                toasty.error('There was a problem getting the hourly export.');
                console.error({ error });
            } finally {
                await this.setState({
                    isExporting: false,
                    showExportModal: false,
                });
            }
        }
    }

    onPayrollCalculationClosed = async () => {
        await this.context.setFormOpened(false);
        this.setState({ selectedRow: null });
        this.state.gridOptions.refresh();
    };

    async onRecalcClick() {
        const { selectedWeekOf, selectedDispatchLocation, selectedEmployee } = this.state;

        const model = {
            weekOf: new Date(selectedWeekOf),
            dispatchLocationId: selectedDispatchLocation,
            employeeId: selectedEmployee,
        };

        // Check to see if this employee has payroll edits
        // If so warn the user of overwrite
        try {
            const hasEdits = await util.fetch.post(
                ApiRoutes.payroll.doesPayrollHaveEdits(),
                model,
            );
            if (hasEdits) {
                const confirmed = window.confirm(
                    'Some of these charges have edits.  Continue and overwrite the edits?',
                );
                if (!confirmed) return;
            }

            const hasExports = await util.fetch.post(
                ApiRoutes.payroll.doesPayrollHaveExports(),
                model,
            );
            if (hasExports) {
                window.alert(
                    'Employees with exported charges cannot be recalculated.',
                );
                return;
            }
        } catch (error) {
            toasty.error('An error occurred while preparing to recalculate.');
            console.error({ error });
            return;
        }

        // Recalculate
        this.setState({ isRecalculating: true });

        try {
            await util.fetch.post(
                ApiRoutes.payrollManagement.recalculateWeek(),
                model,
            );
            this.setState({ isRecalculating: false, showRecalcModal: false });
            toasty.success('Employee\'s week recalculated successfully.');
        } catch (error) {
            toasty.error('An error occurred recalculating.');
            console.error({ error });
        } finally {
            this.setState({ isRecalculating: false });
        }
    }

    async onRejectButtonClick() {
        toasty.error('Not Implemented');
    }

    onRowClicked = (event) => {
        const selection = event.api.getSelectedRows();
        const row = selection.length ? selection[0] : null;

        if (row) {
            this.setState({ selectedRow: row });
        }
    };

    // https://stackoverflow.com/questions/44263350/count-number-of-selected-rows-in-ag-grid
    // Possibly use lodash dequeue?
    onRowSelected(e) {
        const rs = e.api.getSelectedRows();

        this.setState({
            rowsSelected: rs,
        });
    }

    onSelectedDispatchLocationChanged = async (selection) => {
        const loc = { ...selection };
        this.setState({ selectedDispatchLocation: loc.value });
    };

    onSelectedMultiDispatchLocationChanged = async (opts) => {
        this.setState({ selectedMultiDispatchLocations: opts });
    };

    onSelectedPayrollCompanyChanged = async (selection) => {
        const loc = { ...selection };
        this.setState({ selectedPayrollCompany: loc.value });

        const subArr = this.state.dispatchLocations.filter(
            (x) => x.parentCompanyId == loc.value,
        );
        this.setState({ selectedMultiDispatchLocations: subArr });
    };

    onSelectedPayrollDispatchLocationChanged = async (selection) => {
        const { selectedWeekOf } = this.state;

        const model = {
            weekOf: new Date(selectedWeekOf),
            dispatchLocationId: selection.id,
        };

        this.setState({
            selectedDispatchLocation: selection.value,
            selectedEmployee: 0,
        });

        let employees = [];
        try {
            const entities = await util.fetch.post(
                ApiRoutes.payroll.employees(),
                model,
            );
            employees = entities.map((e) => ({
                ...e,
                value: e.id,
                label: e.name,
            }));
        } catch (error) {
            console.log(error);
        }

        this.setState({ employees });
    };

    onSelectedPayrollEmployeeChanged = async (selection) => {
        this.setState({ selectedEmployee: selection.value });
    };

    onSelectedPayrollWeekChanged = async (selection) => {
        const { selectedDispatchLocation } = this.state;

        const model = {
            weekOf: selection.currentTarget.value,
            dispatchLocationId: selectedDispatchLocation,
        };

        this.setState({
            selectedWeekOf: selection.currentTarget.value,
            selectedEmployee: 0,
        });

        let employees = [];
        try {
            const entities = await util.fetch.post(
                ApiRoutes.payroll.employees(),
                model,
            );
            if (entities) {
                employees = entities.map((e) => ({
                    ...e,
                    value: e.id,
                    label: e.name,
                }));
            }
        } catch (error) {
            console.log(error);
        }

        this.setState({ employees });
    };

    onTimesheetDetailsClosed = async () => {
        await this.context.setFormOpened(false);
        this.setState({ selectedRow: null });
    };

    async onWeeklyExportClick() {
        const { exporting } = this.state;

        if (!exporting) {
            const {
                selectedWeekOf,
                selectedDispatchLocation,
                exportBillableOnly,
            } = this.state;

            if (!selectedWeekOf || !selectedDispatchLocation) return;

            this.setState({ isExporting: true });

            const dispatchLocationIds = [];
            dispatchLocationIds.push(selectedDispatchLocation);
            const model = {
                weekOf: new Date(selectedWeekOf),
                dispatchLocationIds,
                exportBillableOnly,
            };

            try {
                let fileName = `PayrollWeeklyExport_${moment().format(
                    'YYYY-M-D_hhmmss',
                )}.xlsx`;

                if (exportBillableOnly) {
                    fileName = `BillingWeeklyExport_${moment().format(
                        'YYYY-M-D_hhmmss',
                    )}.xlsx`;
                }

                await util.fetch.downloadFile(
                    ApiRoutes.payroll.excelWeeklyExport(),
                    model,
                    fileName,
                );
            } catch (error) {
                toasty.error('There was a problem getting the weekly export.');
                console.error({ error });
            } finally {
                await this.setState({
                    isExporting: false,
                    showExportModal: false,
                });
            }
        }
    }

    getColumnDefs(hasFullView, hasEdit, hasIndividual) {
        const dispatchFilterParams = {
            suppressFilterButton: true,
            labelText: 'Filter by Dispatch',
            options: this.state.dispatchLocations,
            optionsLabel: 'label',
            optionsValue: 'value',
        };
        const payrollStatusFilterParams = {
            suppressFilterButton: true,
            options: this.state.payrollStatuses,
            optionsLabel: 'label',
            optionsValue: 'value',
        };
        const billingStatusFilterParams = {
            suppressFilterButton: true,
            options: this.state.billingStatuses,
            optionsLabel: 'label',
            optionsValue: 'value',
        };
        const flagsFilterParams = {
            suppressFilterButton: true,
            options: this.state.flags,
            optionsLabel: 'label',
            optionsValue: 'value',
        };
        const yesNoFilterParams = {
            suppressFilterButton: true,
            options: this.state.yesNo,
            optionsLabel: 'label',
            optionsValue: 'value',
        };

        const defs = [
            {
                headerName: '',
                valueGetter: 'node.id',
                sortable: false,
                hide: true,
                flex: 1,
                maxWidth: 35,
                minWidth: 35,
                cellRenderer: this.indexCellRenderer,
            },
            {
                colId: 'Payroll.TimesheetDetail.Timesheet.JobDate',
                headerName: 'Date',
                field: 'date',
                sortable: true,
                minWidth: 120,
                sort: { direction: 'asc', priority: 0 },
                filter: 'agDateColumnFilter',
                filterParams: DateFilterDefaults,
            },
            {
                colId: 'Start',
                headerName: 'Start',
                field: 'start',
                sortable: false,
                maxWidth: 140,
                minWidth: 140,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'End',
                headerName: 'End',
                field: 'end',
                sortable: false,
                maxWidth: 140,
                minWidth: 140,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'Payroll.Employee.FullName',
                headerName: 'Emp. Name',
                field: 'employeeName',
                sortable: true,
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
                cellStyle: (q) => {
                    if ((q.data ?? {}).onBoarding) {
                        if (q.data.onBoarding) {
                            return { color: 'red', fontWeight: 'bold' };
                        }
                    }

                    return {};
                },
            },
        ];

        if (!hasIndividual) {
            defs.push({
                colId: 'Payroll.DispatchLocationName',
                headerName: 'Dispatching',
                sortable: true,
                flex: 1.5,
                field: 'dispatchLocation',
                tooltipField: 'dispatchOfficeName',
                filter: 'selectFilter',
                filterParams: dispatchFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: dispatchFilterParams,
            });
        }

        defs.push(
            ...[
                {
                    colId: 'Payroll.CustomerCompanyName',
                    headerName: 'Customer Name',
                    field: 'customerName',
                    sortable: true,
                    flex: 1.5,
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                    valueGetter: (row) => {
                        if (row.data) {
                            const name = `${row.data.customerName}${row.data.subcontractorName
                                ? ` - ${row.data.subcontractorName}`
                                : ''
                            }`;
                            return name;
                        }

                        return '';
                    },
                },
                {
                    colId: 'Payroll.ChargeTypeName',
                    headerName: 'Charge Type',
                    field: 'chargeType',
                    sortable: true,
                    flex: 1,
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                },
                {
                    colId: 'Payroll.TimesheetDetail.Timesheet.TimesheetNumber',
                    headerName: 'Timesheet #',
                    field: 'timesheetNumber',
                    sortable: true,
                    flex: 1,
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                },
            ],
        );

        if (!hasIndividual) {
            defs.push(
                ...[
                    {
                        colId: 'Payroll.TimesheetDetail.ChargeTypeIsBillable',
                        headerName: 'Billable',
                        field: 'billable',
                        sortable: false,
                        maxWidth: 140,
                        minWidth: 140,
                        filter: 'selectFilter',
                        floatingFilter: true,
                        filterParams: yesNoFilterParams,
                        floatingFilterComponent: 'selectFloatingFilter',
                        floatingFilterComponentParams: yesNoFilterParams,
                    },
                    {
                        colId: 'Charge',
                        headerName: 'Charge',
                        field: 'charge',
                        type: 'rightAligned',
                        sortable: false,
                        maxWidth: 120,
                        minWidth: 120,
                        cellStyle: (q) => {
                            if ((q.data ?? {}).isBreak) {
                                if (q.data.isPaidBreak) {
                                    return { color: 'blue' };
                                }
                                return { color: 'red' };
                            }

                            return {}; // dont return '' will break grid
                        },
                        floatingFilterComponentParams: {
                            suppressFilterButton: true,
                        },
                    },
                ],
            );
        }

        defs.push(
            ...[
                {
                    colId: 'Pay',
                    headerName: 'Pay',
                    field: 'pay',
                    sortable: false,
                    maxWidth: 100,
                    minWidth: 100,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                },
                {
                    colId: 'REG',
                    headerName: 'REG',
                    field: 'reg',
                    sortable: false,
                    maxWidth: 100,
                    minWidth: 100,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                },
                {
                    colId: 'RSD',
                    headerName: 'RSD',
                    field: 'rsd',
                    sortable: false,
                    maxWidth: 100,
                    minWidth: 100,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                },
                {
                    colId: 'OT',
                    headerName: 'OT',
                    field: 'ot',
                    sortable: false,
                    maxWidth: 100,
                    minWidth: 100,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                },
                {
                    colId: 'OTSD',
                    headerName: 'OTSD',
                    field: 'otsd',
                    sortable: false,
                    maxWidth: 100,
                    minWidth: 100,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                },
                {
                    colId: 'T15',
                    headerName: 'T15',
                    field: 't15',
                    sortable: false,
                    maxWidth: 100,
                    minWidth: 100,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                },
                {
                    colId: 'T15SD',
                    headerName: 'T15SD',
                    field: 't15SD',
                    sortable: false,
                    maxWidth: 100,
                    minWidth: 100,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                },
                {
                    colId: 'PR',
                    headerName: 'PR',
                    field: 'pr',
                    sortable: false,
                    maxWidth: 100,
                    minWidth: 100,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                },
                {
                    colId: 'PRSD',
                    headerName: 'PRSD',
                    field: 'prsd',
                    sortable: false,
                    maxWidth: 100,
                    minWidth: 100,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                },
                {
                    colId: 'PW',
                    headerName: 'PW',
                    field: 'pw',
                    sortable: false,
                    maxWidth: 100,
                    minWidth: 100,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                },
                {
                    colId: 'PWOT',
                    headerName: 'PWOT',
                    field: 'pwot',
                    sortable: false,
                    maxWidth: 100,
                    minWidth: 100,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                },
                {
                    colId: 'PWT15',
                    headerName: 'PWT15',
                    field: 'pwT15',
                    sortable: false,
                    maxWidth: 100,
                    minWidth: 100,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                },
            ],
        );

        if (hasEdit) {
            // Users without full access can only access Exported records (although they can only see Print view records.)
            defs.push(
                ...[
                    {
                        colId: 'Payroll.PayrollStatus.Description',
                        headerName: 'Status',
                        field: 'status',
                        sortable: true,
                        maxWidth: 140,
                        minWidth: 140,
                        cellRenderer: 'variableLinkRenderer',
                        cellRendererParams: (r) => {
                            if ((r.data ?? {}).payrollExportId) {
                                return {
                                    clicked: this.toggleDownloadModal,
                                    isLink: true,
                                    nameField: 'status',
                                    idField: 'payrollExportId',
                                    title: 'Download Export',
                                };
                            }
                            return {
                                clicked: this.toggleDownloadModal,
                                isLink: false,
                                nameField: 'status',
                                idField: 'payrollExportId',
                                title: 'Download Export',
                            };
                        },
                        filter: 'selectFilter',
                        floatingFilter: true,
                        filterParams: payrollStatusFilterParams,
                        floatingFilterComponent: 'selectFloatingFilter',
                        floatingFilterComponentParams:
                            payrollStatusFilterParams,
                    },
                    {
                        colId: 'Payroll.PayrollExportId',
                        headerName: 'Export Id',
                        field: 'payrollExportId',
                        sortable: true,
                        maxWidth: 100,
                        minWidth: 100,
                        filter: 'agTextColumnFilter',
                        filterParams: TextFilterDefaults,
                        floatingFilterComponentParams: {
                            suppressFilterButton: true,
                        },
                    },
                ],
            );
        }

        if (hasEdit) {
            defs.push({
                colId: 'Payroll.TimesheetDetail.Timesheet.BillingStatus.Description',
                headerName: 'Billing Status',
                field: 'billingStatus',
                sortable: true,
                flex: 1.5,
                tooltipField: 'status',
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: billingStatusFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: billingStatusFilterParams,
            });
        }

        defs.push({
            colId: 'Flag',
            headerName: 'Flag',
            field: 'flag',
            flex: 1,
            minWidth: 65,
            sortable: false,
            tooltipField: 'flag',
            tooltipComponentParams: { color: '#ececec' },
            cellRenderer: 'flagIconRenderer',
            cellRendererParams: {
                bodyField: 'flag',
            },
            filter: 'selectFilter',
            floatingFilter: true,
            filterParams: flagsFilterParams,
            floatingFilterComponent: 'selectFloatingFilter',
            floatingFilterComponentParams: flagsFilterParams,
        });

        if (hasEdit) {
            defs.push({
                colId: 'Id',
                flex: 0,
                minWidth: 75,
                maxWidth: 75,
                headerName: 'View',
                sortable: false,
                cellRenderer: 'iconRenderer',
                cellRendererParams: {
                    clicked: this.openTimesheetDetails,
                    idField: 'timesheetId',
                    iconClass: 'fa-eye',
                },
            });
        }

        defs.push({
            colId: 'Id',
            minWidth: 75,
            maxWidth: 75,
            headerName: 'Print',
            sortable: false,
            cellRenderer: 'iconRenderer',
            cellRendererParams: {
                clicked: (id) => this.printTimesheet(id, hasFullView),
                idField: 'timesheetId',
                iconClass: 'fa-print',
            },
        });

        if (hasEdit) {
            defs.push({
                colId: 'Id',
                minWidth: 75,
                maxWidth: 75,
                headerName: '',
                sortable: false,
                cellRenderer: 'iconRenderer',
                cellRendererParams: {
                    clicked: this.openPayrollCalculation,
                    idField: 'id',
                    iconClass: 'fa-edit',
                },
            });
        }

        if (hasEdit) {
            defs.push({
                colId: 'SelectionPlaceholder',
                minWidth: 75,
                headerName: '',
                sortable: false,
                checkboxSelection(params) {
                    if (params.data) {
                        return params.data.status !== 'Exported';
                    }

                    return null;
                },
            });
        }

        return defs;
    }

    getDispatchOption = async (item) => {
        const ret = { value: item.id, label: item.companyName.trim() };

        return ret;
    };

    getExcelAPIPath(hasFull) {
        const url = hasFull
            ? ApiRoutes.payroll.excelExport()
            : ApiRoutes.payroll.excelExportIndividual();
        return url;
    }

    openPayrollCalculation = async (id) => {
        if (id) {
            await this.context.setFormOpened(true);
            this.payrollCalculationRef.current.open(id);
        }
    };

    openTimesheetDetails = async (id) => {
        if (id) {
            await this.context.setFormOpened(true);
            this.timesheetDetailsRef.current.open(id);
        }
    };

    populateState = async () => {
        const payrollStatusesPromise = util.fetch.js(
            ApiRoutes.typeAheads.payrollStatuses(),
        );
        const billingStatusesPromise = util.fetch.js(
            ApiRoutes.typeAheads.billingStatuses(),
        );
        const payrollCompaniesPromise = util.fetch.js(
            ApiRoutes.typeAheads.payrollCompanies(),
        );
        const payrollFlagsPromise = util.fetch.js(
            ApiRoutes.typeAheads.payrollCategoryFlags(),
        );
        const userProfilePromise = getTenantUserProfile();

        const [
            payrollStatuses,
            billingStatuses,
            payrollCompanies,
            userProfile,
            flags,
        ] = await Promise.all([
            payrollStatusesPromise,
            billingStatusesPromise,
            payrollCompaniesPromise,
            userProfilePromise,
            payrollFlagsPromise,
        ]);

        const yesNo = [
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
        ];

        // 2022-10-10 - M. Nicol
        // If the user has Payroll View, (s)he can see all data on the page.
        // See permission setup in App.js.
        // Edit allows use of red Export button.
        // If the user is neither of the below, (s)he must have payroll.viewindividual.
        const canViewBillingReport = !!userProfile.userPermissions.includes('billingreport.view');
        let hasFullView = !!userProfile.userPermissions.includes('payroll.view');
        let hasIndividual = !!userProfile.userPermissions.includes(
            'payroll.viewindividual',
        );
        let hasEdit = !!userProfile.userPermissions.includes('payroll.edit');

        if (this.context?.user?.isAdmin) {
            hasIndividual = false;
            hasEdit = true;
            hasFullView = true;
        }

        let dispatchLocations = await util.fetch.js(
            ApiRoutes.company.dispatchCompanies(),
        );
        dispatchLocations = dispatchLocations.map((e) => ({
            ...e,
            value: e.id,
            label: e.companyName,
        }));

        const self = this;
        const employees = [];
        await this.setState({
            employees,
            dispatchLocations,
            payrollStatuses,
            billingStatuses,
            flags,
            yesNo,
            payrollCompanies,
            hasFullView,
            hasEdit,
            hasIndividual,
        }, function () {
            const gridOptions = createGridOptions(self);

            gridOptions.components = {
                selectFilter: DataGridSelectFilter,
                selectFloatingFilter: DataGridSelectFloatingFilter,
                nameRenderer: LinkCellRenderer,
                iconRenderer: IconCellRenderer,
                flagIconRenderer: FlagIconCellRenderer,
                variableLinkRenderer: VariableLinkCellRenderer,
            };

            gridOptions.rowClassRules = {
                'ag-row-danger': (params) => (params.data ?? {}).isEmergency === true,
                'ag-row-success': (params) => {
                    const matches = ((params.data ?? {}).flag ?? []).filter((f) => (
                        f === PayrollFlags.ChargeTypeThresholdExceeded
                            || f === PayrollFlags.BreakThresholdExceeded
                    ));

                    return matches.length > 0;
                },
            };

            // https://www.ag-grid.com/documentation/javascript/row-selection/#checkbox-selection
            // One column must have "checkboxSelection" set to true.
            // Note: headerCheckboxSelection not available with infinite scroll.
            gridOptions.rowSelection = 'multiple';

            gridOptions.postProcessData = self.transformRowData;
            gridOptions.onRowSelected = self.onRowSelected;
            gridOptions.columnDefs = self.getColumnDefs(
                hasFullView,
                hasEdit,
                hasIndividual,
            );

            // On the grid's first load, we want to apply the filters immediately from the state population,
            // before the API call is made.
            gridOptions.onGridReady = (params) => {
                // Calc past 2 weeks
                const start = moment(moment().subtract(14, 'days')).format(
                    'YYYY-MM-DD',
                );
                const end = moment(moment().subtract(1, 'days')).format(
                    'YYYY-MM-DD',
                );

                const filterInstance = gridOptions.api.getFilterInstance(
                    'Payroll.TimesheetDetail.Timesheet.JobDate',
                );
                filterInstance.setModel({
                    type: 'inRange',
                    dateFrom: start,
                    dateTo: end,
                });

                gridOptions.api.onFilterChanged();

                params.api.setDatasource(dataSource);
                self.setState({ gridApi: params.api });
            };

            // 2022-09-28 - M. Nicol - If the user does not have full access, (s)he can only see his/her own data.
            const searchUrl = hasIndividual
                ? ApiRoutes.payroll.searchindividual()
                : !hasEdit && hasFullView
                    ? ApiRoutes.payroll.searchfullview()
                    : ApiRoutes.payroll.search();

            const dataSource = createDataSource(searchUrl, gridOptions);

            this.setState({
                loading: false,
                gridOptions,
                dataSource,
                canViewBillingReport,
                // selectedMultiDispatchLocations: dispatchLocations
            });
        });
    };

    printTimesheet = async (id, hasFull) => {
        if (id) {
            const url = hasFull
                ? ApiRoutes.report.timesheetPayroll(id)
                : ApiRoutes.report.timesheetPayrollIndividual(id);

            window.open(url, '_self');
        }
    };

    rejectPayrollTimesheet = async () => {
        const { rowsSelected, selectedRowRejectionNotes } = this.state;

        if (
            selectedRowRejectionNotes.length === 0
            || !selectedRowRejectionNotes.trim()
        ) return;

        this.setState({ isRejectingPayrollTimesheet: true });

        // call reject
        if (rowsSelected) {
            const model = {
                payrollId: rowsSelected[0].id,
                note: selectedRowRejectionNotes,
            };

            const endPoint = ApiRoutes.payroll.payrollReject();
            const response = await util.fetch
                .post(endPoint, model)
                .catch(this.handleSaveError);

            if (response) {
                toasty.success('Charges rejected');
            }
        }

        this.state.gridOptions.refresh();

        this.setState({
            selectedRow: null,
            rowsSelected: [],
            showRejectPayrollTimesheetModal: false,
            selectedRowRejectionNotes: '',
            isRejectingPayrollTimesheet: false,
        });
    };

    toggleDownloadModal = async (id) => {
        const { showDownloadModal } = this.state;
        this.setState({
            selectedExportId: id,
            showDownloadModal: !showDownloadModal,
        });
    };

    async toggleExportModal(weekly, billableOnly, isHourly) {
        const { showExportModal } = this.state;
        this.setState({
            showExportModal: !showExportModal,
            selectedDispatchLocation: null,
            selectedMultiDispatchLocations: null,
            selectedPayrollCompany: null,
            selectedWeekOf: '',
            isWeekly: !!weekly,
            isHourly: !!isHourly,
            exportBillableOnly: !!billableOnly,
        });
    }

    toggleRecalcModal = async () => {
        const { showRecalcModal } = this.state;
        this.setState({
            showRecalcModal: !showRecalcModal,
            selectedEmployee: 0,
        });
    };

    toggleRejectPayrollTimesheetModal = async () => {
        const { showRejectPayrollTimesheetModal } = this.state;

        this.setState({
            showRejectPayrollTimesheetModal: !showRejectPayrollTimesheetModal,
        });
    };

    transformRowData(data) {
        data.rows.forEach((row) => {
            // Combine these columns to save space.
            row.quantityDisplay = `${row.quantity} ${row.unitsName}`;

            // Placeholder column so we can have it be the last checkable thing on the right.
            // Otherwise, we could set checkboxSelection = true for an existing column.
            row.selectionPlaceholder = '';
        });
        return { ...data };
    }

    render() {
        const {
            rowData,
            gridOptions,
            employees,
            dispatchLocations,
            payrollCompanies,
            selectedPayrollCompany,
            selectedDispatchLocation,
            selectedMultiDispatchLocations,
            selectedWeekOf,
            selectedEmployee,
            isExporting,
            showExportModal,
            showDownloadModal,
            showRecalcModal,
            isDownloading,
            isRecalculating,
            isWeekly,
            isHourly,
            exportBillableOnly,
            hasEdit,
            hasIndividual,
            rowsSelected,
            isRejectingPayrollTimesheet,
            showRejectPayrollTimesheetModal,
            selectedRowRejectionNotes,
            canViewBillingReport,
        } = this.state;

        return (
            <CommonContext.Consumer>
                {(value) => {
                    const { tenantSettings } = (value ?? {}).tenant ?? {};

                    if (this.state.loading || !tenantSettings) {
                        return (
                            <Progress />
                        );
                    }

                    if (!tenantSettings.payrollEnabled) return <NotAuthorizedPage />;

                    return (
                        <PageWrap>
                            <PageHeading>
                                <FontAwesomeIcon
                                    icon={faTasks}
                                    className="mr-2 text-muted"
                                />
                                <span>Payroll: Pay Categories</span>

                                {hasEdit && (
                                    <>
                                        <span
                                            style={{
                                                float: 'right',
                                                position: 'relative',
                                                top: '-5px',
                                            }}
                                        >
                                            <Button
                                                size="sm"
                                                style={{ marginRight: '10px' }}
                                                color="danger"
                                                onClick={() => this.toggleExportModal()}
                                            >
                      Export
                                            </Button>
                                        </span>

                                        <span
                                            style={{
                                                float: 'right',
                                                position: 'relative',
                                                top: '-5px',
                                            }}
                                        >
                                            <Button
                                                size="sm"
                                                color="danger"
                                                style={{ marginRight: '10px' }}
                                                onClick={() => this.toggleRejectPayrollTimesheetModal()}
                                                disabled={
                                                    rowsSelected.length !== 1
                                                    || isRejectingPayrollTimesheet
                                                }
                                            >
                      Reject To Payroll Charges
                                            </Button>
                                        </span>

                                        <span
                                            style={{
                                                float: 'right',
                                                position: 'relative',
                                                top: '-5px',
                                            }}
                                        >
                                            <Button
                                                size="sm"
                                                style={{ marginRight: '10px' }}
                                                color="success"
                                                onClick={() => this.toggleRecalcModal()}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faCalculator}
                                                />
                                                &nbsp;Recalculate Week
                                            </Button>
                                        </span>
                                    </>
                                )}
                            </PageHeading>

                            <DataGridToolbar
                                entity="Payroll"
                                gridApi={this.state.gridApi}
                                dataSource={this.state.dataSource}
                                hideAdd
                                hideExcelButton
                                gridOptions={this.state.gridOptions}
                                serverExport={{
                                    apiPath: this.getExcelAPIPath(hasEdit),
                                    filePrefix: 'PayrollCategoriesSearch',
                                }}
                                children={(
                                    <>
                                        {!hasIndividual && (
                                            <SmallButton
                                                disabled={
                                                    !!isExporting && !isWeekly
                                                }
                                                onClick={() => this.toggleExportModal(
                                                    false,
                                                    false,
                                                    true,
                                                )}
                                            >
                                                <i className="fa fa-file-excel fa-lg" />
                                                <span className="ml-2 small-viewport-hide">
                                                    {isExporting && isWeekly
                                                        ? 'Exporting...'
                                                        : 'Hours Report'}
                                                </span>
                                            </SmallButton>
                                        )}
                                        {!hasIndividual && (
                                            <SmallButton
                                                disabled={
                                                    !!isExporting
                                                    && !exportBillableOnly
                                                }
                                                onClick={() => this.toggleExportModal(
                                                    true,
                                                    false,
                                                    false,
                                                )}
                                            >
                                                <i className="fa fa-file-excel fa-lg" />
                                                <span className="ml-2 small-viewport-hide">
                                                    {isExporting
                                                        && !exportBillableOnly
                                                        ? 'Exporting...'
                                                        : 'Export Weekly - Payroll'}
                                                </span>
                                            </SmallButton>
                                        )}
                                        {canViewBillingReport && (
                                            <SmallButton
                                                disabled={
                                                    !!isExporting
                                                    && !!exportBillableOnly
                                                }
                                                onClick={() => this.toggleExportModal(
                                                    true,
                                                    true,
                                                    false,
                                                )}
                                            >
                                                <i className="fa fa-file-excel fa-lg" />
                                                <span className="ml-2 small-viewport-hide">
                                                    {isExporting
                                                        && exportBillableOnly
                                                        ? 'Exporting...'
                                                        : 'Export Weekly - Billing'}
                                                </span>
                                            </SmallButton>
                                        )}
                                    </>
                                )}
                            />

                            <DataGrid
                                domLayout="normal"
                                rowData={rowData}
                                gridOptions={gridOptions}
                                gridStatus={this.state.gridStatus}
                            />
                            <TimesheetDetails
                                ref={this.timesheetDetailsRef}
                                show={this.state.timesheetDetailsOpen}
                                toggleShow={(open) => this.setState({
                                    timesheetDetailsOpen: open,
                                })}
                                onClose={this.onTimesheetDetailsClosed}
                                payroll
                                isPayroll
                                isBilling={false}
                                isTimesheet // janky way to show just reaodnly part of the status of the emergency
                                isTimesheetForm
                            />
                            <PayrollCalculationSlideout
                                ref={this.payrollCalculationRef}
                                show={this.state.payrollCalculationOpen}
                                toggleShow={(open) => this.setState({
                                    payrollCalculationOpen: open,
                                })}
                                onClose={this.onPayrollCalculationClosed}
                            />

                            <Modal
                                isOpen={showExportModal}
                                toggle={() => this.toggleExportModal()}
                            >
                                <ModalHeader
                                    toggle={() => this.toggleExportModal()}
                                >
                                    {!isWeekly
                                        && !exportBillableOnly
                                        && !isHourly && (
                                        <span>Export Payroll</span>
                                    )}
                                    {!!isHourly && <span>Hours Report</span>}
                                    {!!isWeekly && !exportBillableOnly && (
                                        <span>Export Payroll - All Time</span>
                                    )}
                                    {!!isWeekly && !!exportBillableOnly && (
                                        <span>Export Payroll - Billing</span>
                                    )}
                                </ModalHeader>
                                <ModalBody>
                                    {!isWeekly && !isHourly && (
                                        <FormGroup>
                                            <label>Operating Company</label>
                                            <Select
                                                placeholder="Select Operating Company"
                                                id="payrollCompanies"
                                                name="payrollCompanies"
                                                styles={CompactSelectStyles}
                                                isClearable={false}
                                                className="react-select"
                                                options={payrollCompanies}
                                                value={
                                                    (
                                                        payrollCompanies ?? []
                                                    ).find(
                                                        (x) => x.value
                                                            === selectedPayrollCompany,
                                                    ) ?? ''
                                                }
                                                onChange={
                                                    this
                                                        .onSelectedPayrollCompanyChanged
                                                }
                                            />
                                        </FormGroup>
                                    )}
                                    {!isWeekly && !isHourly && (
                                        <FormGroup style={{ marginBottom: 0 }}>
                                            <label>Dispatching</label>
                                            <Select
                                                placeholder="Select Dispatching"
                                                id="selectedDispatchLocation"
                                                name="selectedDispatchLocation"
                                                styles={CompactSelectStyles}
                                                isClearable
                                                isMulti
                                                className="react-select"
                                                options={dispatchLocations}
                                                value={
                                                    selectedMultiDispatchLocations
                                                }
                                                onChange={
                                                    this
                                                        .onSelectedMultiDispatchLocationChanged
                                                }
                                            />
                                            <div>
                                                <small
                                                    className="float-right site-link"
                                                    onClick={() => this.onSelectedMultiDispatchLocationChanged(
                                                        this.state
                                                            .dispatchLocations,
                                                    )}
                                                >
                        Select All
                                                </small>
                                            </div>
                                        </FormGroup>
                                    )}
                                    {!!isWeekly && (
                                        <FormGroup>
                                            <label>Dispatching</label>
                                            <Select
                                                placeholder="Select Dispatching"
                                                id="selectedDispatchLocation"
                                                name="selectedDispatchLocation"
                                                styles={CompactSelectStyles}
                                                isClearable={false}
                                                className="react-select"
                                                options={dispatchLocations}
                                                value={
                                                    (
                                                        dispatchLocations ?? []
                                                    ).find(
                                                        (x) => x.value
                                                            === selectedDispatchLocation,
                                                    ) ?? ''
                                                }
                                                onChange={
                                                    this
                                                        .onSelectedDispatchLocationChanged
                                                }
                                            />
                                        </FormGroup>
                                    )}
                                    <FormGroup>
                                        <label>Week Of</label>
                                        <input
                                            id="weekOf"
                                            name="selectedWeekOf"
                                            className="form-control"
                                            defaultValue={selectedWeekOf ?? ''}
                                            onChange={this.onChange}
                                            type="date"
                                        />
                                    </FormGroup>
                                </ModalBody>
                                <ModalFooter>
                                    {isExporting && (
                                        <FontAwesomeIcon
                                            icon={faCircleNotch}
                                            className="fa-spin mr-2"
                                            size="sm"
                                        />
                                    )}
                                    <Button
                                        color="primary"
                                        disabled={isExporting}
                                        onClick={() => (isWeekly
                                            ? this.onWeeklyExportClick()
                                            : isHourly
                                                ? this.onHourlyExportClick()
                                                : this.onExportClick())}
                                    >
                    Ok
                                    </Button>
                                    {' '}
                                    <Button
                                        color="secondary"
                                        onClick={() => this.setState({
                                            showExportModal: false,
                                        })}
                                    >
                    Cancel
                                    </Button>
                                </ModalFooter>
                            </Modal>

                            <Modal
                                isOpen={showDownloadModal}
                                toggle={() => this.toggleDownloadModal()}
                            >
                                <ModalHeader
                                    toggle={() => this.toggleDownloadModal()}
                                >
                  Download Payroll
                                </ModalHeader>
                                <ModalBody>
                                    <FlexCenterRow>
                                        <Button
                                            color="success"
                                            style={{ marginRight: '10px' }}
                                            disabled={isDownloading}
                                            onClick={() => this.onDownloadExportClick()}
                                        >
                      Export
                                        </Button>
                                        {' '}
                                        {/* <Button color="warning" disabled={isDownloading} onClick={() => this.onDownloadWeeklyClick()}>Weekly Employee</Button>{' '} */}
                                    </FlexCenterRow>
                                </ModalBody>
                                <ModalFooter>
                                    {isDownloading && (
                                        <FontAwesomeIcon
                                            icon={faCircleNotch}
                                            className="fa-spin mr-2"
                                            size="sm"
                                        />
                                    )}
                                    <Button
                                        color="secondary"
                                        onClick={() => this.setState({
                                            showDownloadModal: false,
                                        })}
                                    >
                    Cancel
                                    </Button>
                                </ModalFooter>
                            </Modal>

                            <Modal
                                isOpen={showRejectPayrollTimesheetModal}
                                toggle={this.toggleRejectPayrollTimesheetModal}
                            >
                                <ModalHeader
                                    toggle={
                                        this.toggleRejectPayrollTimesheetModal
                                    }
                                >
                  Reject Payroll Charges
                                </ModalHeader>
                                <ModalBody>
                                    <div
                                        style={{ marginBottom: '20px' }}
                                        className="form-text text-danger"
                                    >
                    This action will reset all associated
                    approved charges back to pending.
                                    </div>

                                    <FormGroup>
                                        <FormLabel
                                            required
                                            text="Notes"
                                        />
                                        <textarea
                                            id="selectedRowRejectionNotes"
                                            name="selectedRowRejectionNotes"
                                            className="form-control"
                                            defaultValue={
                                                selectedRowRejectionNotes ?? ''
                                            }
                                            onChange={this.onChange}
                                            required
                                            placeholder="Enter notes regarding the rejection."
                                            type="text"
                                            maxLength="500"
                                            rows="5"
                                        />
                                        <small className="text-danger">
                      Notes are required.
                                        </small>
                                    </FormGroup>
                                </ModalBody>
                                <ModalFooter>
                                    {isRejectingPayrollTimesheet && (
                                        <FontAwesomeIcon
                                            icon={faCircleNotch}
                                            className="fa-spin mr-2"
                                            size="sm"
                                        />
                                    )}
                                    <Button
                                        color="primary"
                                        disabled={isRejectingPayrollTimesheet}
                                        onClick={this.rejectPayrollTimesheet}
                                    >
                    Ok
                                    </Button>
                                    {' '}
                                </ModalFooter>
                            </Modal>

                            <Modal
                                isOpen={showRecalcModal}
                                toggle={() => this.toggleRecalcModal()}
                                backdrop="static"
                                keyboard={false}
                            >
                                <ModalHeader>Recalculate Week</ModalHeader>
                                <ModalBody>
                                    <FormGroup>
                                        <label>Week Of</label>
                                        <input
                                            id="weekOf"
                                            name="selectedWeekOf"
                                            className="form-control"
                                            defaultValue={selectedWeekOf ?? ''}
                                            onChange={
                                                this
                                                    .onSelectedPayrollWeekChanged
                                            }
                                            type="date"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <label>Dispatching</label>
                                        <Select
                                            placeholder="Select Dispatching"
                                            id="selectedDispatchLocation"
                                            name="selectedDispatchLocation"
                                            styles={CompactSelectStyles}
                                            isClearable={false}
                                            className="react-select"
                                            options={dispatchLocations}
                                            value={
                                                (dispatchLocations ?? []).find(
                                                    (x) => x.value
                                                        === selectedDispatchLocation,
                                                ) ?? ''
                                            }
                                            onChange={
                                                this
                                                    .onSelectedPayrollDispatchLocationChanged
                                            }
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <label>Employee</label>
                                        <Select
                                            placeholder="Select Employee"
                                            id="selectedEmployee"
                                            name="selectedEmployee"
                                            styles={CompactSelectStyles}
                                            isClearable={false}
                                            options={employees}
                                            value={
                                                employees.find(
                                                    (i) => i.value
                                                        === selectedEmployee,
                                                ) ?? ''
                                            }
                                            onChange={
                                                this
                                                    .onSelectedPayrollEmployeeChanged
                                            }
                                        />
                                    </FormGroup>
                                    <Button
                                        color="success"
                                        style={{ marginRight: '10px' }}
                                        disabled={
                                            isRecalculating
                                            || selectedEmployee == null
                                            || selectedEmployee == 0
                                        }
                                        onClick={() => this.onRecalcClick()}
                                    >
                    Recalculate
                                    </Button>
                                    {' '}
                                    {isRecalculating && (
                                        <FontAwesomeIcon
                                            icon={faCircleNotch}
                                            className="fa-spin mr-2"
                                            size="sm"
                                        />
                                    )}
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                        color="secondary"
                                        disabled={isRecalculating}
                                        onClick={() => this.setState({
                                            showRecalcModal: false,
                                        })}
                                    >
                    Close
                                    </Button>
                                </ModalFooter>
                            </Modal>
                        </PageWrap>
                    );
                }}
            </CommonContext.Consumer>
        );
    }
}

export default withRouter(PayrollIndex);
