import React from 'react';
import './DispatchBoard.scss';
import {
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    ButtonGroup,
    FormGroup,
    Input,
    InputGroup,
    Nav,
    NavItem,
    NavLink,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Progress,
} from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faAngleLeft,
    faAngleRight,
    faChevronDown,
    faCircleNotch,
    faStar,
    faTimes,

    faBolt,
    faChevronUp,
    faCog,
    faInfoCircle,
    faMapMarker,
    faSort,
    faSpinner,
    faUsers,
} from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import cls from 'classnames';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import Split from 'react-split';
import _ from 'lodash-es';
import { LogLevel, HubConnectionBuilder } from '@microsoft/signalr';
import moment from 'moment';
import CommonContext, {
    ApiRoutes,
    AppNavPaths,
    Weekdays,
    LocalizationKeys as l,
} from '../Common';
import {
    onFieldChange,
    toasty,
    FormCheckbox,
    FlexStartRow,
    FormBlocker,
    FormLabel,
} from '../common/forms/FormElements';
import {
    DateTimeInputFormat,
    DispatchJobTabs,
    DispatchJobTabNumbers,
    DispatchResourceTabNumbers,
    DispatchResourceTabs,
    DispatchBoardViewModel,
    DispatchResourceSearchParams,
    DispatchJobSearchParams,
    StaffSortTypes,
    JobAssignment,
    getJobAssignmentCancellationTypeIdsThatPreventReassignment,
    isCancelAssignmentTreatedAsUnassigned,
    isCancelAssignmentTreatedAsCancelled,
    JobAssignmentCancellationType,
} from './Dispatch';
import StaffCard from './StaffCard';
import { CompactSelectStyles } from '../common/forms/ValidatedSelect';
import { util } from '../Util';
import { SplitHelper } from '../common/SplitHelper';
import CustomCircularProgress from '../common/CustomCircularProgress';
import EquipmentCard from './EquipmentCard';
import DispatchJobAssignments from './DispatchJobAssignments';
import DispatchCancellationJobAssignments from './DispatchCancellationJobAssignments';
import authService from '../api-authorization/AuthorizeService';
import JobWorkflowEditor from './JobWorkflowEditor';
import { ContractTimesheetResponsibilityType } from '../tenant/Tenant';
import { ConfirmationStatus } from '../assignmentConfirmation/AssignmentConfirmation';
import DailyAttendanceDispatchSink from './DailyAttendanceDispatchSink';
import DispatchEmployeeFilter from './DispatchEmployeeFilter';
import DispatchEquipmentFilter from './DispatchEquipmentFilter';
import { CountyLabel } from '../uscounties/CountyLabel';

class DispatchBoard extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.state = new DispatchBoardViewModel();

        // instance binders
        this.onChange = this.onChange.bind(this);
        this.onAttendanceClick = this.onAttendanceClick.bind(this);
        this.onUnassignedClick = this.onUnassignedClick.bind(this);
        this.onCancelledClick = this.onCancelledClick.bind(this);
        this.onUnassignedEquipmentClick = this.onUnassignedEquipmentClick.bind(this);
    }

    // #region METHODS
    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);

        // If we are navigating away from dispatch, then we must stop the connection to the hub.
        if (this.state?.hubConnection) this.state.hubConnection.stop();
    }

    onAttendanceClick = () => {
        let { onlyShowAttendance } = this.state;

        onlyShowAttendance = !onlyShowAttendance;

        this.setState({
            onlyShowAttendance,
            onlyShowUnassigned: false,
            onlyShowCancelled: false,
        });
    };

    // #endregion

    // #region DND

    onBeforeDragCapture = (draggable, mode) => {
    // by setting draggable Id in the state here, we can modify the dimensions of the draggable item.
    // this helps us solve the issues when its hard to drop between items on job assignments.
        this.setState({
            draggableId:
                draggable.draggableId /* RLC: keeps track of what we're dragging. */,
        });
    };

    onCancelAssignment = async () => {
        const {
            selectedJobAssignmentCancellationType,
            selectedJobAssignmentCancellationNotes,
            selectedJobAssignmentCancellationTime,
            selectedJobAssignmentToRemove,
        } = { ...this.state };
        const model = {
            assignmentId: selectedJobAssignmentToRemove.jobAssignmentId,
            cancellationTypeId: selectedJobAssignmentCancellationType,
            cancellationNote: selectedJobAssignmentCancellationNotes,
            cancellationTime: selectedJobAssignmentCancellationTime,
        };

        if (parseInt(selectedJobAssignmentCancellationType ?? 0) <= 0) {
            toasty.error('Please select the cancellation reason.');
            return false;
        }

        this.setState({ isCancellingAssignment: true });

        const response = await util.fetch.post(
            ApiRoutes.dispatch.cancelAssignment(),
            model,
        );

        if (response === 'ok') {
            toasty.success('Notification sent for cancellation');
        } else {
            toasty.error('Notification Error');
        }

        this.setState({
            showCancelAssignmentModal: false,
            isCancellingAssignment: false,
            selectedJobAssignmentToRemove: null,
            selectedJobAssignmentCancellationNotes: '',
            selectedJobAssignmentCancellationType: null,
            selectedJobAssignmentCancellationTime: DateTimeInputFormat(
                new Date(),
            ),
        });
    };

    onCancelJob = async () => {
        const {
            selectedJobCancellationType,
            selectedJobCancellationNotes,
            selectedJobCancellationTime,
            selectedDayJobToRemove,
            cancelContactId,
        } = { ...this.state };

        if (parseInt(selectedJobCancellationType ?? 0) <= 0) {
            toasty.error('Please select the cancellation reason.');
            return false;
        }

        // if its client cancel need to make sure there are assignments as a timesheet gets made
        if (selectedJobCancellationType == 1) {
            const wf_job = { ...selectedDayJobToRemove.job };
            const assignments = { ...wf_job.assignments };
            const dayAssignments = {
                ...assignments[selectedDayJobToRemove.day],
            };

            // If no emps or equipment, no need to open editor.
            if (
                !(dayAssignments.staff ?? []).length
                && !(dayAssignments.equipment ?? []).length
            ) {
                toasty.error(
                    'Please make an assignment to the job on this day for client cancellations.',
                );
                return false;
            }

            // Need at least one emp to make status edits.
            if (
                !(dayAssignments.staff ?? []).filter((x) => x.jobAssignmentId)
                    .length
            ) {
                toasty.error(
                    'Please assign at least one employee to the job on this day for client cancellations.',
                );
                return false;
            }

            // Make sure no equipment is unassigned.
            if (
                (dayAssignments.equipment ?? []).filter(
                    (x) => !x.assignedEquipment?.id && !x.assignedEmployee?.id,
                ).length
            ) {
                toasty.error(
                    'Please ensure all job equipment has been assigned on this day for client cancellations.',
                );
                return false;
            }
        }

        const model = {
            jobId: selectedDayJobToRemove.job.id,
            day: selectedDayJobToRemove.day,
            cancellationTypeId: selectedJobCancellationType,
            cancellationNote: selectedJobCancellationNotes,
            cancellationTime: selectedJobCancellationTime,
            sendNotifications: true,
            cancelContactId,
        };

        this.setState({ isCancellingJob: true });

        const response = await util.fetch.post(
            ApiRoutes.dispatch.cancelJob(),
            model,
        );

        if (response === 'ok') {
            toasty.success('Notification sent for cancellation');
        } else {
            toasty.error('Notification Error');
        }

        this.setState({
            showCancelJobModal: false,
            isCancellingJob: false,
            selectedDayJobToRemove: null,
            selectedJobCancellationType: 1,
            selectedJobCancellationNotes: '',
            selectedJobCancellationTime: DateTimeInputFormat(new Date()),
        });
    };

    onCancelledClick = () => {
        let { onlyShowCancelled } = this.state;

        onlyShowCancelled = !onlyShowCancelled;

        this.setState({
            onlyShowCancelled,
            onlyShowUnassigned: false,
            onlyShowAttendance: false,
        });
    };

    onChange = onFieldChange;

    setStateAsync = async (state) => {
        return new Promise((resolve) => {
            this.setState(state, resolve)
        });
    }
    // #region RESOURCES
    onCurrentWeekChanged = async (selection) => {
    // the week we are changing to
        const week = { ...selection };
        // get user current selection before swapping weeks
        const { selectedWeekRangeDays } = { ...this.state };
        // get the day ints of the selected values
        const daysSelected = selectedWeekRangeDays.map((d) => d.getDay());
        // take the days in the newly selected week and get the corresponding previous day selections
        const newDaySelections = [
            ...week.days
                .filter((wd) => daysSelected.includes(wd.id))
                .map((wd) => new Date(wd.date)),
        ];

        await this.setState(
            {
                selectedWeek: week,
                selectedWeekRangeDays: newDaySelections,
            },
            async () => {
                await Promise.all([
                    this.getResources(),
                    this.getJobs(),
                    this.getCancellations(),
                ]);
            },
        );
    };

    /**
     * Drag End Handler
     *
     * This is very verbose, and supports all of the drop scenarios as the drop context wraps the entire dispatch
     * board.  When dropping an item, we use the passed in droppableId/draggableIds to lookup the corresponding data for manipulation,
     * as beautiful DnD doesn't really support passing around data objects/arrays.
     *
     * @param {any} result
     */
    onDragEnd = async (result) => {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            this.setState({ dragging: false, draggableId: null });
            return;
        }

        if (source.droppableId === destination.droppableId) {
            // DO nothing, dragging within same list.
            this.setState({ dragging: false, draggableId: null });
        } else {
            let errored = false;
            let sourceData = null;
            let job = null;
            let targetAssignmentData = null;
            const currentState = { ...this.state };
            const [dragResourceType] = result.draggableId.split('_');
            // The Id for the droppable area is composed of metadata that we can use to make determinations.
            const [dropResourceType, jobIndex, dayId, assignmentIndex] = destination.droppableId.split('_');

            // Get the source data (eq, staff, crew)
            sourceData = currentState[dragResourceType].rows[source.index];

            if (dropResourceType === 'dailyAttendanceEvent') {
                if (dragResourceType !== 'staff') {
                    toasty.error(
                        'Not Supported',
                        'Only Staff may be assigned to a Daily Attendance Event.',
                    );

                    return false;
                }

                const dailyAttendanceEventId = jobIndex;

                await this.updateEmployeeDailyAttendanceEvent(
                    sourceData.id,
                    dailyAttendanceEventId,
                );

                await this.setState({ draggableId: null });

                return false;
            }
            job = currentState.planningJobs[jobIndex];

            const dayIdInt = parseInt(dayId);

            if (!job.assignments[dayIdInt].canEdit) {
                toasty.warning(
                    'Unable to Assign',
                    'This job date has completed time sheets. It cannot be modified at this time.',
                    { autoClose: false, closeOnClick: true },
                );

                return false;
            }

            const allAssignments = [
                ...currentState.planningJobs.reduce(
                    (prev, val) => prev.concat(
                        val.assignments[dayIdInt][dragResourceType],
                    ),
                    [],
                ),
            ];

            const assignmentsByDayAndJob = currentState.planningJobs[jobIndex].assignments[dayIdInt][
                dragResourceType
            ];
            targetAssignmentData = currentState.planningJobs[jobIndex].assignments[dayIdInt][
                dragResourceType
            ][parseInt(assignmentIndex)];

            const weekStartDay = new Date(currentState.selectedWeek.start);
            const currentDay = new Date(
                currentState.selectedWeek.days.find(
                    (d) => d.id == dayId,
                ).date,
            );

            const assignment = new JobAssignment();
            assignment.jobId = job.id;
            assignment.resourceId = targetAssignmentData.resourceId;
            assignment.weekOfDate = weekStartDay;
            assignment.assignmentDate = currentDay;
            assignment.day = dayIdInt;
            assignment.assignedEmployeeId = dropResourceType === 'staff' ? sourceData.id : null;
            assignment.assignedEmployeeName = dropResourceType === 'staff' ? sourceData.name : null;
            assignment.assignedEquipmentId = dropResourceType === 'equipment' ? sourceData.id : null;
            assignment.assignedEquipmentName = dropResourceType === 'equipment' ? sourceData.name : null;
            assignment.confirmationStatusId = 2;
            assignment.confirmationStatusName = 'Scheduled';

            // Validation
            if (
                ['staff', 'crews'].includes(dragResourceType)
                && dropResourceType === 'staff'
            ) {
                if (dragResourceType === 'crews') {
                    // TODO - get all staff and equipment from the crew, add to assignments
                }

                if (dragResourceType === 'staff') {
                    if (
                        !this.canStaffBeAssigned(
                            sourceData,
                            assignmentsByDayAndJob,
                            targetAssignmentData,
                            job,
                            assignment,
                            allAssignments,
                        )
                    ) {
                        await this.setState({ draggableId: null });
                        return;
                    }
                    // First save the employee to the job for the day
                    errored = await this.saveAssignment(assignment);

                    // Then check - does the emp have assigned equipment?  Assign it automatically for the day if so.
                    if (!errored && !!(sourceData.assignedEquipment ?? []).length) {
                        // Filter unassigned equipment slots
                        const equipmentSlots = job.assignments[dayIdInt].equipment.filter(
                            (slot) =>
                                !slot.assignment && // Only unassigned slots
                                slot.requiredEquipment.some((req) =>
                                    sourceData.assignedEquipment.map((equip) => equip.equipmentTypeId).includes(req.id)
                                )
                        );
                    
                        // Track assigned equipment locally to avoid duplicates
                        const assignedEquipment = new Set();
                    
                        for (const slot of equipmentSlots) {
                            // Find the first compatible equipment for this slot
                            const equipmentToAssign = sourceData.assignedEquipment.find(
                                (equip) =>
                                    !assignedEquipment.has(equip.id) && // Equipment not already assigned
                                    slot.requiredEquipment.map((req) => req.id).includes(equip.equipmentTypeId) // Matches required type
                            );
                    
                            if (equipmentToAssign) {
                                // Create and save the assignment
                                const eqAssignment = new JobAssignment();
                                eqAssignment.jobId = job.id;
                                eqAssignment.resourceId = slot.id; // Slot ID
                                eqAssignment.weekOfDate = weekStartDay;
                                eqAssignment.assignmentDate = currentDay;
                                eqAssignment.day = dayIdInt;
                                eqAssignment.assignedEquipmentId = equipmentToAssign.id;
                                eqAssignment.assignedEquipmentName = equipmentToAssign.name;
                                eqAssignment.equipmentEmployeeAssignmentId = sourceData.id;
                                eqAssignment.equipmentEmployeeAssignmentName = sourceData.name;
                                eqAssignment.confirmationStatusId = 2; // 'Scheduled'
                                eqAssignment.confirmationStatusName = 'Scheduled';
                    
                                errored = await this.saveAssignment(eqAssignment);
                    
                                if (!errored) {
                                    assignedEquipment.add(equipmentToAssign.id); // Mark equipment as assigned
                                }
                            }
                        }
                    }                  
                }
            } else if (
                dragResourceType === 'equipment'
                && dropResourceType === 'equipment'
            ) {
                if (
                    !this.canEquipmentBeAssigned(
                        sourceData,
                        assignmentsByDayAndJob,
                        targetAssignmentData,
                        job,
                        assignment,
                        allAssignments,
                    )
                ) return;
                errored = await this.saveAssignment(assignment);
            } else {
                return;
            }

            this.setState({ draggableId: null });

            if (errored) {
                // Todo; Display error msg?
            }
        }
    };

    onDragStart = (start, provided) => {
        this.setState({ dragging: true });
    };

    // #region WORKFLOW EDITS
    onEditJobWorkflowClicked = (job, day) => {
    // Validation: if crew lead timesheets, make sure there is a crew lead.
        if (
            job.contractTimesheetResponsibilityTypeId
            === ContractTimesheetResponsibilityType.CrewLead
        ) {
            const hasCrewLead = this.doesJobHaveCrewLead(job, day);
            if (!hasCrewLead) {
                const crewLead = this.state.strings[l.crewLead];

                toasty.error(
                    `Please assign a ${crewLead} on this job for this day before editing workflow.`,
                );
                return false;
            }
        }

        const wf_job = { ...job };
        const assignments = { ...wf_job.assignments };
        const dayAssignments = { ...assignments[day] };
        delete wf_job.assignments;

        // If no emps or equipment, no need to open editor.
        if (
            !(dayAssignments.staff ?? []).length
            && !(dayAssignments.equipment ?? []).length
        ) {
            toasty.error(
                'Please make an assignment to the job on this day before editing workflow.',
            );
            return false;
        }

        // Need at least one emp to make status edits.
        if (!(dayAssignments.staff ?? []).length) {
            toasty.error(
                'Please assign at least one employee to the job on this day before editing workflow.',
            );
            return false;
        }

        // Make sure no equipment is unassigned.
        if (
            (dayAssignments.equipment ?? []).filter(
                (x) => !x.assignedEquipment?.id && !x.assignedEmployee?.id,
            ).length
        ) {
            toasty.error(
                'Please ensure all job equipment has been assigned on this day before editing workflow.',
            );
            return false;
        }

        const selWfJob = { job: wf_job, day, assignments: dayAssignments };
        this.context.setFormOpened(true);
        this.setState({
            selectedWorkflowJob: selWfJob,
            jobWorkflowModalOpen: true,
        });
    };

    onEmergencyAssignment = async () => {
        this.setState({ isCallingOutAssignment: true });
        const { selectedEmergencyAssignments } = this.state;
        if (selectedEmergencyAssignments != []) {
            const eqIds = selectedEmergencyAssignments.equipment.map(
                (x) => x.jobAssignmentId,
            );
            const empIds = selectedEmergencyAssignments.staff.map(
                (x) => x.jobAssignmentId,
            );

            const ids = [...eqIds, ...empIds];
            const model = {
                jobAssignmentIds: ids,
                isEmergency: !selectedEmergencyAssignments.isEmergency,
            };
            const response = await util.fetch.post(
                ApiRoutes.jobAssignment.setEmergency(),
                model,
            );

            if (response.success) {
                await Promise.all([
                    this.getJobs(),
                    this.getCancellations(),
                ]).catch((err) => {
                    this.setState({
                        showModal: false,
                        noResponse: false,
                        isSavingJobStatus: false,
                    });
                });
            } else {
                toasty.warning('Error adjusting emergency status.', '', {
                    autoClose: false,
                    closeOnClick: true,
                });
            }
        }

        this.setState({
            showEmergencyConfirmModal: false,
            isCallingOutAssignment: false,
            selectedEmergencyAssignments: null,
        });
    };

    onEmergencyClicked = (job, day) => {
    // Validation: if crew lead timesheets, make sure there is a crew lead.
    // if (job.contractTimesheetResponsibilityTypeId === ContractTimesheetResponsibilityType.CrewLead) {
    //    const hasCrewLead = this.doesJobHaveCrewLead(job, day);
    //    if (!hasCrewLead) {
    //        toasty.error('Please assign a crew lead on this job for this day before setting an emergency.');
    //        return false;
    //    }
    // }

        const wf_job = { ...job };
        const assignments = { ...wf_job.assignments };
        const dayAssignments = { ...assignments[day] };
        delete wf_job.assignments;

        // If no emps or equipment, no need to open editor.
        if (
            !(dayAssignments.staff ?? []).length
            && !(dayAssignments.equipment ?? []).length
        ) {
            toasty.error(
                'Please make an assignment to the job on this day before setting an emergency.',
            );
            return false;
        }

        // Need at least one emp to make status edits.
        if (
            !(dayAssignments.staff ?? []).filter((x) => x.jobAssignmentId)
                .length
        ) {
            toasty.error(
                'Please assign at least one employee to the job on this day before setting an emergency.',
            );
            return false;
        }

        // Make sure no equipment is unassigned.
        if (
            (dayAssignments.equipment ?? []).filter(
                (x) => !x.assignedEquipment?.id && !x.assignedEmployee?.id,
            ).length
        ) {
            toasty.error(
                'Please ensure all job equipment has been assigned on this day before setting an emergency.',
            );
            return false;
        }

        this.setState({ selectedEmergencyAssignments: dayAssignments });

        // update the jobassignments emergency status for all of assigned items, then when confirm the timesheet will get it.
        // open a modal for "are you sure"
        this.toggleEmergencyConfirmModal();
    };

    onEquipmentAssignmentChange = (
        jobIndex,
        dayIndex,
        assignmentType,
        assignmentIndex,
        resourceId,
    ) => {
        const { planningJobs } = { ...this.state };
        const jobAssignment = {
            ...planningJobs[jobIndex].assignments[dayIndex].equipment[
                assignmentIndex
            ],
        };

        // bugbug: check compliances here?

        if (assignmentType === 'staff') {
            this.updateEquipmentAssignedEmployee(
                jobAssignment.jobAssignmentId,
                resourceId,
            );
        } else {
            this.updateEquipmentAssignedEquipment(
                jobAssignment.jobAssignmentId,
                resourceId,
            );
        }
    };
    // #endregion

    onJobCancellationCreatedFromHub = (jobCreatedString) => {
        const { selectedJobDispatchLocation } = this.state;
        const job = JSON.parse(jobCreatedString);

        if (job.dispatchLocationId !== selectedJobDispatchLocation) return;
        const cancellationJobs = [...this.state.cancellationJobs];
        cancellationJobs.push(job);
        this.setState({
            cancellationJobs,
            lastPushUpdate: `Jobs: ${job.name} - ${job.number} - ${job.startTime} were cancelled.`,
        });
    };

    onJobCancellationUpdatedFromHub = (jobUpdatedString) => {
        const { selectedJobDispatchLocation } = { ...this.state };
        const updatedJob = JSON.parse(jobUpdatedString);

        if (updatedJob.dispatchLocationId !== selectedJobDispatchLocation) return;

        const cancellationJobs = [...this.state.cancellationJobs];
        const existingJob = cancellationJobs.find(
            (j) => j.id === updatedJob.id,
        );

        let i = null;
        if (existingJob) i = cancellationJobs.findIndex((x) => x.id === existingJob.id);

        if (i == null || i == -1) {
            cancellationJobs.push(updatedJob);
        } else {
            cancellationJobs[i] = updatedJob;
        }

        this.setState(
            {
                cancellationJobs,
                lastPushUpdate: `Jobs: ${updatedJob.name} - ${updatedJob.number} - ${updatedJob.startTime} were cancelled.`,
            },
            () => this.setJobNotSaving(updatedJob.id),
        );
    };

    onJobCreatedFromHub = (jobCreatedString) => {
        const { selectedJobDispatchLocation } = this.state;
        const job = JSON.parse(jobCreatedString);

        if (job.dispatchLocationId !== selectedJobDispatchLocation) return;
        const { allJobs, planningJobs } = this.state;

        allJobs.push(job);

        // If the new job passes the current filter criteria, add it to the planning jobs.
        const doesPass = this.isFilteredPlanningJob(job);
        if (doesPass) planningJobs.push(job);

        this.setState({
            allJobs,
            planningJobs,
            lastPushUpdate: `Jobs: ${job.name} - ${job.number} - ${job.startTime} was added.`,
        });
    };

    onJobTabClicked = async (tab) => {
        const selectedTab = { ...tab };
        this.setState({ activeJobTab: selectedTab });
    };

    onJobUpdatedFromHub = (jobUpdatedString) => {
        const { selectedJobDispatchLocation, selectedWeekRangeDays } = {
            ...this.state,
        };
        const updatedJob = JSON.parse(jobUpdatedString);

        if (updatedJob.dispatchLocationId !== selectedJobDispatchLocation) return;

        const { planningJobs } = this.state;
        const existingJob = planningJobs.find((j) => j.id === updatedJob.id);
        const i = planningJobs.indexOf(existingJob);

        // Need to update allJobs too since in the update case, we're replacing the job
        // with a new instance.
        const { allJobs } = this.state;
        const ia = allJobs.indexOf(existingJob);

        let cancelled = false;
        if (selectedWeekRangeDays.length == 1) {
            cancelled = selectedWeekRangeDays.every(
                (x) => updatedJob.assignments[x.getDay()].equipment.length == 0
                    && updatedJob.assignments[x.getDay()].staff.length == 0,
            );
        }

        if (updatedJob.isDeleted || cancelled) {
            planningJobs.splice(i, 1);
            allJobs.splice(ia, 1);
        } else {
            planningJobs[i] = updatedJob;
            allJobs[ia] = updatedJob;
        }

        const action = updatedJob.isDeleted ? 'deleted' : 'updated';

        this.setState(
            {
                allJobs,
                planningJobs,
                lastPushUpdate: `Jobs: ${updatedJob.name} - ${updatedJob.number} - ${updatedJob.startTime} was ${action}.`,
            },
            () => this.setJobNotSaving(updatedJob.id),
        );
    };

    onJobsUpdatedBulkFromHub = (jsonStr) => {
        const jobs = JSON.parse(jsonStr);
        const planningJobs = [...this.state.planningJobs];

        const updated = planningJobs
        // Take updated jobs, otherwise keep the old job
            .map((x) => jobs.find((j) => j.id === x.id) ?? x)
        // Remove anything that's been flagged as deleted.
            .filter((x) => !x.isDeleted);

        this.setState({
            planningJobs: updated,
            lastPushUpdate: `${jobs.length} jobs updated.`,
        });
    };

    onNextWeekClicked = () => {
        const { weeks, selectedWeek } = { ...this.state };
        let currentIndex = weeks
            .map((x) => x.start)
            .indexOf(selectedWeek.start);
        if (currentIndex + 1 === weeks.length) return;
        this.onCurrentWeekChanged({ ...weeks[(currentIndex += 1)] });
    };

    onNoResponseCheckedChanged = (e) => {
        let { noResponse } = this.state;
        noResponse = e.target.checked;
        this.setState({ noResponse });
    };

    onPreviousWeekClicked = () => {
        const { weeks, selectedWeek } = { ...this.state };
        let currentIndex = weeks
            .map((x) => x.start)
            .indexOf(selectedWeek.start);
        if (currentIndex === 0) return;
        this.onCurrentWeekChanged({ ...weeks[(currentIndex -= 1)] });
    };

    onRemoveAssignment = async (job, jobAssignment, day) => {
        const crewLead = this.state.strings[l.crewLead];

        // this hits too early, and is covered by confirmation/send notifications
        // If Staff Assignment - Need to make sure the scheduler is not removing the crew lead
        if (jobAssignment.assignableGroups) {
            const staffCount = (
                job.assignments[day].staff.filter((s) => !!s.assignment) ?? []
            ).length;
            if (!!jobAssignment.isCrewLead && staffCount > 1) {
                toasty.error(
                    `Please promote another crew member to the ${crewLead} before removing/cancelling this crew member.  Each job must have a ${crewLead.toLowerCase()}.`,
                );
                return false;
            }
        }

        // show modal and get reason if its not in a status of scheduled.
        if (jobAssignment.assignment.confirmationStatusId >= 3) {
            this.setState(
                {
                    selectedJobAssignmentToRemove: jobAssignment,
                    selectedJobAssignmentCancellationStartTime: job.startTime,
                    selectedJobAssignmentCancellationTime: DateTimeInputFormat(
                        new Date(),
                    ),
                },
                () => this.toggleCancelAssignmentModal(),
            );

            // if (!!jobAssignment.assignableGroups) {
            //    //if assignment is a crew lead then we need to validate if its the only/last on the job, if so prompt
            //    //to cancel the entire job and disallow removal.
            //    //Validation: if crew lead timesheets, make sure there is a crew lead.
            //    if (job.contractTimesheetResponsibilityTypeId === ContractTimesheetResponsibilityType.CrewLead) {
            //        var crewLeadLeftOnly = job.assignments[day].staff.filter(x => !!x.isCrewLead).length ==
            //            job.assignments[day].staff.length;

            //        if (crewLeadLeftOnly) {
            //            toasty.error('Job must have a crew lead');
            //            await this.setJobNotSaving(job.id);
            //            return false;
            //        }
            //    }
            // }
        } else {
            await this.setJobSaving(job.id);
            this.removeAssignment(jobAssignment);
            await this.setJobNotSaving(job.id);
        }
    };

    onRemoveDayJob = async (job, day) => {
        const { expandedCancellationReasonRequired, id } = job;

        let companyContacts = [];

        if (expandedCancellationReasonRequired) {
            this.setState({ cancelJobDataLoading: true });

            const allCompanyContacts = await util.fetch.post(
                ApiRoutes.locationContact.forJob(id),
            );

            companyContacts = allCompanyContacts.map((lc) => this.toContactDropdownOption(lc));
        }

        await this.setState({
            expandedCancellationReasonRequired,
            companyContacts,
            cancelJobDataLoading: false,
            cancelContactId: null,
            selectedJobCancellationType: null,
        });

        const dayAssignment = job.assignments[day];
        if (
            dayAssignment.staff.length > 0
            || dayAssignment.equipment.length > 0
        ) {
            const empAssignments = dayAssignment.staff.filter(
                (x) => x.assignment !== null,
            );
            const eqAssignments = dayAssignment.equipment.filter(
                (x) => x.assignment !== null,
            );
            const allAssignments = [...empAssignments, ...eqAssignments];
            if (allAssignments.length) {
                // any emp or eq assignments have a confirmation less than 3 can be removed without cancel.
                // Could be an issue here if they get into a situation where there is a mix/match? not sure its possible.
                if (
                    allAssignments.filter(
                        (x) => x.assignment.confirmationStatusId < 3,
                    ).length == allAssignments.length
                ) {
                    // just remove them all as they are not confirmed or anything
                    await this.setJobSaving(job.id);
                    await this.removeAllJobAssignments(
                        allAssignments.map((x) => x.jobAssignmentId),
                    );
                    await this.setJobNotSaving(job.id);
                } else {
                    // if there is a mix match of no response and confirmed assignments, notify and disallow cancellation
                    const allConf = allAssignments.filter(
                        (x) => x.assignment.confirmationStatusId == 4,
                    ).length == allAssignments.length;

                    if (!allConf) {
                        toasty.error(
                            'Please make sure that all assignments are Confirmed to cancel the job.',
                        );
                        return false;
                    }
                    // needs cancellation
                    this.setState({
                        selectedDayJobToRemove: { job, day },
                        selectedJobCancellationTime: DateTimeInputFormat(
                            new Date(),
                        ),
                    });
                    this.toggleCancelJobModal();
                }
            }
        }
    };

    onResourceTabClicked = async (tab) => {
        const selectedTab = { ...tab };
        await this.setState({ activeResourceTab: selectedTab });
    };

    onSelectedJobAssignmentCancellationReasonChanged = async (selection) => {
        const sel = { ...selection };

        await this.setState({
            selectedJobAssignmentCancellationType: sel.id,
        });
    };

    onSelectedJobCancellationReasonChanged = async (selection) => {
        const sel = { ...selection };
        const selectedJobCancellationType = sel.value;
        await this.setState({ selectedJobCancellationType });
    };

    onSelectedJobDispatchLocationChanged = async (selection) => {
        const { tenantSettings } = this.state;
        const { allowCrossDispatching } = tenantSettings;
        const { selectedResourceDispatchLocation } = { ...this.state };
        const isAdmin = this.context?.user?.isAdmin;
        const loc = { ...selection };

        await this.setState({
            selectedJobDispatchLocation: loc.value,
            // #2180 - Admins can cross dispatch.  Otherwise, keep the panes (resources/jobs)
            //        in dispatch step by setting the selected dispatch on both sides.
            selectedResourceDispatchLocation:
                !!isAdmin && !!allowCrossDispatching
                    ? selectedResourceDispatchLocation
                    : loc.value,
        });

        if (!isAdmin || !allowCrossDispatching) {
            await Promise.all([this.getResources(), this.getJobs()]);
        } else {
            await this.getJobs();
        }
        await this.getCancellations();
    };

    onSelectedResourceDispatchLocationChanged = async (selection) => {
        const loc = { ...selection };
        await this.setState(
            { selectedResourceDispatchLocation: loc.value },
            () => this.getResources(),
        );
    };

    onSelectedWeekRangeDayClicked = async (day) => {
        const { selectedWeekRangeDays, selectedWeek } = { ...this.state };
        // get ints representing the week day(s) selected
        let daysSelected = selectedWeekRangeDays.map((d) => d.getDay());
        // If toggling off, remove only if there's > 1 selection
        if (daysSelected.includes(day.getDay())) {
            if (daysSelected.length > 1) {
                daysSelected = [
                    ...daysSelected.filter((d) => d !== day.getDay()),
                ];
            }
        } else {
            daysSelected.push(day.getDay());
        }
        // get the date objects for the day selections
        let newSelections = [
            ...selectedWeek.days
                .filter((wd) => daysSelected.includes(wd.id))
                .map((wd) => this.getSelectableDate(wd)),
        ];
        newSelections = _.sortBy(newSelections, (o) => o);

        this.setState({ selectedWeekRangeDays: newSelections }, async () => {
            await this.getResources();
            await this.getJobs();
            await this.getCancellations();
        });
    };

    onSendNotifications = async () => {
        const { selectedWeekRangeDays, selectedJobDispatchLocation, noResponse } = { ...this.state };
        // can only send if 1 day is selected.  Button should hide but double check

        this.setState({ isSavingJobStatus: true });

        if (
            (selectedWeekRangeDays ?? []).length === 1
            && selectedJobDispatchLocation
        ) {
            // pop up modal to ask if you want to include no response employees
            const model = {
                date: selectedWeekRangeDays[0],
                dispatchLocationId: selectedJobDispatchLocation,
                resendNoResponse: noResponse,
            };

            const response = await util.fetch.post(
                ApiRoutes.jobAssignment.notify(),
                model,
            );

            if (!(response.errorMessages ?? []).length) {
                await Promise.all([
                    this.getJobs(),
                    this.getCancellations(),
                ]).catch((err) => {
                    this.setState({
                        showModal: false,
                        noResponse: false,
                        isSavingJobStatus: false,
                    });
                });
            } else {
                toasty.warning(
                    'Notifications completed with errors',
                    `${response.errorMessages}`,
                    { autoClose: false, closeOnClick: true },
                );
            }
        }

        this.setState({
            showModal: false,
            noResponse: false,
            isSavingJobStatus: false,
        });
    };
    // #endregion

    onStaffRelatedDataUpdatedFromHub = (employeeId) => {
        const { allStaffIds } = this.state;

        if (!allStaffIds.includes(employeeId)) {
            return;
        }

        this.getResources();
    };

    onUnassignedClick = () => {
        let { onlyShowUnassigned } = this.state;

        onlyShowUnassigned = !onlyShowUnassigned;

        this.setState({
            onlyShowUnassigned,
            onlyShowAttendance: false,
            onlyShowCancelled: false,
        });
    };

    onUnassignedEquipmentClick = () => {
        let { onlyShowUnassignedEquipment } = this.state;

        onlyShowUnassignedEquipment = !onlyShowUnassignedEquipment;

        this.setState({ onlyShowUnassignedEquipment });
    };

    getAllJobs = async () => {
        const { selectedWeek, selectedJobDispatchLocation } = this.state;
        const searchParams = new DispatchJobSearchParams();

        searchParams.weekStartDate = new Date(selectedWeek.start);
        searchParams.weekEndDate = new Date(selectedWeek.end);
        searchParams.dispatchLocation = selectedJobDispatchLocation;

        // MFN 2022-03-14 - We want all jobs for all days.
        // Restore line to this if this is not what we want (will need to get selectedWeekRangeDays from state): selectedWeekRangeDays.map(x => new Date(x).getDay()) ?? [];
        searchParams.selectedDays = null;
        searchParams.searchText = null; // set to jobSearchText from state if you do not want all jobs.

        const allData = await util.fetch.post(
            ApiRoutes.dispatch.jobs(),
            searchParams,
        );

        // Don't need filteredCount/maxRows - we handle that as part of the filtering process.
        const { rows } = allData;

        return rows;
    };

    getCancellationBoard = () => {
        const { cancellationJobs, selectedWeekRangeDays } = { ...this.state };
        const accumulator = { cancelPending: 0, cancelConfirmed: 0 };
        const days = selectedWeekRangeDays.map((wrd) => wrd.getDay());
        const staff = cancellationJobs.reduce(
            (acc, job) => {
                const cancelPending = acc.cancelPending
                    + days.reduce((acc, day) => (
                        acc
                            + job.assignments[day].cancelledAssignments.filter(
                                (g) => !!g.assignedEmployee && !g.confirmedOn,
                            ).length
                    ), 0);
                const cancelConfirmed = acc.cancelConfirmed
                    + days.reduce((acc, day) => (
                        acc
                            + job.assignments[day].cancelledAssignments.filter(
                                (g) => !!g.assignedEmployee && !!g.confirmedOn,
                            ).length
                    ), 0);
                return {
                    cancelPending,
                    cancelConfirmed,
                };
            },
            { ...accumulator },
        );

        const eq = cancellationJobs.reduce(
            (acc, job) => {
                const cancelPending = acc.cancelPending
                    + days.reduce((acc, day) => (
                        acc
                            + job.assignments[day].cancelledAssignments.filter(
                                (g) => !!g.assignedEquipment && !g.confirmedOn,
                            ).length
                    ), 0);
                const cancelConfirmed = acc.cancelConfirmed
                    + days.reduce((acc, day) => (
                        acc
                            + job.assignments[day].cancelledAssignments.filter(
                                (g) => !!g.assignedEquipment && !!g.confirmedOn,
                            ).length
                    ), 0);
                return {
                    cancelPending,
                    cancelConfirmed,
                };
            },
            { ...accumulator },
        );

        return {
            staff: {
                cancelPending: staff.cancelPending,
                cancelConfirmed: staff.cancelConfirmed,
            },
            equipment: {
                cancelPending: eq.cancelPending,
                cancelConfirmed: eq.cancelConfirmed,
            },
        };
    };

    getCancellations = _.debounce(async () => {
        if (!this.state.cancellationsLoading) {
            this.setState({ cancellationsLoading: true });

            const {
                cancellationJobSearchText,
                selectedWeek,
                selectedJobDispatchLocation,
                selectedWeekRangeDays,
            } = { ...this.state };
            const searchParams = new DispatchJobSearchParams();

            searchParams.weekStartDate = new Date(selectedWeek.start);
            searchParams.weekEndDate = new Date(selectedWeek.end);
            searchParams.searchText = cancellationJobSearchText ?? '';
            searchParams.dispatchLocation = selectedJobDispatchLocation;
            searchParams.selectedDays = selectedWeekRangeDays.map((x) => new Date(x).getDay())
                    ?? [];

            const jobData = await util.fetch.post(
                ApiRoutes.dispatch.cancelledJobs(),
                searchParams,
            );

            this.setState(
                {
                    cancellationsLoading: false,
                    cancellationJobs: jobData.rows,
                    cancellationJobsFilteredCount: jobData.filterCount,
                    cancellationJobsMaxRows: jobData.maxRows,
                },
            );
        }
    }, 250);

    getCompliancesForJob = (job) => {
        const { complianceTypes } = this.state;

        return complianceTypes.filter(
            (c) => c.appliesToAllDispatches
                || (job.complianceIds || []).includes(c.id),
        );
    };

    getCurrentResource = () => {
        const {
            activeResourceTab, staff, equipment, crews,
        } = {
            ...this.state,
        };
        switch (activeResourceTab.number) {
        case DispatchResourceTabNumbers.Staff:
            return staff;
        case DispatchResourceTabNumbers.Equipment:
            return equipment;
        case DispatchResourceTabNumbers.Crews:
            return crews;
        default:
            return null;
        }
    };

    getDailyAttendanceEventRestrictionMessage = (
        employee,
        assignment,
        dailyAttendanceEvents,
        employeeDailyAttendanceEvents,
    ) => {
        const dateInQuestion = moment(assignment.assignmentDate);

        const employeeDailyAttendanceEvent = employeeDailyAttendanceEvents.find(
            (edae) => edae.employeeId == employee.id
                && moment(dateInQuestion).isSame(edae.eventDate, 'day'),
        );

        if (!employeeDailyAttendanceEvent) {
            return null;
        }

        const dailyAttendanceEvent = dailyAttendanceEvents.find(
            (dae) => dae.id == employeeDailyAttendanceEvent.dailyAttendanceEventId,
        );

        if (
            dailyAttendanceEvent
            && dailyAttendanceEvent.employeeMayNotBeAssigned
        ) {
            return `${employee.name} has a Daily Attendance Event of "${dailyAttendanceEvent.name}" which prevents assignment.`;
        }

        return null;
    };

    getEmployeeDailyAttendanceEvents = _.debounce(async () => {
        const {
            employeeDailyAttendanceEventsLoading,
            selectedWeekRangeDays,
            selectedJobDispatchLocation,
        } = this.state;

        const eventDate = selectedWeekRangeDays && selectedWeekRangeDays.length === 1
            ? moment(selectedWeekRangeDays[0]).toDate()
            : null;

        if (!employeeDailyAttendanceEventsLoading) {
            this.setState({
                employeeDailyAttendanceEventsLoading: true,
            });

            const employeeDailyAttendanceEvents = eventDate
                ? await util.fetch.post(
                    ApiRoutes.employeeDailyAttendanceEvent.allFor(),
                    {
                        eventDate,
                        dispatchLocationId: selectedJobDispatchLocation,
                    },
                )
                : [];

            this.setState(
                {
                    employeeDailyAttendanceEvents,
                    employeeDailyAttendanceEventsLoading: false,
                },
            );
        }
    }, 250);

    getEmployeeLongTermStatuses = _.debounce(async () => {
        const { employeeLongTermStatusesLoading } = this.state;

        if (!employeeLongTermStatusesLoading) {
            this.setState({ employeeLongTermStatusesLoading: true });

            const searchParams = this.getResourceSearchParams();

            const employeeLongTermStatuses = await util.fetch.post(
                ApiRoutes.employeeLongTermStatus.allEffectiveBetween(),
                searchParams,
            );

            this.setState({
                employeeLongTermStatuses,
                employeeLongTermStatusesLoading: false,
            });

            const { staff } = this.state;

            this.hideRestrictedStaff(staff);

            this.setState({ staff });
        }
    }, 250);

    getEquipment = _.debounce(async () => {
        if (!this.state.equipmentLoading) {
            this.setState({ equipmentLoading: true });
            const searchParams = this.getResourceSearchParams();
            const equipment = await util.fetch.post(
                ApiRoutes.dispatch.equipment(),
                searchParams,
            );

            this.setState(
                {
                    equipment,
                    equipmentLoading: false,
                },
            );
        }
    }, 250);

    getHiddenEquipmentIds = () => {
        const { equipment, activeResourceTab } = this.state;

        const hiddenEquipmentIds = [];
        if (activeResourceTab.number === DispatchResourceTabNumbers.Equipment) {
            hiddenEquipmentIds.push(
                ...equipment.rows
                    .filter((eq) => this.equipmentShouldBeHidden(eq))
                    .map((eq) => eq.id),
            );
        }

        return hiddenEquipmentIds;
    };

    getHiddenStaffIds = () => {
        const {
            onlyShowAttendance,
            onlyShowUnassigned,
            onlyShowCancelled,
            staff,
        } = this.state;

        const hiddenStaffIds = [];

        if (onlyShowAttendance || onlyShowUnassigned || onlyShowCancelled) {
            hiddenStaffIds.push(
                ...staff.rows
                    .filter((s) => this.staffShouldBeHidden(s))
                    .map((s) => s.id),
            );
        }

        return hiddenStaffIds;
    };

    getIsCancelJobSaveDisabled = () => {
        const {
            expandedCancellationReasonRequired,
            selectedJobCancellationType,
            cancelContactId,
            cancelJobDataLoading,
        } = this.state;

        if (cancelJobDataLoading) {
            return true;
        }

        return Boolean(
            expandedCancellationReasonRequired
                && selectedJobCancellationType
                    == JobAssignmentCancellationType.ClientCancel
                && !cancelContactId,
        );
    };

    getJobs = _.debounce(async () => {
        if (!this.state.jobsLoading) {
            this.setState({ jobsLoading: true });

            const {
                allJobs,
                selectedWeek,
                jobLoadedWeek,
                selectedJobDispatchLocation,
                jobLoadedDispatchLocation,
            } = this.state;

            // If the selected week & dispatch location are what was loaded last,
            // use the list of allJobs.  Otherwise, get a fresh copy of the jobs.
            // We get all jobs first because we want awareness of all job assignments for all staff/equipment
            // even if that assignment falls on a day where a given job is not on the list of selected days.
            // Allows use to see all days during the week that staff/equipment are assigned.
            const allData = selectedWeek === jobLoadedWeek
                    && selectedJobDispatchLocation === jobLoadedDispatchLocation
                ? allJobs
                : await this.getAllJobs();

            // planningJobs - jobs that fall within the selected days of the week.
            // We do filter the jobs display based on this.
            const pjs = allData.filter(this.isFilteredPlanningJob);

            this.setState(
                {
                    jobsLoading: false,
                    jobLoadedWeek: selectedWeek,
                    jobLoadedDispatchLocation: selectedJobDispatchLocation,
                    allJobs: allData,
                    planningJobs: pjs,
                    planningJobsFilteredCount: pjs.length,
                    planningJobsMaxRows: allData.length,
                },
            );
        }
    }, 250);

    getLongTermStatusRestrictionMessage = (
        employee,
        assignment,
        longTermStatuses,
        employeeLongTermStatuses,
    ) => {
        const dateInQuestion = moment(assignment.assignmentDate);

        const effectiveEmployeeLongTermStatus = employeeLongTermStatuses.find(
            (elts) => elts.employeeId == employee.id
                && moment(dateInQuestion).isBetween(
                    elts.startDate,
                    elts.endDate || moment('2099-12-31'),
                    undefined,
                    '[]',
                ),
        ); // inclusive

        if (!effectiveEmployeeLongTermStatus) {
            return null;
        }

        const effectiveLongTermStatus = longTermStatuses.find(
            (lts) => lts.id == effectiveEmployeeLongTermStatus.longTermStatusId,
        );

        if (
            !effectiveLongTermStatus
            || (effectiveLongTermStatus.allowDispatch
                && !effectiveLongTermStatus.hideFromDispatchBoard)
        ) {
            if (
                effectiveLongTermStatus
                && effectiveLongTermStatus.allowDispatch
            ) {
                const warning = `${employee.name} has a Long-Term Status of "${effectiveLongTermStatus.name}", but this did not prevent assignment.`;

                toasty.warning(
                    'Employee With Long-Term Status Assigned',
                    warning,
                    {
                        autoClose: false,
                        closeOnClick: true,
                    },
                );
            }

            return null;
        }

        return `${employee.name} has a Long-Term Status of "${effectiveLongTermStatus.name}" applied.`;
    };

    // bugbug: this doesn't do any graph traversal to examine compliances for
    // related employees or equipment - think assigned drivers, or tractors that haul trailers
    getMissingEquipmentComplianceMessage = (
        equipment,
        job,
        targetAssignmentData,
        assignment,
    ) => {
        if (!this.isComplianceEnabled()) {
            return '';
        }

        const jobCompliances = this.getCompliancesForJob(job).filter(
            (c) => this.isEquipmentCompliance(c)
                && this.isComplianceNeededForEquipmentType(c, targetAssignmentData),
        );

        const missingComplianceNames = jobCompliances
            .filter((c) => !this.complianceIsUpToDate(equipment, c, assignment))
            .map((c) => c.name);

        if (missingComplianceNames && missingComplianceNames.length > 0) {
            return `${
                equipment.description
            } is not up-to-date on the following required compliances, and may not be assigned: [${missingComplianceNames.join(
                ',',
            )}].`;
        }

        return '';
    };

    getMissingUserComplianceMessage = (
        staff,
        job,
        targetAssignmentData,
        assignment,
    ) => {
        if (!this.isComplianceEnabled()) {
            return '';
        }

        const jobCompliances = this.getCompliancesForJob(job).filter(
            (c) => this.isEmployeeCompliance(c)
                && this.isComplianceNeededForGroupType(
                    staff,
                    c,
                    targetAssignmentData,
                ),
        );

        const missingComplianceNames = jobCompliances
            .filter((c) => !this.complianceIsUpToDate(staff, c, assignment))
            .map((c) => c.name);

        if (missingComplianceNames && missingComplianceNames.length > 0) {
            return `${
                staff.name
            } is not up-to-date on the following required compliances, and may not be assigned: [${missingComplianceNames.join(
                ',',
            )}].`;
        }

        return '';
    };

    getNameSet = (job) => {
        const nameSet = new Set();

        const entries = Object.entries(job.assignments);
        for (const entry of entries) {
            const a = entry[1];

            for (const s of a.staff) {
                // s.assignment.name: "Doe, John"
                if (s.assignment) nameSet.add(s.assignment.name);
            }
        }

        return nameSet;
    };

    getOpsBoard = () =>
    // TODO: derive this from personnel in the ops job listing.

        ({
            staff: { confirmed: 0, started: 0, timeCard: 0 },
        });

    // RLC: This needs to go server side.
    getPlanningBoard = () => {
        const { planningJobs, selectedWeekRangeDays } = { ...this.state };
        const accumulator = {
            filled: 0,
            unfilled: 0,
            noResponse: 0,
            confirmed: 0,
            cancelled: 0,
        };
        const days = selectedWeekRangeDays.map((wrd) => wrd.getDay());
        const staff = planningJobs.reduce(
            (acc, job) => {
                const unfilled = acc.unfilled
                    + days.reduce((acc, day) => (
                        acc
                            + job.assignments[day].staff.length
                            - job.assignments[day].staff.filter(
                                (g) => !!g.assignment,
                            ).length
                    ), 0);
                const filled = acc.filled
                    + days.reduce((acc, day) => (
                        acc
                            + job.assignments[day].staff.filter(
                                (g) => !!g.assignment
                                    && g.assignment.confirmationStatusId
                                        === ConfirmationStatus.Scheduled,
                            ).length
                    ), 0);
                const noResponse = acc.noResponse
                    + days.reduce((acc, day) => (
                        acc
                            + job.assignments[day].staff.filter(
                                (g) => !!g.assignment
                                    && g.assignment.confirmationStatusId
                                        === ConfirmationStatus.NoResponse,
                            ).length
                    ), 0);
                const confirmed = acc.confirmed
                    + days.reduce((acc, day) => (
                        acc
                            + job.assignments[day].staff.filter(
                                (g) => !!g.assignment
                                    && g.assignment.confirmationStatusId
                                        === ConfirmationStatus.Confirmed,
                            ).length
                    ), 0);
                return {
                    unfilled,
                    filled,
                    noResponse,
                    confirmed,
                };
            },
            { ...accumulator },
        );

        const eq = planningJobs.reduce(
            (acc, job) => {
                const unfilled = acc.unfilled
                    + days.reduce((acc, day) => (
                        acc
                            + (job.assignments[day].equipment.length
                                - job.assignments[day].equipment.filter(
                                    (g) => !!g.assignment,
                                ).length)
                    ), 0);
                const filled = acc.filled
                    + days.reduce((acc, day) => (
                        acc
                            + job.assignments[day].equipment.filter(
                                (g) => (g.assignment ?? {})
                                    .confirmationStatusId
                                    === ConfirmationStatus.Scheduled,
                            ).length
                    ), 0);
                const noResponse = acc.noResponse
                    + days.reduce((acc, day) => (
                        acc
                            + job.assignments[day].equipment.filter(
                                (g) => (g.assignment ?? {})
                                    .confirmationStatusId
                                    === ConfirmationStatus.NoResponse,
                            ).length
                    ), 0);
                const confirmed = acc.confirmed
                    + days.reduce((acc, day) => (
                        acc
                            + job.assignments[day].equipment.filter(
                                (g) => (g.assignment ?? {})
                                    .confirmationStatusId
                                    === ConfirmationStatus.Confirmed,
                            ).length
                    ), 0);
                return {
                    unfilled,
                    filled,
                    noResponse,
                    confirmed,
                };
            },
            { ...accumulator },
        );

        return {
            staff: {
                unfilled: staff.unfilled,
                scheduled: staff.filled,
                noResponse: staff.noResponse,
                confirmed: staff.confirmed,
            },
            equipment: {
                unfilled: eq.unfilled,
                scheduled: eq.filled,
                noResponse: eq.noResponse,
                confirmed: eq.confirmed,
            },
        };
    };

    getPreferredDispatch = () => {
        if (!window.localStorage) return null;

        const preferredDispatch = localStorage.getItem(
            'preferredDispatchLocation',
        );

        if (preferredDispatch) return parseInt(preferredDispatch);
        return null;
    };

    getResourceSearchParams = () => {
        const {
            resourceSearchText,
            selectedWeekRangeDays,
            selectedResourceDispatchLocation,
        } = { ...this.state };
        const searchParams = new DispatchResourceSearchParams();
        searchParams.dates = (selectedWeekRangeDays ?? []).map((d) => d) ?? [];
        searchParams.searchText = resourceSearchText ?? '';
        searchParams.dispatchLocation = selectedResourceDispatchLocation;
        return searchParams;
    };

    getResources = _.debounce(async () => {
        if (!this.state.resourcesLoading) {
            this.setState({
                resourcesLoading: true,
                hiddenEmployeeCount: 0,
            });

            const searchParams = this.getResourceSearchParams();

            const promises = [
                util.fetch.post(ApiRoutes.dispatch.staff(), searchParams),
                util.fetch.post(
                    ApiRoutes.dispatch.equipment(),
                    searchParams,
                ),
            ];

            if (this.state.crewsEnabled) {
                promises.push(
                    util.fetch.post(
                        ApiRoutes.dispatch.crews(),
                        searchParams,
                    ),
                );
            }

            await this.getEmployeeDailyAttendanceEvents();
            await this.getEmployeeLongTermStatuses();

            const [staff, equipment, crews] = await Promise.all(promises);

            let allStaffIds = [];

            if (staff && staff.rows) {
                allStaffIds = staff.rows.map((s) => s.id);
            }

            this.hideRestrictedStaff(staff);

            this.setState(
                {
                    resourcesLoading: false,
                    staff,
                    allStaffIds,
                    equipment,
                    crews,
                },
            );
        }
    }, 250);

    // 2022-09-30 - M. Nicol - Done in support of ensuring day selections work as expected.
    // Might be able to use moment.js or put in a utility class.
    getSelectableDate(wd) {
    // Original code - susceptible to crossing days due to timezone:
    // const ret = new Date(wd.date);

        // New:
        // wd.date === "2022-09-28T00:00:00-04:00"
        const t = wd.date.indexOf('T');

        // 2022-09-28
        const dateOnly = wd.date.substring(0, t);

        const [y, m, d] = dateOnly.split('-');

        // JS date - use index for month (January is 0).
        // Set hour to noon so we won't cross days due to timezone issues.
        const finalDate = new Date(y, m - 1, d, 12);

        return finalDate;
    }

    getStaff = _.debounce(async () => {
        if (!this.state.staffLoading) {
            this.setState({
                staffLoading: true,
                hiddenEmployeeCount: 0,
            });
            const searchParams = this.getResourceSearchParams();
            const staff = await util.fetch.post(
                ApiRoutes.dispatch.staff(),
                searchParams,
            );

            this.hideRestrictedStaff(staff);

            this.setState({ staff, staffLoading: false });
        }
    }, 250);

    setCrewLead = async (jobAssignmentId, jobId) => {
    // Block the UI for the job while saving the crew lead.  There is also validation done here.
        await this.setState({ jobsSaving: [jobId] });
        await util.fetch.js(ApiRoutes.dispatch.setCrewLead(jobAssignmentId));
        await this.setState({ jobsSaving: [] });
    };

    setJobNotSaving = async (jobId) => {
        if (!jobId) throw new Error('DispatchBoard.setJobSaving: jobId is required');
        const saving = [...this.state.jobsSaving];
        const jobs_saving_updated = saving.filter((x) => x !== jobId) ?? [];
        await this.setStateAsync({ jobsSaving: jobs_saving_updated });
    };

    setJobSaving = async (jobId) => {
        if (!jobId) throw new Error('DispatchBoard.setJobSaving: jobId is required');
        const jobs_saving = this.state.jobsSaving.filter((x) => x !== jobId) ?? [];
        jobs_saving.push(jobId);
        await this.setStateAsync({ jobsSaving: jobs_saving });
    };

    setupPushNotifications = () => {
        const hubConnection = new HubConnectionBuilder()
            .withUrl('jobUpdateHub')
            .configureLogging(LogLevel.Error)
            .withAutomaticReconnect()
            .build();

        hubConnection.on('JobCreated', this.onJobCreatedFromHub);
        hubConnection.on('JobUpdated', this.onJobUpdatedFromHub);
        hubConnection.on(
            'JobCancellationUpdated',
            this.onJobCancellationUpdatedFromHub,
        );
        hubConnection.on('JobsUpdatedBulk', this.onJobsUpdatedBulkFromHub);
        hubConnection.on(
            'StaffRelatedDataUpdated',
            this.onStaffRelatedDataUpdatedFromHub,
        );

        hubConnection
            .start()
            .then((result) => {
                hubConnection.invoke('JoinGroup', 'Dispatch');
                // console.log('Job Update Hub Connected');
                this.setState({ hubConnection });
            })
            .catch((e) => console.log('Job Update Hub Connection failed: ', e));
    };

    // Equipment assignment validation
    canEquipmentBeAssigned = (
        sourceData,
        allAssignmentsForTheDayAndJob,
        targetAssignmentData,
        job,
        assignment,
        assignmentsForAllJobsForTheDay,
    ) => {
        const dayJobAssignmentExists = allAssignmentsForTheDayAndJob.find(
            (g) => !!g.assignment && g.assignment.id === sourceData.id,
        );

        if (dayJobAssignmentExists) {
            toasty.error(
                'Assignment Invalid',
                `${sourceData.description} has already been scheduled for the selected time slot.`,
            );
            return false;
        }
        // Addresses #1783
        // If not assigned to the target job, warn if the staff member is assigned to another job for the same day.
        const anyAssignmentExistsForDayAndJob = !!(
            assignmentsForAllJobsForTheDay.filter(
                (a) => !!a.assignment && a.assignment.id === sourceData.id,
            ) ?? []
        ).length;

        if (anyAssignmentExistsForDayAndJob) {
            const warning = `Warning: [${sourceData.equipmentType.description}] ${sourceData.identifier} is assigned to more than one job.  Please review the assignments and ensure is this correct.`;
            toasty.warning('Equipment Assigned To Multiple Jobs', warning, {
                autoClose: false,
                closeOnClick: true,
            });
        }

        // Other rules

        // const equipmentName = sourceData.name || sourceData.description;

        // Does the dragged item's equipment type match one of the required equipment types of the assignment?
        if (
            !targetAssignmentData.requiredEquipment
                .map((e) => e.id)
                .includes(sourceData.equipmentTypeId)
        ) {
            toasty.error(
                'Assignment Invalid',
                `${sourceData.description} (${
                    sourceData.equipmentType.description
                }) does not belong to equipment type: ${targetAssignmentData.requiredEquipment
                    .map((e) => e.description)
                    .join(', ')}.`,
            );
            return false; // invalid drop target.  no need to visually display anything to the user.
        }

        // todo: is this an assignable equipment type

        const missingComplianceMessage = this.getMissingEquipmentComplianceMessage(
            sourceData,
            job,
            targetAssignmentData,
            assignment,
        );

        if (missingComplianceMessage) {
            toasty.error('Assignment Invalid', missingComplianceMessage);
            return false;
        }

        return true;
    };

    // Staff assignment validation
    canStaffBeAssigned = (
        sourceData,
        allAssignmentsForTheDayAndJob,
        targetAssignmentData,
        job,
        assignment,
        assignmentsForAllJobsForTheDay,
    ) => {
        const {
            longTermStatuses,
            employeeLongTermStatuses,
            dailyAttendanceEvents,
            employeeDailyAttendanceEvents,
        } = this.state;

        const dayJobAssignmentExists = allAssignmentsForTheDayAndJob.find(
            (g) => !!g.assignment && g.assignment.id === sourceData.id,
        );

        if (dayJobAssignmentExists) {
            toasty.error(
                'Assignment Invalid',
                `${sourceData.name} has already been scheduled for the selected time slot.`,
            );
            return false;
        }
        // Addresses #1783
        // If not assigned to the target job, warn if the staff member is assigned to another job for the same day.
        const anyAssignmentExistsForDayAndJob = !!(
            assignmentsForAllJobsForTheDay.filter(
                (a) => !!a.assignment && a.assignment.id === sourceData.id,
            ) ?? []
        ).length;

        if (anyAssignmentExistsForDayAndJob) {
            const warning = `Warning: ${sourceData.name} is assigned to more than one job.  Please review the assignments and confirm is this correct.`;
            toasty.warning('Employee Assigned To Multiple Jobs', warning, {
                autoClose: false,
                closeOnClick: true,
            });
        }

        // Other rules

        // Does the dragged item's employee group match one of the required groups?
        if (
            !_.intersection(
                targetAssignmentData.assignableGroups.map((e) => e.id),
                sourceData.groups.map((g) => g.id),
            ).length
        ) {
            toasty.error(
                'Assignment Invalid',
                `${
                    sourceData.name
                } does not belong to roles: ${targetAssignmentData.assignableGroups
                    .map((e) => e.groupName)
                    .join(', ')}`,
            );
            return false; // invalid drop target.  no need to visually display anything to the user.
        }

        const missingComplianceMessage = this.getMissingUserComplianceMessage(
            sourceData,
            job,
            targetAssignmentData,
            assignment,
        );

        if (missingComplianceMessage) {
            toasty.error('Assignment Invalid', missingComplianceMessage);
            return false;
        }

        const longTermStatusRestrictionMessage = this.getLongTermStatusRestrictionMessage(
            sourceData,
            assignment,
            longTermStatuses,
            employeeLongTermStatuses,
        );

        if (longTermStatusRestrictionMessage) {
            toasty.error(
                'Assignment Can Not Be Made',
                longTermStatusRestrictionMessage,
            );
            return false;
        }

        const dailyAttendanceEventRestrictionMessage = this.getDailyAttendanceEventRestrictionMessage(
            sourceData,
            assignment,
            dailyAttendanceEvents,
            employeeDailyAttendanceEvents,
        );

        if (dailyAttendanceEventRestrictionMessage) {
            toasty.error(
                'Assignment Can Not Be Made',
                dailyAttendanceEventRestrictionMessage,
            );
            return false;
        }

        // todo: is this an assignable group

        return true;
    };

    complianceIsUpToDate = (entity, complianceType, assignment) => {
        const assignmentDate = new Date(assignment.assignmentDate);

        return Boolean(
            entity.compliances.find(
                (sc) => sc.complianceTypeId == complianceType.id
                    && moment(assignmentDate).isBetween(
                        sc.effectiveDate,
                        sc.expirationDate || '2099-12-31',
                    ),
            ),
        );
    };

    doesJobHaveCrewLead = (job, day) => {
        const result = !!job.assignments[day].staff.filter((x) => !!x.isCrewLead)
            .length;
        return result;
    };

    // Accept the job if the jobSearchText is found in variety of places.
    doesJobPassSearchText = (job) => {
        const { jobSearchText } = this.state;

        const jst = (jobSearchText ?? '').trim();

        // No job search text - accept the job.
        if (jst === '') return true;

        const r = new RegExp(jst, 'i');

        // Search the following:
        //  Work Order Number
        //  Contract Number + Sequence ('number' property: 444ABC-3)
        //  Contract Company Name
        //  Foreman (first/last name)
        //  Job Description (contained in 'name')
        //  Subcontractor Company (contained in 'name')
        //  Assigned employee name (in assignments)
        const isFound = r.test(job.name)
            || r.test(job.foremanName)
            || r.test(job.number)
            || r.test(job.workOrderNumber)
            || r.test(job.contractCompanyName)
            || this.isStaffAssigned(job, jst);

        return isFound;
    };

    // #endregion

    // #region JOBS

    // Accept job if it has staff/equipment assignments in one of the selected days of the week.
    doesJobPassWeek = (job) => {
        const { selectedWeekRangeDays } = this.state;

        // index 0: day of the week (0-7), 1: the assignment.
        const entries = Object.entries(job.assignments);
        for (const entry of entries) {
            const a = entry[1];

            if (a.staff.length > 0 || a.equipment.length > 0) {
                const compDay = parseInt(entry[0]);

                if (selectedWeekRangeDays.find((d) => d.getDay() === compDay)) return true;
            }
        }

        return false;
    };

    equipmentShouldBeHidden = (equipment) => {
        const { onlyShowUnassignedEquipment, allJobs, selectedWeekRangeDays } = this.state;

        if (
            !onlyShowUnassignedEquipment
            || selectedWeekRangeDays.length !== 1
        ) {
            return false;
        }

        const selectedDate = selectedWeekRangeDays[0];

        let hideEquipment = false;

        allJobs.forEach((j) => Object.values(j.assignments).forEach((a) => {
            if (
                a.isCancelled
                    || !moment(selectedDate).isSame(moment(a.date), 'day')
                    || !a.equipment
            ) {
                return;
            }

            Object.values(a.equipment).forEach((equipmentOpening) => {
                if (
                    equipmentOpening.assignment
                        && equipmentOpening.assignment.id == equipment.id
                ) {
                    hideEquipment = true;
                }
            });
        }));

        return hideEquipment;
    };

    handleCancellationJobSearchText = async (value) => {
        this.setState({ cancellationJobSearchText: value });
        this.getCancellations();
    };

    handleJobSearchText = async (value) => {
        this.setState({ jobSearchText: value });
        this.getJobs();
    };

    handleResourceSearchText = async (value) => {
        const { activeResourceTab } = { ...this.state };
        this.setState({ resourceSearchText: value });
        switch (activeResourceTab.number) {
        case DispatchResourceTabNumbers.Staff:
            this.getStaff();
            break;
        case DispatchResourceTabNumbers.Equipment:
            this.getEquipment();
            break;
        case DispatchResourceTabNumbers.Crews:
            return false; // not yet implemented
        default:
            break;
        }
    };

    hideDisplayDueToLongTermStatus = (staff) => {
        const { longTermStatuses, employeeLongTermStatuses } = this.state;

        if (!staff) {
            return true;
        }

        if (
            !longTermStatuses
            || !longTermStatuses.length
            || !employeeLongTermStatuses
            || !employeeLongTermStatuses.length
        ) {
            return false;
        }

        const employeeLongTermStatus = employeeLongTermStatuses.find(
            (elts) => elts.employeeId == staff.id,
        );

        if (!employeeLongTermStatus) {
            return false;
        }

        const longTermStatus = longTermStatuses.find(
            (lts) => employeeLongTermStatus.longTermStatusId == lts.id,
        );

        return longTermStatus && longTermStatus.hideFromDispatchBoard;
    };

    hideRestrictedStaff = (staff) => {
        if (!staff || !staff.rows) {
            return;
        }

        const staffCount = staff.rows.length;
        let { hiddenEmployeeCount } = this.state;

        staff.rows = staff.rows.filter(
            (s) => !this.hideDisplayDueToLongTermStatus(s),
        );

        hiddenEmployeeCount += staffCount - staff.rows.length;

        this.setState({ hiddenEmployeeCount });
    };

    isComplianceEnabled = () => {
        const { tenantSettings } = this.state;
        return Boolean(tenantSettings.complianceEnabled);
    };

    isComplianceNeededForEquipmentType = (compliance, targetAssignmentData) => {
        const complianceEquipmentTypeIds = compliance.complianceEquipmentTypes.map(
            (cet) => cet.equipmentTypeId,
        );

        return (
            compliance
            && targetAssignmentData.requiredEquipment
            && (complianceEquipmentTypeIds.includes(
                targetAssignmentData.requiredEquipment.id,
            )
                || targetAssignmentData.requiredEquipment.some((re) => complianceEquipmentTypeIds.includes(re.id)))
        );
    };

    isComplianceNeededForGroupType = (
        staff,
        compliance,
        targetAssignmentData,
    ) => {
        const complianceGroupIds = compliance.complianceApplicationUserGroups.map(
            (caug) => caug.applicationUserGroupId,
        );
        const staffGroupIds = staff.groups.map((g) => g.id);

        return (
            compliance
            && targetAssignmentData.assignableGroups
            && targetAssignmentData.assignableGroups.some(
                (ag) => complianceGroupIds.includes(ag.id)
                    && staffGroupIds.includes(ag.id),
            )
        );
    };

    isEmployeeCompliance = (compliance) => (
        compliance
            && compliance.complianceApplicationUserGroups
            && compliance.complianceApplicationUserGroups.length > 0
    );

    isEquipmentCompliance = (compliance) => (
        compliance
            && compliance.complianceEquipmentTypes
            && compliance.complianceEquipmentTypes.length > 0
    );

    isFilteredPlanningJob = (job) => {
        if (!job || !job.assignments) return false;

        const doesPass = this.doesJobPassWeek(job) && this.doesJobPassSearchText(job);

        return doesPass;
    };

    isStaffAssigned = (job, searchTerm) => {
    // searchTerm: "doe"
        const r = new RegExp(searchTerm, 'i');

        const nameSet = this.getNameSet(job);

        const nameArray = Array.from(nameSet);
        const isFound = nameArray.find((n) => r.test(n));

        return isFound;
    };

    populateState = async () => {
        const isAuthenticated = await authService.isAuthenticated();
        if (isAuthenticated) {
            this.setupPushNotifications();

            const preferredDispatch = this.getPreferredDispatch();

            let [
                strings,
                vm,
                jobAssignmentCancellationTypes,
                jobCancellationTypes,
                user,
                complianceTypes,
                longTermStatuses,
                dailyAttendanceEvents,
                tenantTenantSettings,
            ] = await Promise.all([
                util.l10n.getStrings([l.crewLead]),
                util.fetch.js(ApiRoutes.dispatch.viewModel()),
                util.fetch.js(ApiRoutes.jobAssignmentCancellationType.all()),
                util.fetch.js(ApiRoutes.typeAheads.jobCancellationTypes()),
                util.fetch.js(ApiRoutes.auth.profile()),
                util.fetch.js(ApiRoutes.complianceTypes.all()),
                util.fetch.js(ApiRoutes.longTermStatus.all()),
                util.fetch.js(ApiRoutes.dailyAttendanceEvent.all()),
                util.fetch.js(ApiRoutes.tenant.settings()),
            ]);

            const tenantSettings = tenantTenantSettings && tenantTenantSettings.tenantSettings
                ? tenantTenantSettings.tenantSettings
                : {};

            const areAttendanceEventsEnabled = tenantSettings.attendanceEventsEnabled;

            if (!areAttendanceEventsEnabled) {
                dailyAttendanceEvents = [];
            }

            // var tomorrow = new Date();

            /// /RLC: Added to resolve issue with time rolling over to next day
            // tomorrow.setHours(0, 0, 0, 0);

            // tomorrow.setDate(tomorrow.getDate() + 1);

            let selectedWeek = { ...vm.weeks.find((x) => !!x.current) };

            const m_WeeksEnd = moment(selectedWeek.end);

            // pulling tomorrow from VM instead of using the above.
            const m_Tomorrow = moment(vm.tomorrow);

            // since we default them to tomorrow, we need to see if we must
            // flip to next week in the Weeks Dropdown.
            if (m_Tomorrow.isAfter(m_WeeksEnd)) {
                selectedWeek = {
                    ...vm.weeks.find((w) => moment(w.start).isSame(m_Tomorrow)),
                };
            }

            const firstDispatchResult = vm.dispatchLocations[0].value;
            const defaultDispatch = this.context?.user?.isAdmin
                ? preferredDispatch ?? firstDispatchResult
                : user.dispatchCompanyId ?? firstDispatchResult;

            const jobAssignmentCancellationTypeIdsThatPreventReassignment = getJobAssignmentCancellationTypeIdsThatPreventReassignment(
                jobAssignmentCancellationTypes,
            );

            await this.setState(
                {
                    allJobs: [],
                    activeResourceTab: { ...DispatchResourceTabs[0] },
                    dispatchLocations: vm.dispatchLocations,
                    jobAssignmentCancellationTypes,
                    jobCancellationTypes,
                    jobsSaving: [],
                    employeeGroups: vm.employeeGroups,
                    equipmentTypes: vm.equipmentTypes,
                    isAuthenticated,
                    isSavingJobStatus: false,
                    isCancellingJob: false,
                    isCancellingAssignment: false,
                    isCallingOutAssignment: false,
                    weeks: vm.weeks,
                    preferredDispatchLocation: preferredDispatch,
                    selectedResourceDispatchLocation: defaultDispatch,
                    selectedJobDispatchLocation: defaultDispatch,
                    selectedWeekRangeDays: [new Date(vm.tomorrow)], // default to tomorrow
                    selectedWeek,
                    complianceTypes,
                    strings,
                    longTermStatuses,
                    dailyAttendanceEvents,
                    areAttendanceEventsEnabled,
                    tenantSettings,
                    jobAssignmentCancellationTypeIdsThatPreventReassignment,
                },
                async () => {
                    await Promise.all([
                        this.getResources(),
                        this.getJobs(),
                        this.getCancellations(),
                        this.getEmployeeDailyAttendanceEvents(),
                        this.getEmployeeLongTermStatuses(),
                    ]);

                    await this.setState({ loading: false });
                },
            );
        }
    };

    preferredDispatch = (id) => {
        if (!id) {
            localStorage.setItem('preferredDispatchLocation', '');
            this.setState({ preferredDispatchLocation: null });
        } else {
            localStorage.setItem('preferredDispatchLocation', id.toString());
            this.setState({ preferredDispatchLocation: id });
        }
    };

    removeAllJobAssignments = async (ids) => {
        await util.fetch.post(ApiRoutes.dispatch.removeAllAssignments(), ids);
    };

    removeAssignment = async (jobAssignment) => {
        await util.fetch.delete(
            ApiRoutes.dispatch.removeAssignment(jobAssignment.jobAssignmentId),
        );
    };

    saveAssignment = async (assignment) => {
    // Don't allow multiple saves concurrently
        if (!this.state.jobsSaving.includes(assignment.jobId)) {
            await this.setJobSaving(assignment.jobId);

            await util.fetch.andGetResponse(
                util.fetch.types.post,
                ApiRoutes.dispatch.saveAssignment(),
                { ...assignment },
                'Server Error Saving Job Assignment',
            ).finally(async () => await this.setJobNotSaving(assignment.jobId));

            await this.getEmployeeDailyAttendanceEvents();
        }
    };

    selectedJobAssignmentCancellationTypePreventsReassignment = () => {
        const {
            selectedJobAssignmentCancellationType,
            jobAssignmentCancellationTypes,
        } = this.state;

        if (!selectedJobAssignmentCancellationType) {
            return false;
        }

        const jobAssignmentCancellationType = jobAssignmentCancellationTypes.find(
            (jact) => jact.id == selectedJobAssignmentCancellationType,
        );

        return Boolean(
            jobAssignmentCancellationType
                && jobAssignmentCancellationType.preventsReassignment,
        );
    };

    staffShouldBeHidden = (staff) => {
        const {
            onlyShowAttendance,
            onlyShowUnassigned,
            onlyShowCancelled,
            employeeDailyAttendanceEvents,
            employeeLongTermStatuses,
            allJobs,
            selectedWeekRangeDays,
            cancellationJobs,
        } = this.state;

        const selectedDate = selectedWeekRangeDays[0];

        if (
            (!onlyShowAttendance
                && !onlyShowUnassigned
                && !onlyShowCancelled)
            || selectedWeekRangeDays.length !== 1
        ) {
            return false;
        }

        if (onlyShowAttendance) {
            return !employeeDailyAttendanceEvents.find(
                (eda) => eda.employeeId == staff.id,
            );
        }

        if (onlyShowCancelled) {
            let foundMatch = false;

            cancellationJobs.forEach((j) => Object.values(j.assignments).forEach((a) => {
                Object.values(a.cancelledAssignments).forEach((c) => {
                    if (!foundMatch) {
                        foundMatch = c.assignedEmployeeId == staff.id
                                && (this.treatCancelAsUnassigned(c)
                                    || this.treatCancelAsCancelled(c))
                                && moment(c.assignmentDate).isSame(
                                    selectedDate,
                                    'day',
                                );
                    }
                });
            }));

            return !foundMatch;
        }

        if (onlyShowUnassigned) {
            if (
                employeeDailyAttendanceEvents.find(
                    (eda) => eda.employeeId == staff.id,
                )
                || employeeLongTermStatuses.find(
                    (elts) => elts.employeeId == staff.id,
                )
            ) {
                return true;
            }

            let hideEmployee = false;

            allJobs.forEach((j) => Object.values(j.assignments).forEach((a) => {
                if (
                    a.isCancelled
                        || !moment(selectedDate).isSame(moment(a.date), 'day')
                        || !a.staff
                ) {
                    return;
                }

                Object.values(a.staff).forEach((staffOpening) => {
                    if (
                        staffOpening.assignment
                            && staffOpening.assignment.id == staff.id
                    ) {
                        hideEmployee = true;
                    }
                });
            }));

            // return hideEmployee;

            if (hideEmployee) return true;

            // If employee still showing, check for cancellations
            let foundCancel = false;

            cancellationJobs.forEach((j) => Object.values(j.assignments).forEach((a) => {
                Object.values(a.cancelledAssignments).forEach((c) => {
                    if (!foundCancel) {
                        foundCancel = c.assignedEmployeeId == staff.id
                                && this.treatCancelAsCancelled(c)
                                && moment(c.assignmentDate).isSame(
                                    selectedDate,
                                    'day',
                                );
                    }
                });
            }));

            return foundCancel;
        }

        return false;
    };

    toContactDropdownOption = (locationContact) => {
        const { id } = locationContact;

        const contactName = locationContact.title
            ? `${locationContact.lastName}, ${locationContact.firstName} (${locationContact.title})`
            : `${locationContact.lastName}, ${locationContact.firstName}`;

        return {
            id,
            contactName,
        };
    };

    toggleCancelAssignmentModal = () => {
        const { showCancelAssignmentModal } = this.state;
        this.setState({
            showCancelAssignmentModal: !showCancelAssignmentModal,
        });
    };

    toggleCancelJobModal = () => {
        const { showCancelJobModal } = this.state;
        this.setState({ showCancelJobModal: !showCancelJobModal });
    };

    toggleCancellationJobCollapse = (jobId, collapsed) => {
        let { cancellationJobsOpened } = { ...this.state };

        if (collapsed) {
            cancellationJobsOpened = cancellationJobsOpened.filter(
                (x) => x !== jobId,
            );
        } else {
            cancellationJobsOpened = util.array.insertIfNotExists(
                cancellationJobsOpened,
                jobId,
            );
        }

        this.setState({ cancellationJobsOpened });
    };

    toggleEmergencyConfirmModal = () => {
        const { showEmergencyConfirmModal, isCallingOutAssignment } = this.state;

        if (showEmergencyConfirmModal == false) this.isCallingOutAssignment = false;

        this.setState({
            showEmergencyConfirmModal: !showEmergencyConfirmModal,
            isCallingOutAssignment,
        });
    };

    toggleJobCollapse = (jobId, collapsed) => {
        let { jobsOpened } = { ...this.state };

        if (collapsed) jobsOpened = jobsOpened.filter((x) => x !== jobId);
        else jobsOpened = util.array.insertIfNotExists(jobsOpened, jobId);

        this.setState({ jobsOpened });
    };

    toggleModal = () => {
        const { showModal } = this.state;
        this.setState({ showModal: !showModal });
    };

    treatCancelAsCancelled = (employeeAssignment) => {
        const { jobAssignmentCancellationTypes } = this.state;

        return isCancelAssignmentTreatedAsCancelled(
            employeeAssignment,
            jobAssignmentCancellationTypes,
        );
    };

    treatCancelAsUnassigned = (employeeAssignment) => {
        const { jobAssignmentCancellationTypes } = this.state;

        return isCancelAssignmentTreatedAsUnassigned(
            employeeAssignment,
            jobAssignmentCancellationTypes,
        );
    };

    updateEmployeeDailyAttendanceEvent = async (
        employeeId,
        dailyAttendanceEventId,
    ) => {
        const { selectedWeekRangeDays } = this.state;

        const eventDate = moment(selectedWeekRangeDays[0]).toDate();

        const postData = {
            employeeId,
            dailyAttendanceEventId,
            eventDate,
        };

        if (dailyAttendanceEventId == 0) {
            await util.fetch.andGetResponse(
                util.fetch.types.post,
                ApiRoutes.employeeDailyAttendanceEvent.delete(),
                postData,
                'Error Removing Daily Attendance Event',
                null,
                true,
            );
        } else {
            await util.fetch.andGetResponse(
                util.fetch.types.post,
                ApiRoutes.employeeDailyAttendanceEvent.upsert(),
                postData,
                'Error Updating Daily Attendance Event',
                null,
                true,
            );
        }

        await this.getEmployeeDailyAttendanceEvents();
    };

    updateEquipmentAssignedEmployee = async (jobAssignmentId, employeeId) => {
        await util.fetch.js(
            ApiRoutes.dispatch.setEquipmentAssignedEmployee(
                jobAssignmentId,
                employeeId,
            ),
        );
    };

    updateEquipmentAssignedEquipment = async (jobAssignmentId, equipmentId) => {
        await util.fetch.js(
            ApiRoutes.dispatch.setEquipmentAssignedEquipment(
                jobAssignmentId,
                equipmentId,
            ),
        );
    };

    // #endregion

    render() {
        const {
            activeJobTab,
            activeResourceTab,
            allJobsCollapsed,
            allCancellationJobsCollapsed,
            // crews,
            crewsInterfaceOpen,
            dispatchLocations,
            jobAssignmentCancellationTypes,
            jobCancellationTypes,
            jobsOpened,
            jobsSaving,
            jobSearchText,
            cancellationJobSearchText,
            loading,
            // dragging,
            equipment,
            isAuthenticated,
            isSavingJobStatus,
            isCancellingJob,
            isCancellingAssignment,
            isCallingOutAssignment,
            allJobs,
            planningJobs,
            planningJobsFilteredCount,
            planningJobsMaxRows,
            cancellationJobs,
            cancellationJobsOpened,
            cancellationJobsFilteredCount,
            cancellationJobsMaxRows,
            resourceSearchText,
            selectedEmergencyAssignments,
            selectedWorkflowJob,
            selectedDayJobToRemove,
            selectedJobDispatchLocation,
            selectedResourceDispatchLocation,
            selectedJobAssignmentToRemove,
            selectedJobAssignmentCancellationStartTime,
            selectedJobAssignmentCancellationType,
            selectedJobAssignmentCancellationNotes,
            selectedJobAssignmentCancellationTime,
            selectedJobCancellationType,
            selectedJobCancellationNotes,
            selectedJobCancellationTime,
            selectedStaffSort,
            selectedWeek,
            selectedWeekRangeDays,
            staff,
            staffSortDropdownOpen,
            weeks,
            noResponse,
            showModal,
            showCancelAssignmentModal,
            showCancelJobModal,
            showEmergencyConfirmModal,
            strings,
            longTermStatuses,
            employeeLongTermStatuses,
            dailyAttendanceEvents,
            staffLoading,
            areAttendanceEventsEnabled,
            employeeDailyAttendanceEvents,
            hiddenEmployeeCount,
            onlyShowAttendance,
            onlyShowUnassigned,
            onlyShowCancelled,
            tenantSettings,
            equipmentLoading,
            onlyShowUnassignedEquipment,
            resourcesLoading,
            jobAssignmentCancellationTypeIdsThatPreventReassignment,
            expandedCancellationReasonRequired,
            companyContacts,
            cancelJobDataLoading,
            cancelContactId,
        } = { ...this.state };

        const staffTabActive = activeResourceTab.number === DispatchResourceTabNumbers.Staff;
        const equipmentTabActive = activeResourceTab.number === DispatchResourceTabNumbers.Equipment;

        const isCancelJobSaveDisabled = this.getIsCancelJobSaveDisabled();

        let hiddenResourceText = hiddenEmployeeCount > 0
            ? ` ${hiddenEmployeeCount} employees hidden due to Long-Term Status.`
            : '';

        const hiddenStaffIds = this.getHiddenStaffIds();

        if (onlyShowAttendance || onlyShowUnassigned) {
            const hiddenStaffCount = hiddenStaffIds.length;
            const filterName = onlyShowAttendance ? 'Attendance' : 'Unassigned';

            hiddenResourceText
                += hiddenStaffCount > 0
                    ? `${hiddenStaffCount} employees hidden due to ${filterName} filter.`
                    : '';
        }

        const hiddenEquipmentIds = this.getHiddenEquipmentIds();

        if (equipmentTabActive) {
            const hiddenEquipmentCount = hiddenEquipmentIds.length;

            hiddenResourceText = hiddenEquipmentCount > 0
                ? `${hiddenEquipmentCount} pieces of equipment hidden due to Unassigned filter.`
                : '';
        }

        // #region COMPUTED
        if (
            !isAuthenticated
            || !(this.context.tenant ?? {}).tenantSettings
            || !!loading
            || !selectedJobDispatchLocation
            || !selectedResourceDispatchLocation
        ) return <Progress />;

        const { crewsEnabled } = tenantSettings;
        const hoursEnabled = tenantSettings.dispatchHoursEnabled;

        const daysInRange = !selectedWeek
            ? null
            : util.date.getDaysInRange(
                moment(selectedWeek.startDisplay, 'L-d-YYYY').toDate(),
                moment(selectedWeek.endDisplay, 'L-d-YYYY').toDate(),
            );
        const currentResource = this.getCurrentResource();
        const planningBoard = this.getPlanningBoard();
        const cancellationBoard = this.getCancellationBoard();
        // opsBoard = this.getOpsBoard();

        const firstWeekSelected = !!weeks.length
            && weeks.map((x) => x.start).indexOf(selectedWeek.start) === 0;
        const lastWeekSelected = !!weeks.length
            && weeks.map((x) => x.start).indexOf(selectedWeek.start) + 1
                === weeks.length;

        const oneDaySelected = (selectedWeekRangeDays ?? []).length === 1;
        const todaySelectedOrLater = !!oneDaySelected
            && !!moment(selectedWeekRangeDays[0]).isSameOrAfter(
                moment(new Date()),
                'day',
            );
        const jobDispatchLocation = (dispatchLocations ?? []).find(
            (x) => x.value === selectedJobDispatchLocation,
        ) ?? '';
        const resourceDispatchLocation = (dispatchLocations ?? []).find(
            (x) => x.value === selectedResourceDispatchLocation,
        ) ?? '';
        const showResourceDispatchSelection = !!this.context?.user?.isAdmin
            && !!tenantSettings.allowCrossDispatching;

        const showAttendance = !(
            loading
            || staffLoading
            || resourcesLoading
            || !staffTabActive
            || !areAttendanceEventsEnabled
            || !dailyAttendanceEvents
            || !dailyAttendanceEvents.length
            || !selectedWeekRangeDays
            || selectedWeekRangeDays.length != 1
        );

        // #endregion

        return (
            <>
                <FormBlocker show={!!this.context.formIsOpen} />
                <div className="p-2 dispatch-board">
                    <small
                        className="text-success mb-1 w-100 text-right"
                        hidden={!this.state.lastPushUpdate}
                    >
                        {this.state.lastPushUpdate}
                    </small>
                    <DragDropContext
                        onDragStart={this.onDragStart}
                        onDragEnd={this.onDragEnd}
                        onBeforeCapture={this.onBeforeDragCapture}
                    >
                        <div
                            id="dispatchWrapper"
                            className={cls({
                                'crews-open': crewsInterfaceOpen,
                            })}
                        >
                            {!!crewsEnabled && (
                                <div id="crewsPane">
                                    <legend>
                                        <FontAwesomeIcon
                                            title="Close Crews"
                                            icon={faUsers}
                                            className="mr-2"
                                        />
                  Crews
                                    </legend>
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        size="1x"
                                        id="crewsClose"
                                        onClick={() => this.setState({
                                            crewsInterfaceOpen: false,
                                        })}
                                    />
                                    <div id="dispatchLeftContentHeader" />
                                    <div id="dispatchLeftContent" />
                                </div>
                            )}
                            <Split
                                id="dispatchPane"
                                className="dispatchPane"
                                sizes={[60, 40]}
                                minSize={[324, 300]}
                                expandToMin={false}
                                snapOffset={30}
                                dragInterval={1}
                                direction="horizontal"
                                cursor="col-resize"
                                elementStyle={SplitHelper.flex.elementStyle}
                            >
                                <div
                                    id="dispatchLeft"
                                    className={cls({
                                        'pointer-events-none':
                                            !!this.state.resourcesLoading,
                                        max80: showAttendance,
                                        max100: !showAttendance,
                                    })}
                                >
                                    <legend className="d-flex flex-row align-items-center">
                    RESOURCES
                                        <div
                                            className={
                                                showResourceDispatchSelection
                                                    ? 'd-none'
                                                    : 'd-flex flex-row align-items-center'
                                            }
                                        >
                                            &nbsp;&nbsp;//&nbsp;&nbsp;
                                            <span className="p-0 m-0 text-muted">
                                                {
                                                    resourceDispatchLocation?.label
                                                }
                                            </span>
                                        </div>
                                    </legend>
                                    {!!loading && <CustomCircularProgress />}
                                    {!loading && (
                                        <>
                                            <div id="dispatchCenterContentHeader">
                                                <div className="week-selection">
                                                    <FormGroup>
                                                        <label>Week</label>
                                                        <InputGroup id="weekRange">
                                                            <Button
                                                                size="sm"
                                                                type="button"
                                                                onClick={
                                                                    this
                                                                        .onPreviousWeekClicked
                                                                }
                                                                disabled={
                                                                    !!firstWeekSelected
                                                                }
                                                                style={{
                                                                    zIndex: 0,
                                                                }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faAngleLeft
                                                                    }
                                                                />
                                                            </Button>
                                                            <Select
                                                                styles={
                                                                    CompactSelectStyles
                                                                }
                                                                isClearable={
                                                                    false
                                                                }
                                                                className="react-select"
                                                                options={weeks}
                                                                getOptionLabel={(
                                                                    option,
                                                                ) => option.range}
                                                                getOptionValue={(
                                                                    option,
                                                                ) => option}
                                                                value={
                                                                    selectedWeek
                                                                    ?? ''
                                                                }
                                                                onChange={
                                                                    this
                                                                        .onCurrentWeekChanged
                                                                }
                                                            />

                                                            <Button
                                                                size="sm"
                                                                type="button"
                                                                onClick={
                                                                    this
                                                                        .onNextWeekClicked
                                                                }
                                                                disabled={
                                                                    !!lastWeekSelected
                                                                }
                                                                style={{
                                                                    zIndex: 0,
                                                                }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faAngleRight
                                                                    }
                                                                />
                                                            </Button>
                                                        </InputGroup>
                                                    </FormGroup>
                                                </div>
                                                <div className="weekday-selection">
                                                    {!!selectedWeek
                                                        && !!(daysInRange ?? [])
                                                            .length && (
                                                        <FormGroup>
                                                            <label>
                                                                Day
                                                            </label>
                                                            <ButtonGroup
                                                                id="dispatchWeekdaySelection"
                                                                className=""
                                                                size="sm"
                                                            >
                                                                {daysInRange.map(
                                                                    (
                                                                        dayDate,
                                                                    ) => {
                                                                        const day = Weekdays.find(
                                                                            (
                                                                                x,
                                                                            ) => x.id
                                                                                        === dayDate.getDay(),
                                                                        );
                                                                        return (
                                                                            <Button
                                                                                key={
                                                                                    day.id
                                                                                }
                                                                                type="button"
                                                                                color="outline-secondary"
                                                                                onClick={() => {
                                                                                    this.onSelectedWeekRangeDayClicked(
                                                                                        dayDate,
                                                                                    );
                                                                                }}
                                                                                className={cls(
                                                                                    {
                                                                                        active: (
                                                                                            selectedWeekRangeDays
                                                                                                ?? []
                                                                                        )
                                                                                            .map(
                                                                                                (
                                                                                                    d,
                                                                                                ) => d.toLocaleDateString(),
                                                                                            )
                                                                                            .includes(
                                                                                                dayDate.toLocaleDateString(),
                                                                                            ),
                                                                                    },
                                                                                )}
                                                                            >
                                                                                <b>
                                                                                    {
                                                                                        Weekdays[
                                                                                            day
                                                                                                .id
                                                                                        ]
                                                                                            .abbreviationShort
                                                                                    }
                                                                                </b>
                                                                            </Button>
                                                                        );
                                                                    },
                                                                )}
                                                            </ButtonGroup>
                                                        </FormGroup>
                                                    )}
                                                </div>
                                                <DispatchEmployeeFilter
                                                    dailyAttendanceEvents={
                                                        dailyAttendanceEvents
                                                    }
                                                    employeeDailyAttendanceEvents={
                                                        employeeDailyAttendanceEvents
                                                    }
                                                    selectedWeekRangeDays={
                                                        selectedWeekRangeDays
                                                    }
                                                    loading={loading}
                                                    staffLoading={staffLoading}
                                                    areAttendanceEventsEnabled={
                                                        areAttendanceEventsEnabled
                                                    }
                                                    staff={staff}
                                                    allJobs={allJobs}
                                                    employeeLongTermStatuses={
                                                        employeeLongTermStatuses
                                                    }
                                                    onlyShowAttendance={
                                                        onlyShowAttendance
                                                    }
                                                    onlyShowUnassigned={
                                                        onlyShowUnassigned
                                                    }
                                                    onlyShowCancelled={
                                                        onlyShowCancelled
                                                    }
                                                    onAttendanceClick={
                                                        this.onAttendanceClick
                                                    }
                                                    onUnassignedClick={
                                                        this.onUnassignedClick
                                                    }
                                                    onCancelledClick={
                                                        this.onCancelledClick
                                                    }
                                                    staffTabActive={
                                                        staffTabActive
                                                    }
                                                    tenantSettings={
                                                        tenantSettings
                                                    }
                                                    resourcesLoading={
                                                        resourcesLoading
                                                    }
                                                    cancellationJobs={
                                                        cancellationJobs
                                                    }
                                                    jobAssignmentCancellationTypes={
                                                        jobAssignmentCancellationTypes
                                                    }
                                                />
                                                <DispatchEquipmentFilter
                                                    selectedWeekRangeDays={
                                                        selectedWeekRangeDays
                                                    }
                                                    loading={loading}
                                                    resourcesLoading={
                                                        resourcesLoading
                                                    }
                                                    equipmentLoading={
                                                        equipmentLoading
                                                    }
                                                    allJobs={allJobs}
                                                    equipment={equipment}
                                                    onUnassignedEquipmentClick={
                                                        this
                                                            .onUnassignedEquipmentClick
                                                    }
                                                    onlyShowUnassignedEquipment={
                                                        onlyShowUnassignedEquipment
                                                    }
                                                    equipmentTabActive={
                                                        equipmentTabActive
                                                    }
                                                    tenantSettings={
                                                        tenantSettings
                                                    }
                                                />
                                                <div className="dispatch-location">
                                                    <FormGroup
                                                        className={
                                                            showResourceDispatchSelection
                                                                ? ''
                                                                : 'd-none'
                                                        }
                                                    >
                                                        <label>
                            Dispatching
                                                        </label>
                                                        <Select
                                                            placeholder="Select Dispatching"
                                                            id="selectedResourceDispatchLocation"
                                                            name="selectedResourceDispatchLocation"
                                                            styles={
                                                                CompactSelectStyles
                                                            }
                                                            isClearable={false}
                                                            className="react-select"
                                                            options={
                                                                dispatchLocations
                                                            }
                                                            value={
                                                                resourceDispatchLocation
                                                            }
                                                            onChange={
                                                                this
                                                                    .onSelectedResourceDispatchLocationChanged
                                                            }
                                                        />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div id="dispatchCenterToolbar">
                                                <Nav
                                                    id="dispatchResourceTabs"
                                                    tabs
                                                >
                                                    {DispatchResourceTabs.map(
                                                        (tab) => {
                                                            if (
                                                                !!(
                                                                    tab.number
                                                                    === DispatchResourceTabNumbers.Crews
                                                                )
                                                                && !crewsEnabled
                                                            ) return null;

                                                            return (
                                                                <NavItem
                                                                    key={
                                                                        tab.number
                                                                    }
                                                                >
                                                                    <NavLink
                                                                        className={cls(
                                                                            {
                                                                                active:
                                                                                        activeResourceTab.number
                                                                                        === tab.number,
                                                                            },
                                                                        )}
                                                                        onClick={() => this.onResourceTabClicked(
                                                                            tab,
                                                                        )}
                                                                    >
                                                                        <i
                                                                            className={
                                                                                `mr-2 fa ${
                                                                                    tab.icon}`
                                                                            }
                                                                        />
                                                                        <span className="tabName">
                                                                            {
                                                                                tab.name
                                                                            }
                                                                        </span>
                                                                        {!!(
                                                                            tab.number
                                                                                === DispatchResourceTabNumbers.Crews
                                                                        )
                                                                                && activeResourceTab.number
                                                                                    === DispatchResourceTabNumbers.Crews && (
                                                                            <FontAwesomeIcon
                                                                                id="crewButton"
                                                                                title="Manage Crews"
                                                                                size="lg"
                                                                                onClick={() => {
                                                                                    const {
                                                                                        crewsInterfaceOpen,
                                                                                    } = {
                                                                                        ...this
                                                                                            .state,
                                                                                    };
                                                                                    this.setState(
                                                                                        {
                                                                                            crewsInterfaceOpen:
                                                                                                        !crewsInterfaceOpen,
                                                                                        },
                                                                                    );
                                                                                }}
                                                                                className="ml-2"
                                                                                icon={
                                                                                    faCog
                                                                                }
                                                                            />
                                                                        )}
                                                                    </NavLink>
                                                                </NavItem>
                                                            );
                                                        },
                                                    )}
                                                </Nav>
                                                <div id="dispatchCenterToolbarRight">
                                                    {staffTabActive && (
                                                        <>
                                                            {!!hoursEnabled && (
                                                                <ButtonDropdown
                                                                    id="staffSortButton"
                                                                    size="sm"
                                                                    isOpen={
                                                                        staffSortDropdownOpen
                                                                    }
                                                                    toggle={() => this.setState(
                                                                        {
                                                                            staffSortDropdownOpen:
                                                                                    !staffSortDropdownOpen,
                                                                        },
                                                                    )}
                                                                >
                                                                    <DropdownToggle
                                                                        caret
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            icon={
                                                                                faSort
                                                                            }
                                                                            className="mr-2"
                                                                        />
                                                                        {
                                                                            selectedStaffSort.label
                                                                        }
                                                                    </DropdownToggle>
                                                                    <DropdownMenu>
                                                                        {StaffSortTypes.map(
                                                                            (
                                                                                t,
                                                                            ) => (
                                                                                <DropdownItem
                                                                                    key={
                                                                                        t.id
                                                                                    }
                                                                                    onClick={() => this.setState(
                                                                                        {
                                                                                            selectedStaffSort:
                                                                                                    {
                                                                                                        ...t,
                                                                                                    },
                                                                                        },
                                                                                    )}
                                                                                >
                                                                                    {
                                                                                        t.label
                                                                                    }
                                                                                </DropdownItem>
                                                                            ),
                                                                        )}
                                                                    </DropdownMenu>
                                                                </ButtonDropdown>
                                                            )}
                                                        </>
                                                    )}
                                                    <Input
                                                        placeholder={`Search ${activeResourceTab.name}`}
                                                        bsSize="sm"
                                                        type="search"
                                                        id="searchText"
                                                        name="searchText"
                                                        className="ml-2"
                                                        value={
                                                            resourceSearchText
                                                            ?? ''
                                                        }
                                                        onChange={(e) => this.handleResourceSearchText(
                                                            e.target.value,
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <Droppable
                                                droppableId="resourcesDroppable"
                                                style={{ transform: 'none' }}
                                            >
                                                {(provided, snapshot) => (
                                                    <div
                                                        id="dispatchCenterContent"
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        {(!!staffLoading
                                                            || !!resourcesLoading) && (
                                                            <CustomCircularProgress />
                                                        )}
                                                        {!staffLoading
                                                            && !resourcesLoading && (
                                                            <>
                                                                {staffTabActive
                                                                        && !staffLoading
                                                                        && (
                                                                            (
                                                                                staff
                                                                                ?? {}
                                                                            )
                                                                                .rows
                                                                            ?? []
                                                                        ).map(
                                                                            (
                                                                                st,
                                                                                index,
                                                                            ) => (
                                                                                <Draggable
                                                                                    key={
                                                                                        st.id
                                                                                    }
                                                                                    draggableId={`staff_${st.id}`}
                                                                                    index={
                                                                                        index
                                                                                    }
                                                                                >
                                                                                    {(
                                                                                        provided,
                                                                                    ) => (
                                                                                        <StaffCard
                                                                                            innerRef={
                                                                                                provided.innerRef
                                                                                            }
                                                                                            provided={
                                                                                                provided
                                                                                            }
                                                                                            staff={
                                                                                                st
                                                                                            }
                                                                                            jobs={
                                                                                                allJobs
                                                                                            }
                                                                                            weekdays={
                                                                                                daysInRange
                                                                                            }
                                                                                            minimal={
                                                                                                `staff_${st.id}`
                                                                                                    === this
                                                                                                        .state
                                                                                                        .draggableId
                                                                                            }
                                                                                            longTermStatuses={
                                                                                                longTermStatuses
                                                                                            }
                                                                                            employeeLongTermStatuses={
                                                                                                employeeLongTermStatuses
                                                                                            }
                                                                                            dailyAttendanceEvents={
                                                                                                dailyAttendanceEvents
                                                                                            }
                                                                                            employeeDailyAttendanceEvents={
                                                                                                employeeDailyAttendanceEvents
                                                                                            }
                                                                                            cancellationJobs={
                                                                                                cancellationJobs
                                                                                            }
                                                                                            hiddenStaffIds={
                                                                                                hiddenStaffIds
                                                                                            }
                                                                                            jobAssignmentCancellationTypeIdsThatPreventReassignment={
                                                                                                jobAssignmentCancellationTypeIdsThatPreventReassignment
                                                                                            }
                                                                                            jobAssignmentCancellationTypes={
                                                                                                jobAssignmentCancellationTypes
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                                </Draggable>
                                                                            ),
                                                                        )}
                                                                {staffTabActive
                                                                        && !(
                                                                            (
                                                                                staff
                                                                                ?? {}
                                                                            )
                                                                                .rows
                                                                            ?? []
                                                                        )
                                                                            .length && (
                                                                    <span>
                                                                              No
                                                                              staff
                                                                              found
                                                                              for
                                                                              the
                                                                              selected
                                                                              filters.
                                                                    </span>
                                                                )}
                                                                {equipmentTabActive
                                                                        && (
                                                                            (
                                                                                equipment
                                                                                ?? {}
                                                                            )
                                                                                .rows
                                                                            ?? []
                                                                        ).map(
                                                                            (
                                                                                eq,
                                                                                index,
                                                                            ) => (
                                                                                <Draggable
                                                                                    key={
                                                                                        eq.id
                                                                                    }
                                                                                    draggableId={`equipment_${eq.id}`}
                                                                                    index={
                                                                                        index
                                                                                    }
                                                                                >
                                                                                    {(
                                                                                        provided,
                                                                                    ) => (
                                                                                        <EquipmentCard
                                                                                            innerRef={
                                                                                                provided.innerRef
                                                                                            }
                                                                                            provided={
                                                                                                provided
                                                                                            }
                                                                                            equipment={
                                                                                                eq
                                                                                            }
                                                                                            allJobs={
                                                                                                planningJobs
                                                                                            }
                                                                                            weekdays={
                                                                                                daysInRange
                                                                                            }
                                                                                            minimal={
                                                                                                `equipment_${eq.id}`
                                                                                                    === this
                                                                                                        .state
                                                                                                        .draggableId
                                                                                            }
                                                                                            hiddenEquipmentIds={
                                                                                                hiddenEquipmentIds
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                                </Draggable>
                                                                            ),
                                                                        )}
                                                                {equipmentTabActive
                                                                        && !(
                                                                            (
                                                                                equipment
                                                                                ?? {}
                                                                            )
                                                                                .rows
                                                                            ?? []
                                                                        )
                                                                            .length && (
                                                                    <span>
                                                                              No
                                                                              equipment
                                                                              found
                                                                              for
                                                                              the
                                                                              selected
                                                                              dispatching.
                                                                    </span>
                                                                )}
                                                                {activeResourceTab.number
                                                                        === DispatchResourceTabNumbers.Crews && (
                                                                    <></>
                                                                )}
                                                                {
                                                                    provided.placeholder
                                                                }
                                                            </>
                                                        )}
                                                    </div>
                                                )}
                                            </Droppable>

                                            <div id="dispatchCenterFooter">
                                                {!!(
                                                    (currentResource ?? {})
                                                        .rows ?? []
                                                ).length && (
                                                    <small>
                                                        {`Displaying ${
                                                            (
                                                                currentResource.rows
                                                            ?? []
                                                            ).length
                                                        } of ${
                                                            currentResource.maxRows
                                                        } resources (${
                                                            currentResource.maxRows
                                                        - currentResource.filterCount
                                                        } filtered).${hiddenResourceText}`}
                                                    </small>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div
                                    id="dispatchRight"
                                    className={cls({
                                        'pointer-events-none':
                                            !!this.state.jobsLoading,
                                        max80: showAttendance,
                                        max100: !showAttendance,
                                    })}
                                >
                                    <legend>JOB REQUIREMENTS</legend>
                                    {!!this.state.loading && (
                                        <CustomCircularProgress />
                                    )}
                                    {!this.state.loading && (
                                        <>
                                            <div id="dispatchRightContentHeader">
                                                <div id="dispatchRightContentHeaderLeft">
                                                    {!!todaySelectedOrLater && (
                                                        <>
                                                            <FormGroup id="notificationFormGroup">
                                                                <label>
                              Notifications
                                                                </label>
                                                                <Button
                                                                    type="button"
                                                                    size="sm"
                                                                    color="primary"
                                                                    onClick={
                                                                        this
                                                                            .toggleModal
                                                                    }
                                                                >
                                                                    <FontAwesomeIcon
                                                                        size="lg"
                                                                        icon={
                                                                            faBolt
                                                                        }
                                                                        className="mr-2"
                                                                    />
                              Send
                                                                </Button>
                                                            </FormGroup>
                                                            <Modal
                                                                id="notificationModal"
                                                                isOpen={
                                                                    showModal
                                                                }
                                                                toggle={
                                                                    this
                                                                        .toggleModal
                                                                }
                                                                keyboard={false}
                                                                backdrop="static"
                                                            >
                                                                <ModalHeader
                                                                    toggle={
                                                                        this
                                                                            .toggleModal
                                                                    }
                                                                >
                              Send
                              Notifications
                                                                </ModalHeader>
                                                                <ModalBody>
                                                                    <FormCheckbox
                                                                        small
                                                                        className="ml-1 mt-2"
                                                                        id="noResponse"
                                                                        name="noResponse"
                                                                        checked={
                                                                            noResponse
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onNoResponseCheckedChanged
                                                                        }
                                                                        labelText="Include employees with no response"
                                                                    />
                                                                </ModalBody>
                                                                <ModalFooter>
                                                                    {!!isSavingJobStatus && (
                                                                        <>
                                                                            <FontAwesomeIcon
                                                                                icon={
                                                                                    faCircleNotch
                                                                                }
                                                                                className="fa-spin mr-2"
                                                                                size="lg"
                                                                            />
                                Sending
                                Notifications
                                and
                                Updating
                                Jobs...
                                                                        </>
                                                                    )}
                                                                    {!isSavingJobStatus && (
                                                                        <>
                                                                            <Button
                                                                                color="secondary"
                                                                                disabled={
                                                                                    isSavingJobStatus
                                                                                }
                                                                                onClick={
                                                                                    this
                                                                                        .toggleModal
                                                                                }
                                                                            >
                                  Cancel
                                                                            </Button>
                                                                            <Button
                                                                                color="primary"
                                                                                disabled={
                                                                                    isSavingJobStatus
                                                                                }
                                                                                onClick={
                                                                                    this
                                                                                        .onSendNotifications
                                                                                }
                                                                            >
                                  Ok
                                                                            </Button>
                                                                        </>
                                                                    )}
                                                                </ModalFooter>
                                                            </Modal>
                                                        </>
                                                    )}
                                                </div>
                                                <div id="dispatchRightContentHeaderRight">
                                                    <div className="dispatch-location">
                                                        <FormGroup>
                                                            <label>
                              Dispatching
                                                            </label>
                                                            <FlexStartRow>
                                                                <Select
                                                                    placeholder="Select Dispatching"
                                                                    id="selectedJobDispatchLocation"
                                                                    name="selectedJobDispatchLocation"
                                                                    styles={
                                                                        CompactSelectStyles
                                                                    }
                                                                    isClearable={
                                                                        false
                                                                    }
                                                                    className="react-select"
                                                                    options={
                                                                        dispatchLocations
                                                                    }
                                                                    value={
                                                                        jobDispatchLocation
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .onSelectedJobDispatchLocationChanged
                                                                    }
                                                                />
                                                                {!!window.localStorage
                                                                    && this.context
                                                                        .user
                                                                        .isAdmin && (
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faStar
                                                                        }
                                                                        onClick={() => {
                                                                            if (
                                                                                selectedJobDispatchLocation
                                                                            ) {
                                                                                this.preferredDispatch(
                                                                                    selectedJobDispatchLocation,
                                                                                );
                                                                            }
                                                                        }}
                                                                        title="Toggle this as preferred Dispatching"
                                                                        size="lg"
                                                                        className={
                                                                            `preferred-dispatch-icon cursor-pointer ml-2 ${
                                                                                !!selectedJobDispatchLocation
                                                                                && this
                                                                                    .state
                                                                                    .preferredDispatchLocation
                                                                                    === selectedJobDispatchLocation
                                                                                    ? 'active'
                                                                                    : 'text-muted'}`
                                                                        }
                                                                    />
                                                                )}
                                                            </FlexStartRow>
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="dispatchRightContent">
                                                <Nav id="dispatchJobTabs" tabs>
                                                    {DispatchJobTabs.map(
                                                        (tab) => (
                                                            <NavItem
                                                                key={
                                                                    tab.number
                                                                }
                                                            >
                                                                <NavLink
                                                                    className={cls(
                                                                        {
                                                                            active:
                                                                                    activeJobTab.number
                                                                                    === tab.number,
                                                                        },
                                                                    )}
                                                                    onClick={() => this.onJobTabClicked(
                                                                        tab,
                                                                    )}
                                                                >
                                                                    {
                                                                        tab.name
                                                                    }
                                                                </NavLink>
                                                            </NavItem>
                                                        ),
                                                    )}
                                                </Nav>
                                                {activeJobTab.number
                                                    === DispatchJobTabNumbers.Planning && (
                                                    <div className="planning-content">
                                                        <div className="scoreboard">
                                                            <table className="table table-sm table-bordered border-top-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="text-left">
                                                                Resource
                                                                        </th>
                                                                        <th className="text-center assignment-status-label unfilled">
                                                                Unfilled
                                                                        </th>
                                                                        <th className="text-center assignment-status-label scheduled">
                                                                Scheduled
                                                                        </th>
                                                                        <th className="text-center assignment-status-label noresponse">
                                                                No
                                                                Response
                                                                        </th>
                                                                        <th className="text-center assignment-status-label confirmed">
                                                                Confirmed
                                                                        </th>
                                                                        {/* <th className="text-center assignment-status-label noresponse">Cancelled</th> */}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="text-left font-bold">
                                                                Staff
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {
                                                                                planningBoard
                                                                                    .staff
                                                                                    .unfilled
                                                                            }
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {
                                                                                planningBoard
                                                                                    .staff
                                                                                    .scheduled
                                                                            }
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {
                                                                                planningBoard
                                                                                    .staff
                                                                                    .noResponse
                                                                            }
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {
                                                                                planningBoard
                                                                                    .staff
                                                                                    .confirmed
                                                                            }
                                                                        </td>
                                                                        {/* <td className="text-center">{planningBoard.staff.cancelled}</td> */}
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="text-left font-bold">
                                                                Equipment
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {
                                                                                planningBoard
                                                                                    .equipment
                                                                                    .unfilled
                                                                            }
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {
                                                                                planningBoard
                                                                                    .equipment
                                                                                    .scheduled
                                                                            }
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {
                                                                                planningBoard
                                                                                    .equipment
                                                                                    .noResponse
                                                                            }
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {
                                                                                planningBoard
                                                                                    .equipment
                                                                                    .confirmed
                                                                            }
                                                                        </td>
                                                                        {/* <td className="text-center">{planningBoard.equipment.cancelled}</td> */}
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="job-listing-container">
                                                            <div className="job-listing-container-header">
                                                                <span className="job-listing-container-subheader">
                                                            JOBS
                                                                </span>
                                                                <div className="job-listing-filters">
                                                                    <Input
                                                                        placeholder="Search Jobs"
                                                                        bsSize="sm"
                                                                        type="search"
                                                                        id="jobSearchText"
                                                                        name="jobSearchText"
                                                                        value={
                                                                            jobSearchText
                                                                            ?? ''
                                                                        }
                                                                        onChange={(
                                                                            e,
                                                                        ) => this.handleJobSearchText(
                                                                            e
                                                                                .target
                                                                                .value,
                                                                        )}
                                                                    />
                                                                    <span
                                                                        className="ml-2 btn btn-outline-primary btn-sm d-flex flex-row align-items-center"
                                                                        title="Expand or collapse all jobs."
                                                                        onClick={() => {
                                                                            const {
                                                                                planningJobs,
                                                                                allJobsCollapsed,
                                                                            } = {
                                                                                ...this
                                                                                    .state,
                                                                            };
                                                                            const ids = planningJobs.map(
                                                                                (
                                                                                    x,
                                                                                ) => x.id,
                                                                            );
                                                                            this.setState(
                                                                                {
                                                                                    allJobsCollapsed:
                                                                                        !allJobsCollapsed,
                                                                                    jobsOpened:
                                                                                        allJobsCollapsed
                                                                                            ? []
                                                                                            : ids,
                                                                                },
                                                                            );
                                                                        }}
                                                                    >
                                                                        <i
                                                                            className={cls(
                                                                                'fa fa-lg m-0 p-0',
                                                                                {
                                                                                    'fa-chevron-down':
                                                                                        !allJobsCollapsed,
                                                                                    'fa-chevron-up':
                                                                                        !!allJobsCollapsed,
                                                                                },
                                                                            )}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="job-listing">
                                                                {!!this.state
                                                                    .jobsLoading && (
                                                                    <CustomCircularProgress />
                                                                )}
                                                                {!(
                                                                    planningJobs
                                                                    ?? []
                                                                ).length && (
                                                                    <span>
                                                              No jobs
                                                              with
                                                              assignments
                                                              found
                                                              for the
                                                              selected
                                                              filters.
                                                                    </span>
                                                                )}
                                                                {!this.state
                                                                    .jobsLoading
                                                                    && !!(
                                                                        planningJobs
                                                                        ?? []
                                                                    ).length
                                                                    && planningJobs.map(
                                                                        (
                                                                            job,
                                                                            index,
                                                                        ) => {
                                                                            const jobPaneOpen = jobsOpened.includes(
                                                                                job.id,
                                                                            );
                                                                            // let cancelled = selectedWeekRangeDays.every(x => job.assignments[x.getDay()].isCancelled === true);
                                                                            // if (!cancelled) {
                                                                            return (
                                                                                <div
                                                                                    className={cls(
                                                                                        'jobListItem',
                                                                                        {
                                                                                            'details-open':
                                                                                                !!jobsOpened.includes(
                                                                                                    job.id,
                                                                                                ),
                                                                                            saving: !!jobsSaving.includes(
                                                                                                job.id,
                                                                                            ),
                                                                                        },
                                                                                    )}
                                                                                    key={
                                                                                        job.id
                                                                                    }
                                                                                >
                                                                                    <div className="saving-progress">
                                                                                        <FontAwesomeIcon
                                                                                            icon={
                                                                                                faCircleNotch
                                                                                            }
                                                                                            spin
                                                                                        />
                                                                                    </div>
                                                                                    <div className="jobListItemHeader">
                                                                                        <div className="flex-fill job-name">
                                                                                            {`${
                                                                                                job.name
                                                                                            }${
                                                                                                job.foremanName
                                                                                                    ? ` - ${
                                                                                                        job.foremanName}`
                                                                                                    : ''
                                                                                            } - ${
                                                                                                job.startTime
                                                                                            }${
                                                                                                job.workOrderNumber
                                                                                                    ? ` -${
                                                                                                        job.workOrderNumber}`
                                                                                                    : ''
                                                                                            }`}
                                                                                        </div>
                                                                                        <Link
                                                                                            className="job-number ml-1 mr-2 site-link"
                                                                                            to={`${AppNavPaths.Job}/${job.id}`}
                                                                                            target="_blank"
                                                                                        >
                                                                                            {
                                                                                                job.number
                                                                                            }
                                                                                        </Link>
                                                                                        <FontAwesomeIcon
                                                                                            className="ml-2 cursor-pointer"
                                                                                            icon={
                                                                                                !jobPaneOpen
                                                                                                    ? faChevronDown
                                                                                                    : faChevronUp
                                                                                            }
                                                                                            title={
                                                                                                jobPaneOpen
                                                                                                    ? 'HideDetails'
                                                                                                    : 'Show Details'
                                                                                            }
                                                                                            onClick={() => this.toggleJobCollapse(
                                                                                                job.id,
                                                                                                !!jobPaneOpen,
                                                                                            )}
                                                                                        />
                                                                                    </div>
                                                                                    <div
                                                                                        className="job-details"
                                                                                        hidden={
                                                                                            !jobPaneOpen
                                                                                        }
                                                                                    >
                                                                                        <div className="job-details-header">
                                                                                            <div className="d-flex flex-column flex-fill">
                                                                                                <span
                                                                                                    className="job-address mb-1"
                                                                                                    title="Meeting Location"
                                                                                                >
                                                                                                    <span className="badge badge-secondary mr-1">
                                                                                                        M
                                                                                                    </span>
                                                                                                    <FontAwesomeIcon
                                                                                                        icon={
                                                                                                            faMapMarker
                                                                                                        }
                                                                                                        className="text-muted mr-1"
                                                                                                    />
                                                                                                    {
                                                                                                        job.meetingAddress
                                                                                                    }
                                                                                                </span>
                                                                                                <span
                                                                                                    className="job-address"
                                                                                                    title="Job Location"
                                                                                                >
                                                                                                    <span className="badge badge-secondary mr-1">
                                                                                                        J
                                                                                                    </span>
                                                                                                    <FontAwesomeIcon
                                                                                                        icon={
                                                                                                            faMapMarker
                                                                                                        }
                                                                                                        className="text-muted mr-1"
                                                                                                    />
                                                                                                    {
                                                                                                        job.jobAddress
                                                                                                    }
                                                                                                </span>

                                                                                                {Boolean(job.usCounty) && (
                                                                                                    <span
                                                                                                        className="job-address mt-1"
                                                                                                        title="Job County"
                                                                                                    >
                                                                                                        <span className="badge badge-secondary mr-1">
                                                                                                            C
                                                                                                        </span>
                                                                                                        <FontAwesomeIcon
                                                                                                            icon={
                                                                                                                faMapMarker
                                                                                                            }
                                                                                                            className="text-muted mr-1"
                                                                                                        />
                                                                                                        
                                                                                                        <CountyLabel {...job.usCounty} />
                                                                                                    </span>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                        <DispatchJobAssignments
                                                                                            allStaff={
                                                                                                staff.rows
                                                                                            }
                                                                                            allEquipment={
                                                                                                equipment.rows
                                                                                            }
                                                                                            job={
                                                                                                job
                                                                                            }
                                                                                            jobIndex={
                                                                                                index
                                                                                            }
                                                                                            selectedWeekRangeDays={
                                                                                                selectedWeekRangeDays
                                                                                            }
                                                                                            onEquipmentAssignmentChange={
                                                                                                this
                                                                                                    .onEquipmentAssignmentChange
                                                                                            }
                                                                                            onRemoveAssignment={
                                                                                                this
                                                                                                    .onRemoveAssignment
                                                                                            }
                                                                                            onRemoveDayJob={
                                                                                                this
                                                                                                    .onRemoveDayJob
                                                                                            }
                                                                                            setCrewLead={
                                                                                                this
                                                                                                    .setCrewLead
                                                                                            }
                                                                                            onEditJobWorkflowClicked={
                                                                                                this
                                                                                                    .onEditJobWorkflowClicked
                                                                                            }
                                                                                            onEmergencyClicked={
                                                                                                this
                                                                                                    .onEmergencyClicked
                                                                                            }
                                                                                            tenantSettings={
                                                                                                tenantSettings
                                                                                            }
                                                                                            strings={
                                                                                                strings
                                                                                            }
                                                                                        />
                                                                                        {/* <FontAwesomeIcon */}
                                                                                        {/*    title="Remove all assignments for this job" */}
                                                                                        {/*    icon={faMinusCircle} */}
                                                                                        {/*    className="text-danger cursor-pointer" */}
                                                                                        {/*    onClick={this.removeAllJobAssignments} */}
                                                                                        {/* /> */}
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        // }
                                                                        },
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {activeJobTab.number
                                                    === DispatchJobTabNumbers.Cancellations && (
                                                    <div className="planning-content">
                                                        <div className="scoreboard">
                                                            <table className="table table-sm table-bordered border-top-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="text-left">
                                                                Resource
                                                                        </th>
                                                                        <th className="text-center assignment-status-label noresponse">
                                                                Cancel
                                                                Pending
                                                                        </th>
                                                                        <th className="text-center assignment-status-label confirmed">
                                                                Cancel
                                                                Confirmed
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="text-left font-bold">
                                                                Staff
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {
                                                                                cancellationBoard
                                                                                    .staff
                                                                                    .cancelPending
                                                                            }
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {
                                                                                cancellationBoard
                                                                                    .staff
                                                                                    .cancelConfirmed
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="text-left font-bold">
                                                                Equipment
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {
                                                                                cancellationBoard
                                                                                    .equipment
                                                                                    .cancelPending
                                                                            }
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {
                                                                                cancellationBoard
                                                                                    .equipment
                                                                                    .cancelConfirmed
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="job-listing-container">
                                                            <div className="job-listing-container-header">
                                                                <span className="job-listing-container-subheader">
                                                            CANCELLED
                                                            JOBS
                                                                </span>
                                                                <div className="job-listing-filters">
                                                                    <Input
                                                                        placeholder="Search Cancellations"
                                                                        bsSize="sm"
                                                                        type="search"
                                                                        id="cancellationJobSearchText"
                                                                        name="cancellationJobSearchText"
                                                                        value={
                                                                            cancellationJobSearchText
                                                                            ?? ''
                                                                        }
                                                                        onChange={(
                                                                            e,
                                                                        ) => this.handleCancellationJobSearchText(
                                                                            e
                                                                                .target
                                                                                .value,
                                                                        )}
                                                                    />
                                                                    <span
                                                                        className="ml-2 btn btn-outline-primary btn-sm d-flex flex-row align-items-center"
                                                                        title="Expand or collapse all cancellations."
                                                                        onClick={() => {
                                                                            const {
                                                                                cancellationJobs,
                                                                                allCancellationJobsCollapsed,
                                                                            } = {
                                                                                ...this
                                                                                    .state,
                                                                            };
                                                                            const ids = cancellationJobs.map(
                                                                                (
                                                                                    x,
                                                                                ) => x.id,
                                                                            );
                                                                            this.setState(
                                                                                {
                                                                                    allCancellationJobsCollapsed:
                                                                                        !allCancellationJobsCollapsed,
                                                                                    cancellationJobsOpened:
                                                                                        allCancellationJobsCollapsed
                                                                                            ? []
                                                                                            : ids,
                                                                                },
                                                                            );
                                                                        }}
                                                                    >
                                                                        <i
                                                                            className={cls(
                                                                                'fa fa-lg m-0 p-0',
                                                                                {
                                                                                    'fa-chevron-down':
                                                                                        !allCancellationJobsCollapsed,
                                                                                    'fa-chevron-up':
                                                                                        !!allCancellationJobsCollapsed,
                                                                                },
                                                                            )}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="job-listing">
                                                                {!!this.state
                                                                    .cancellationsLoading && (
                                                                    <CustomCircularProgress />
                                                                )}
                                                                {!(
                                                                    cancellationJobs
                                                                    ?? []
                                                                ).length && (
                                                                    <span>
                                                              No
                                                              cancellations
                                                              found
                                                              for the
                                                              selected
                                                              filters.
                                                                    </span>
                                                                )}
                                                                {!this.state
                                                                    .cancellationsLoading
                                                                    && !!(
                                                                        cancellationJobs
                                                                        ?? []
                                                                    ).length
                                                                    && cancellationJobs.map(
                                                                        (
                                                                            job,
                                                                            index,
                                                                        ) => {
                                                                            const cancellationJobPaneOpen = cancellationJobsOpened.includes(
                                                                                job.id,
                                                                            );
                                                                            return (
                                                                                <div
                                                                                    className={cls(
                                                                                        'jobListItem',
                                                                                        {
                                                                                            'details-open':
                                                                                                !!cancellationJobsOpened.includes(
                                                                                                    job.id,
                                                                                                ),
                                                                                        },
                                                                                    )}
                                                                                    key={
                                                                                        job.id
                                                                                    }
                                                                                >
                                                                                    <div className="saving-progress">
                                                                                        <FontAwesomeIcon
                                                                                            icon={
                                                                                                faCircleNotch
                                                                                            }
                                                                                            spin
                                                                                        />
                                                                                    </div>
                                                                                    <div className="jobListItemHeader">
                                                                                        <div className="flex-fill job-name">
                                                                                            {`${
                                                                                                job.name
                                                                                            }${
                                                                                                job.foremanName
                                                                                                    ? ` - ${
                                                                                                        job.foremanName}`
                                                                                                    : ''
                                                                                            } - ${
                                                                                                job.startTime
                                                                                            }${
                                                                                                job.workOrderNumber
                                                                                                    ? ` -${
                                                                                                        job.workOrderNumber}`
                                                                                                    : ''
                                                                                            }`}
                                                                                        </div>
                                                                                        <Link
                                                                                            className="job-number ml-1 mr-2 site-link"
                                                                                            to={`${AppNavPaths.Job}/${job.id}`}
                                                                                            target="_blank"
                                                                                        >
                                                                                            {
                                                                                                job.number
                                                                                            }
                                                                                        </Link>
                                                                                        <FontAwesomeIcon
                                                                                            className="ml-2 cursor-pointer"
                                                                                            icon={
                                                                                                !cancellationJobPaneOpen
                                                                                                    ? faChevronDown
                                                                                                    : faChevronUp
                                                                                            }
                                                                                            title={
                                                                                                cancellationJobPaneOpen
                                                                                                    ? 'HideDetails'
                                                                                                    : 'Show Details'
                                                                                            }
                                                                                            onClick={() => this.toggleCancellationJobCollapse(
                                                                                                job.id,
                                                                                                !!cancellationJobPaneOpen,
                                                                                            )}
                                                                                        />
                                                                                    </div>
                                                                                    <div
                                                                                        className="job-details"
                                                                                        hidden={
                                                                                            !cancellationJobPaneOpen
                                                                                        }
                                                                                    >
                                                                                        <div className="job-details-header">
                                                                                            <div className="d-flex flex-column flex-fill">
                                                                                                <span
                                                                                                    className="job-address mb-1"
                                                                                                    title="Meeting Location"
                                                                                                >
                                                                                                    <span className="badge badge-secondary mr-1">
                                                                                      M
                                                                                                    </span>
                                                                                                    <FontAwesomeIcon
                                                                                                        icon={
                                                                                                            faMapMarker
                                                                                                        }
                                                                                                        className="text-muted mr-1"
                                                                                                    />
                                                                                                    {
                                                                                                        job.meetingAddress
                                                                                                    }
                                                                                                </span>
                                                                                                <span
                                                                                                    className="job-address"
                                                                                                    title="Job Location"
                                                                                                >
                                                                                                    <span className="badge badge-secondary mr-1">
                                                                                      J
                                                                                                    </span>
                                                                                                    <FontAwesomeIcon
                                                                                                        icon={
                                                                                                            faMapMarker
                                                                                                        }
                                                                                                        className="text-muted mr-1"
                                                                                                    />
                                                                                                    {
                                                                                                        job.jobAddress
                                                                                                    }
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <DispatchCancellationJobAssignments
                                                                                            job={
                                                                                                job
                                                                                            }
                                                                                            jobIndex={
                                                                                                index
                                                                                            }
                                                                                            selectedWeekRangeDays={
                                                                                                selectedWeekRangeDays
                                                                                            }
                                                                                            tenantSettings={
                                                                                                tenantSettings
                                                                                            }
                                                                                            strings={
                                                                                                strings
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        },
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            {activeJobTab.number
                                                === DispatchJobTabNumbers.Planning && (
                                                <div id="dispatchRightContentFooter">
                                                    {!!(planningJobs ?? [])
                                                        .length && (
                                                        <small>
                                                            {`Displaying ${
                                                                planningJobs.length
                                                            } of ${planningJobsMaxRows} jobs (${
                                                                planningJobsMaxRows
                                                            - planningJobsFilteredCount
                                                            } filtered).`}
                                                        </small>
                                                    )}
                                                </div>
                                            )}
                                            {activeJobTab.number
                                                === DispatchJobTabNumbers.Cancellations && (
                                                <div id="dispatchRightContentFooter">
                                                    {!!(cancellationJobs ?? [])
                                                        .length && (
                                                        <small>
                                                            {`Displaying ${
                                                                cancellationJobs.length
                                                            } of ${cancellationJobsMaxRows} cancellations (${
                                                                planningJobsMaxRows
                                                            - cancellationJobsFilteredCount
                                                            } filtered).`}
                                                        </small>
                                                    )}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>

                                <>
                                    {Boolean(showAttendance) && (
                                        <div
                                            id="dispatchBottom"
                                            className={cls({
                                                'pointer-events-none':
                                                    !!this.state.jobsLoading,
                                            })}
                                        >
                                            <DailyAttendanceDispatchSink
                                                dailyAttendanceEvents={
                                                    dailyAttendanceEvents
                                                }
                                                employeeDailyAttendanceEvents={
                                                    employeeDailyAttendanceEvents
                                                }
                                                selectedWeekRangeDays={
                                                    selectedWeekRangeDays
                                                }
                                                loading={loading}
                                                staffLoading={staffLoading}
                                                resourcesLoading={resourcesLoading}
                                                areAttendanceEventsEnabled={
                                                    areAttendanceEventsEnabled
                                                }
                                                staffTabActive={staffTabActive}
                                            />
                                        </div>
                                    )}
                                </>
                            </Split>
                        </div>
                    </DragDropContext>
                </div>

                <Modal
                    isOpen={showCancelJobModal}
                    toggle={this.toggleCancelJobModal}
                >
                    <ModalHeader toggle={this.toggleCancelJobModal}>
            Cancel Job
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <label>
                                {`Job Start Time: ${
                                    ((selectedDayJobToRemove ?? {}).job ?? {})
                                        .startTime
                                }`}
                            </label>
                        </FormGroup>
                        <FormGroup>
                            <FormLabel
                                htmlFor="selectedJobCancellationType"
                                text="Cancellation Reason"
                                required
                            />
                            <Select
                                placeholder="Select Cancellation Reason"
                                id="selectedJobCancellationType"
                                name="selectedJobCancellationType"
                                styles={CompactSelectStyles}
                                isClearable={false}
                                className="react-select"
                                required
                                options={jobCancellationTypes}
                                value={
                                    (jobCancellationTypes ?? []).find(
                                        (x) => x.value
                                            === selectedJobCancellationType,
                                    ) ?? ''
                                }
                                onChange={
                                    this.onSelectedJobCancellationReasonChanged
                                }
                            />
                            <small className="invalid-feedback text-danger">
                A Cancellation Reason is required.
                            </small>
                        </FormGroup>
                        {expandedCancellationReasonRequired
                            && selectedJobCancellationType
                                == JobAssignmentCancellationType.ClientCancel && (
                            <FormGroup>
                                <FormLabel htmlFor="cancelContactId" text="Cancel Requested By" required />
                                <Select
                                    styles={CompactSelectStyles}
                                    isClearable={false}
                                    id="cancelContactId"
                                    name="cancelContactId"
                                    className="react-select"
                                    options={companyContacts}
                                    getOptionLabel={(locationContact) => locationContact.contactName}
                                    getOptionValue={(locationContact) => locationContact.id}
                                    value={
                                        (companyContacts ?? []).find(
                                            (lc) => lc.id == cancelContactId,
                                        ) ?? ''
                                    }
                                    onChange={(selection) => this.setState({
                                        cancelContactId: selection.id,
                                    })}
                                />
                                <small className="invalid-feedback text-danger">
                                    Please select who requested this cancellation.
                                </small>
                            </FormGroup>
                        )}
                        <FormGroup>
                            <FormLabel htmlFor="selectedJobCancellationTime" text="Cancellation Time" required />
                            <input
                                id="selectedJobCancellationTime"
                                required
                                type="datetime-local"
                                className="form-control"
                                name="selectedJobCancellationTime"
                                defaultValue={selectedJobCancellationTime ?? ''}
                                onChange={this.onChange}
                            />
                            <small className="invalid-feedback text-danger">
                Cancellation Time is required.
                            </small>
                        </FormGroup>
                        <FormGroup>
                            <FormLabel htmlFor="notes" text="Notes" />
                            <textarea
                                id="notes"
                                name="selectedJobCancellationNotes"
                                className="form-control"
                                defaultValue={
                                    selectedJobCancellationNotes ?? ''
                                }
                                onChange={this.onChange}
                                placeholder="Enter optional notes regarding the cancellation."
                                type="text"
                                maxLength="500"
                                rows="5"
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        {isCancellingJob && (
                            <FontAwesomeIcon
                                icon={faCircleNotch}
                                className="fa-spin mr-2"
                                size="sm"
                            />
                        )}
                        <Button
                            color="primary"
                            onClick={this.onCancelJob}
                            disabled={isCancelJobSaveDisabled || isCancellingJob}
                        >
              Ok
                        </Button>
                        {' '}
                    </ModalFooter>
                </Modal>

                <Modal isOpen={cancelJobDataLoading}>
                    <ModalHeader>Loading Data</ModalHeader>
                    <ModalBody>
                        <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            className="mr-2"
                        />
            Please wait...
                    </ModalBody>
                </Modal>

                <Modal
                    isOpen={showCancelAssignmentModal}
                    toggle={this.toggleCancelAssignmentModal}
                >
                    <ModalHeader toggle={this.toggleCancelAssignmentModal}>
            Remove Assignment
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <label>{`Job Start Time: ${selectedJobAssignmentCancellationStartTime}`}</label>
                        </FormGroup>
                        <FormGroup>
                            <label>Cancellation Reason</label>
                            <Select
                                required
                                placeholder="Select Cancellation Reason"
                                id="selectedJobAssignmentCancellationType"
                                name="selectedJobAssignmentCancellationType"
                                styles={CompactSelectStyles}
                                isClearable
                                className="react-select"
                                options={jobAssignmentCancellationTypes}
                                getOptionLabel={(option) => option.description}
                                getOptionValue={(option) => option.id}
                                value={
                                    (jobAssignmentCancellationTypes ?? []).find(
                                        (x) => x.id
                                            === selectedJobAssignmentCancellationType,
                                    ) ?? ''
                                }
                                onChange={
                                    this
                                        .onSelectedJobAssignmentCancellationReasonChanged
                                }
                            />
                        </FormGroup>
                        {this.selectedJobAssignmentCancellationTypePreventsReassignment() && (
                            <div className="mb-2 text-danger">
                                <FontAwesomeIcon icon={faInfoCircle} />
              Selecting this will prevent the employee from
              being reassigned for the remainder of the day.
                            </div>
                        )}
                        {(selectedJobAssignmentCancellationType ?? {}) == 1
                        && ((selectedJobAssignmentToRemove ?? {}).assignment ?? {})
                            .confirmationStatusId == 3 ? (
                                <small className=" text-danger">
                              Assignment needs to be confirmed for client
                              cancellation.
                                </small>
                            ) : (
                                <>
                                    <FormGroup>
                                        <label>Cancellation Time</label>
                                        <input
                                            id="selectedJobAssignmentCancellationTime"
                                            required
                                            type="datetime-local"
                                            className="form-control"
                                            name="selectedJobAssignmentCancellationTime"
                                            defaultValue={
                                                selectedJobAssignmentCancellationTime
                                            ?? ''
                                            }
                                            onChange={this.onChange}
                                        />
                                        <small className="invalid-feedback text-danger">
                      Cancellation Time is required.
                                        </small>
                                    </FormGroup>
                                    <FormGroup>
                                        <label>Notes</label>
                                        <textarea
                                            id="notes"
                                            name="selectedJobAssignmentCancellationNotes"
                                            className="form-control"
                                            defaultValue={
                                                selectedJobAssignmentCancellationNotes
                                            ?? ''
                                            }
                                            onChange={this.onChange}
                                            placeholder="Enter optional notes regarding the cancellation."
                                            type="text"
                                            maxLength="500"
                                            rows="5"
                                        />
                                    </FormGroup>
                                </>
                            )}
                    </ModalBody>
                    <ModalFooter>
                        {isCancellingAssignment && (
                            <FontAwesomeIcon
                                icon={faCircleNotch}
                                className="fa-spin mr-2"
                                size="sm"
                            />
                        )}
                        <Button
                            color="primary"
                            disabled={
                                isCancellingAssignment
                                || ((selectedJobAssignmentCancellationType
                                    ?? {}) == 1
                                    && (
                                        (selectedJobAssignmentToRemove ?? {})
                                            .assignment ?? {}
                                    ).confirmationStatusId == 3)
                            }
                            onClick={this.onCancelAssignment}
                        >
              Ok
                        </Button>
                        {' '}
                    </ModalFooter>
                </Modal>

                <Modal
                    isOpen={showEmergencyConfirmModal}
                    toggle={this.toggleEmergencyConfirmModal}
                >
                    <ModalHeader toggle={this.toggleEmergencyConfirmModal}>
            Emergency confirmation
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <p>
                                {(selectedEmergencyAssignments ?? {})
                                    .isEmergency === false
                                    ? 'Are you sure you would like to set this job as emergency work?'
                                    : 'Are you sure you would like to remove emergency work from this job?'}
                            </p>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        {isCallingOutAssignment && (
                            <FontAwesomeIcon
                                icon={faCircleNotch}
                                className="fa-spin mr-2"
                                size="sm"
                            />
                        )}
                        <Button
                            color="primary"
                            disabled={isCallingOutAssignment}
                            onClick={this.onEmergencyAssignment}
                        >
              Ok
                        </Button>
                        {' '}
                    </ModalFooter>
                </Modal>

                <JobWorkflowEditor
                    jobDetails={selectedWorkflowJob}
                    show
                    strings={strings}
                    onClose={() => {
                        this.context.setFormOpened(false);
                        this.setState({
                            jobWorkflowModalOpen: false,
                            selectedWorkflowJob: null,
                        });
                    }}
                />
            </>
        );
    }
}
export default withRouter(DispatchBoard);
