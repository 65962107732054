import React from 'react';
import { Button, Row, Col, Spinner } from 'reactstrap';
import { Can } from '../Can';
import { SmallButton } from '../common/forms/FormElements';
import CommonContext from '../Common';

export class EmployeeRestrictionInfo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        this.populateState();
    }

    async populateState() {
        this.setState({
            loading: false,
        });
    }

    render() {
        if (this.state.loading) {
            return (<Spinner />);
        }
        return (
            <>
                <Row className="pb-1">
                    <Col>
                        <div className="d-flex flex-row align-items-center justify-content-end">
                            <Can I="create" a="employee_restriction">
                                <CommonContext.Consumer>
                                    {(value) => (
                                        <SmallButton
                                            disabled={!!value.formIsOpen}
                                            type="button"
                                            onClick={this.props.onAddRestriction}
                                        >
                                            <i className="fa fa-plus-circle fa-md mr-2" />
                      Add Restriction
                                        </SmallButton>
                                    )}
                                </CommonContext.Consumer>
                            </Can>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <table className="table table-sm table-bordered">
                            <thead className="text-muted">
                                <tr>
                                    <th>Customer</th>
                                    <th>Foremen</th>
                                    <th>Block All</th>
                                    <Can I="edit" a="employee_restriction">
                                        <th className="text-center" style={{ width: '30px' }} />
                                    </Can>
                                </tr>
                            </thead>
                            <tbody>
                                {!(this.props.restrictions ?? []).length
                                    ? <tr><td colSpan="5" className="text-center">No restriction found.</td></tr>
                                    : this.props.restrictions.map((restrictionInfo, i) => (
                                        <tr key={restrictionInfo.id}>
                                            <td>{restrictionInfo.companyName}</td>
                                            <td>{restrictionInfo.foremenName}</td>
                                            <td>{restrictionInfo.blockAll == true ? 'Yes' : 'No'}</td>
                                            <Can I="edit" a="employee_restriction">
                                                <CommonContext.Consumer>
                                                    {(value) => (
                                                        <td>
                                                            <Button
                                                                disabled={!!value.formIsOpen}
                                                                type="button"
                                                                color="outline-primary"
                                                                onClick={() => this.props.onEditRestriction(restrictionInfo)}
                                                                size="sm"
                                                            >
                                Edit
                                                            </Button>
                                                        </td>
                                                    )}
                                                </CommonContext.Consumer>
                                            </Can>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </>
        );
    }
}

EmployeeRestrictionInfo.contextType = CommonContext;
