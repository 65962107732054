import React from 'react';
import { Button, Row, Col, Spinner } from 'reactstrap';
import { util } from '../Util';
import { Can } from '../Can';
import { SmallButton } from '../common/forms/FormElements';
import CommonContext from '../Common';

export class EmployeePayRateInfo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        this.populateState();
    }

    async populateState() {
        this.setState({
            loading: false,
        });
    }

    render() {
        const { loading } = this.state;
        const { payRates, onAddPayRate, onEditPayRate } = this.props;

        if (loading) {
            return (
                <Spinner />
            );
        }
        return (
            <>
                <Row className="pb-1">
                    <Col>
                        <div className="d-flex flex-row align-items-center justify-content-end">
                            <Can I="edit" a="employee">
                                <CommonContext.Consumer>
                                    {(context) => (
                                        <SmallButton
                                            disabled={!!context.formIsOpen}
                                            type="button"
                                            onClick={onAddPayRate}
                                        >
                                            <i className="fa fa-plus-circle fa-md mr-2" />
                      Add Pay Rate
                                        </SmallButton>
                                    )}
                                </CommonContext.Consumer>
                            </Can>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <table className="table table-sm table-bordered">
                            <thead className="text-muted">
                                <tr>
                                    <th>Pay Rate</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Rate Received</th>
                                    <Can I="edit" a="employee">
                                        <th
                                            className="text-center"
                                            style={{ width: '30px' }}
                                        />
                                    </Can>
                                </tr>
                            </thead>
                            <tbody>
                                {!(payRates ?? []).length ? (
                                    <tr>
                                        <td colSpan="5" className="text-center">
                      No rates found.
                                        </td>
                                    </tr>
                                ) : (
                                    payRates.map((rateInfo, i) => (
                                        <tr key={rateInfo.id}>
                                            <td>
                                                {parseFloat(
                                                    rateInfo.payRate,
                                                ).toFixed(2)}
                                            </td>
                                            <td>
                                                {util.date.getShort(
                                                    rateInfo.startDate,
                                                )}
                                            </td>
                                            <td>
                                                {util.date.getShort(
                                                    rateInfo.endDate,
                                                ) ?? 'Current'}
                                            </td>
                                            <td>
                                                {util.date.getShort(
                                                    rateInfo.rateReceivedDate,
                                                )}
                                            </td>
                                            <Can I="edit" a="employee">
                                                <CommonContext.Consumer>
                                                    {(context) => (
                                                        <td>
                                                            <Button
                                                                disabled={
                                                                    !!context.formIsOpen
                                                                }
                                                                type="button"
                                                                color="outline-primary"
                                                                onClick={() => onEditPayRate(
                                                                    rateInfo,
                                                                )}
                                                                size="sm"
                                                            >
                                Edit
                                                            </Button>
                                                        </td>
                                                    )}
                                                </CommonContext.Consumer>
                                            </Can>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </>
        );
    }
}

EmployeePayRateInfo.contextType = CommonContext;
