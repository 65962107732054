import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Progress } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import moment from 'moment';
import {
    createDataSource,
    createGridOptions,
    DataGrid,
    LinkCellRenderer,
    TextFilterDefaults,
    ConditionalEditLinkCellRenderer,
    indexCellRenderer,
    EmployeeAccountStatusRenderer,
} from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import {
    FlexEndRow, PageHeading, PageWrap, SmallButton,
} from '../common/forms/FormElements';
import { util } from '../Util';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import authService from '../api-authorization/AuthorizeService';
import { Can } from '../Can';
import { isComplianceAssociatedWithEmployee } from '../complianceType/ComplianceType';

class EmployeeIndex extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            selectedRow: null,
        };
        this.onAddEmployeeClicked = this.onAddEmployeeClicked.bind(this);
        this.onOnboardEmployeeClicked = this.onOnboardEmployeeClicked.bind(this);
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
        this.setState = (state, callback) => { };
    }

    onAddEmployeeClicked = () => this.props.history.push(`${AppNavPaths.EmployeeNew}`);

    onEditCompliance = (id) => this.props.history.push(`${AppNavPaths.EmployeeCompliances}?employeeId=${id}`);

    onOnboardEmployeeClicked = () => this.props.history.push({ pathname: `${AppNavPaths.EmployeeNew}`, search: '?newHire=true' });

    getInitialListFiltersFromCache(initialFilters) {
        const json = sessionStorage.getItem('employee_gridFilterSettings');

        if (json) {
            const obj = JSON.parse(json);

            initialFilters.groupId = obj.Group ? parseInt(obj.Group.value) : 0;
            initialFilters.state = obj['EmployeeLocations.EmployeeLocation.State'] ? obj['EmployeeLocations.EmployeeLocation.State'].value : '';
            initialFilters.dispatchId = obj['DispatchCompany.CompanyName'] ? parseInt(obj['DispatchCompany.CompanyName'].value) : 0;
        }

        return initialFilters;
    }

    nameRenderer(params) {
        if (!params.data) return null;
        const url = `${AppNavPaths.Employee}/${params.data.id}`;
        return (<Link className="site-link" to={url} replace>{`${params.data.lastName}, ${params.data.firstName}`}</Link>);
    }

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();

        if (isAuthenticated) {
            const [groups, accountStatuses, usStates, dispatchLocations, allEmployeeComplianceTypes] = await Promise.all([
                util.fetch.js(ApiRoutes.auth.groups()),
                util.fetch.js(ApiRoutes.employeeAccountStatuses.all()),
                util.fetch.js(ApiRoutes.typeAheads.USStates()),
                util.fetch.js(ApiRoutes.typeAheads.dispatchLocations()),
                util.fetch.js(ApiRoutes.complianceTypes.allEmployeeComplianceTypes()),
            ]);

            accountStatuses.unshift({
                id: -1,
                description: 'Active & New Hire',
            });

            const gridOptions = createGridOptions(this);
            const context = this;

            const anyComplianceAssociatedWithEmployee = (employee) => allEmployeeComplianceTypes
                .some((ect) => isComplianceAssociatedWithEmployee(ect, employee));

            const shouldWarn = (employee) => {
                const allConfiguredComplianceTypeIds = employee
                    .applicationUserCompliances
                    .map((c) => c.complianceTypeId);

                const upToDateComplianceTypeIds = employee
                    .applicationUserCompliances
                    .filter((c) => moment(new Date()).isBetween(c.effectiveDate, c.expirationDate || '2099-12-31'))
                    .map((c) => c.complianceTypeId);

                return allEmployeeComplianceTypes
                    .some((ect) => ect.warnWhenMissing
                        && allConfiguredComplianceTypeIds.includes(ect.id)
                        && !upToDateComplianceTypeIds.includes(ect.id)
                        && isComplianceAssociatedWithEmployee(ect, employee));
            };

            const hasAssociatedComplianceAndIsUpToDate = (employeeParam) => {
                if (!employeeParam || !employeeParam.data) {
                    return false;
                }

                const employee = employeeParam.data;

                if (!anyComplianceAssociatedWithEmployee(employee)) {
                    return {
                        show: false,
                        warn: false,
                    };
                }

                return {
                    show: true,
                    warn: shouldWarn(employee),
                };
            };

            let initialListFilters = {
                groupId: 0,
                state: '',
                dispatchId: 0,
            };

            initialListFilters = this.getInitialListFiltersFromCache(initialListFilters);

            const complianceDisabled = !this.context.tenant?.tenantSettings?.complianceEnabled;

            gridOptions.components = {
                selectFilter: DataGridSelectFilter,
                selectFloatingFilter: DataGridSelectFloatingFilter,
                nameRenderer: LinkCellRenderer,
                complianceAvailableRenderer: ConditionalEditLinkCellRenderer,
                employeeAccountStatusRenderer: EmployeeAccountStatusRenderer,
                indexCellRenderer,
            };

            const groupFilterParams = {
                suppressFilterButton: true,
                options: groups,
                optionsLabel: 'groupName',
                optionsValue: 'id',
                initialFilterValue: initialListFilters.groupId,
            };
            const statusFilterParams = {
                suppressFilterButton: true,
                options: accountStatuses,
                optionsLabel: 'description',
                optionsValue: 'id',
                initialFilterValue: -1,
            };
            const stateFilterParams = {
                suppressFilterButton: true,
                options: usStates,
                optionsLabel: 'label',
                optionsValue: 'value',
                initialFilterValue: initialListFilters.state,
            };
            const dispatchFilterParams = {
                suppressFilterButton: true,
                labelText: 'Filter by Dispatch',
                options: dispatchLocations,
                optionsLabel: 'label',
                optionsValue: 'value',
                initialFilterValue: initialListFilters.dispatchId,
            };

            gridOptions.columnDefs = [
                {
                    flex: 0,
                    width: 70,
                    minWidth: 70,
                    maxWidth: 70,
                    headerName: '',
                    valueGetter: 'node.id',
                    sortable: false,
                    cellRenderer: 'indexCellRenderer',
                },
                {
                    colId: 'EmployeeUser.User.UserName',
                    sortable: true,
                    headerName: 'Username',
                    field: 'userName',
                    tooltipField: 'userName',
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                    cellRenderer: 'nameRenderer',
                    cellRendererParams: {
                        to: (id) => `${AppNavPaths.Employee}/${id}`,
                        nameField: 'userName',
                        idField: 'id',
                        title: 'View this Employee',
                    },
                },
                {
                    colId: 'LastName',
                    sortable: true,
                    headerName: 'Last Name',
                    field: 'lastName',
                    tooltipField: 'lastName',
                    sort: { direction: 'asc', priority: 0 },
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                    cellRenderer: 'employeeAccountStatusRenderer',
                    cellRendererParams: {
                        nameField: 'lastName',
                    }
                },
                {
                    colId: 'FirstName',
                    sortable: true,
                    headerName: 'First Name',
                    field: 'firstName',
                    tooltipField: 'firstName',
                    sort: { direction: 'asc' },
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                    cellRenderer: 'employeeAccountStatusRenderer',
                    cellRendererParams: {
                        nameField: 'firstName',
                    }
                },
                {
                    colId: 'Group',
                    sortable: false,
                    headerName: 'Groups',
                    field: 'groupsFlat',
                    tooltipField: 'groupsFlat',
                    filter: 'selectFilter',
                    floatingFilter: true,
                    filterParams: groupFilterParams,
                    floatingFilterComponent: 'selectFloatingFilter',
                    floatingFilterComponentParams: groupFilterParams,
                },
                {
                    colId: 'associatedCompliances',
                    headerName: 'Associated Compliances',
                    hide: complianceDisabled,
                    cellRenderer: 'complianceAvailableRenderer',
                    valueGetter: hasAssociatedComplianceAndIsUpToDate,
                    cellRendererParams: {
                        clicked: this.onEditCompliance,
                        idField: 'id',
                        title: 'Update this Employee\'s Compliances',
                        entity: 'applicationusercompliance',
                        displayValues: [
                            { value: true, text: 'Manage' },
                            { value: false, text: '' },
                        ],
                    },
                },
                {
                    colId: 'EmployeeLocations.EmployeeLocation.City',
                    sortable: false,
                    sort: { direction: 'asc' },
                    headerName: 'City',
                    field: 'routingAddress.city',
                    tooltipField: 'routingAddress.city',
                    filter: 'agTextColumnFilter',
                    floatingFilter: true,
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                },
                {
                    colId: 'EmployeeLocations.EmployeeLocation.State',
                    sortable: false,
                    sort: { direction: 'asc' },
                    headerName: 'State',
                    field: 'routingAddress.state',
                    tooltipField: 'routingAddress.state',
                    flex: 0,
                    width: 140,
                    filter: 'selectFilter',
                    floatingFilter: true,
                    filterParams: stateFilterParams,
                    floatingFilterComponent: 'selectFloatingFilter',
                    floatingFilterComponentParams: stateFilterParams,
                },
                {
                    colId: 'DispatchCompany.CompanyName',
                    headerName: 'Dispatching',
                    sortable: true,
                    field: 'dispatchOfficeLocation',
                    tooltipField: 'dispatchOfficeLocation',
                    filter: 'selectFilter',
                    floatingFilter: true,
                    filterParams: dispatchFilterParams,
                    floatingFilterComponent: 'selectFloatingFilter',
                    floatingFilterComponentParams: dispatchFilterParams,
                },
                {
                    colId: 'Email',
                    sortable: true,
                    field: 'email',
                    tooltipField: 'email',
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                },
                {
                    colId: 'MobilePhone',
                    sortable: false,
                    field: 'mobilePhone',
                    tooltipField: 'mobilePhone',
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                },
                {
                    colId: 'AccountStatus.Description',
                    sortable: true,
                    field: 'accountStatus',
                    tooltipField: 'accountStatus',
                    filter: 'selectFilter',
                    floatingFilter: true,
                    filterParams: statusFilterParams,
                    floatingFilterComponent: 'selectFloatingFilter',
                    floatingFilterComponentParams: statusFilterParams,
                },
            ];

            /// ///////////////////////////////////////////////////////
            // RJP - Cache and reload filter and sort changes
            gridOptions.onFilterChanged = (params) => {
                const filterModel = gridOptions.api.getFilterModel();
                sessionStorage.setItem('employee_gridFilterSettings', JSON.stringify(filterModel));
            };

            gridOptions.onSortChanged = (params) => {
                const sortModel = gridOptions.api.columnModel.getColumnState();
                sessionStorage.setItem('employee_gridSortSettings', JSON.stringify(sortModel));
            };

            gridOptions.onFirstDataRendered = (params) => {
                const sortModel = sessionStorage.getItem('employee_gridSortSettings');
                if (sortModel) {
                    const obj = JSON.parse(sortModel);
                    gridOptions.api.setSortModel(obj);
                }
            };

            gridOptions.onGridReady = (params) => {
                const filterModel = sessionStorage.getItem('employee_gridFilterSettings');
                if (filterModel) {
                    const obj = JSON.parse(filterModel);

                    // Have to set individually here instead of using the whole model
                    // due to list types requiring a different initialization routine
                    if (obj['EmployeeUser.User.UserName']) {
                        const filterInstance = gridOptions.api.getFilterInstance('EmployeeUser.User.UserName');
                        filterInstance.setModel(obj['EmployeeUser.User.UserName']);
                        gridOptions.api.onFilterChanged();
                    }
                    if (obj.LastName) {
                        const filterInstance = gridOptions.api.getFilterInstance('LastName');
                        filterInstance.setModel(obj.LastName);
                        gridOptions.api.onFilterChanged();
                    }
                    if (obj.FirstName) {
                        const filterInstance = gridOptions.api.getFilterInstance('FirstName');
                        filterInstance.setModel(obj.FirstName);
                        gridOptions.api.onFilterChanged();
                    }
                    if (obj['EmployeeLocations.EmployeeLocation.City']) {
                        const filterInstance = gridOptions.api.getFilterInstance('EmployeeLocations.EmployeeLocation.City');
                        filterInstance.setModel(obj['EmployeeLocations.EmployeeLocation.City']);
                        gridOptions.api.onFilterChanged();
                    }
                    if (obj.Email) {
                        const filterInstance = gridOptions.api.getFilterInstance('Email');
                        filterInstance.setModel(obj.Email);
                        gridOptions.api.onFilterChanged();
                    }
                    if (obj.MobilePhone) {
                        const filterInstance = gridOptions.api.getFilterInstance('MobilePhone');
                        filterInstance.setModel(obj.MobilePhone);
                        gridOptions.api.onFilterChanged();
                    }
                }

                params.api.setDatasource(context.state.dataSource);
                context.setState({ gridApi: params.api });
            };
            // END - Cache and reload filter and sort changes
            /// //////////////////////////////////////////////////////

            const ds = createDataSource(ApiRoutes.employees.search(), gridOptions);

            this.setState((state) => ({
                loading: false,
                gridOptions,
                dataSource: ds,
            }));
        }
    }

    render() {
        if (!!this.state.loading || !this.context?.permissions || !this.context?.tenant?.tenantSettings) return (<Progress />);

        const { tenant } = { ...this.context };
        const { employeeOnboardingEnabled } = { ...tenant.tenantSettings };
        const { rowData, gridOptions } = { ...this.state };

        return (
            <PageWrap>
                <PageHeading>
                    <FontAwesomeIcon icon={faUser} className="mr-2 text-muted" />
                    <span>Employees</span>
                </PageHeading>
                <DataGridToolbar
                    entity="employee"
                    gridApi={this.state.gridApi}
                    dataSource={this.state.dataSource}
                    hideAdd
                    addIcon="fa-user-plus"
                    hideExcelButton
                    gridOptions={this.state.gridOptions}
                    serverExport={{ apiPath: ApiRoutes.employees.excelExport(), filePrefix: 'EmployeeSearch' }}
                    serverExportDisabled={!!this.state.saving || !!this.state.loading || !!this.state.loadingData}
                >
                    <Can I="create" a="employee">
                        <FlexEndRow>
                            <Can I="add" a="employee">
                                <SmallButton type="button" onClick={this.onAddEmployeeClicked}>
                                    <i className="fa fa-md mr-2 fa-user-plus" />
                  Add
                                </SmallButton>
                            </Can>
                            {
                                !!employeeOnboardingEnabled
                                && (
                                    <Can I="onboard" a="employee">
                                        <SmallButton type="button" onClick={this.onOnboardEmployeeClicked}>
                                            <i className="fa fa-md mr-2 fa-user-plus" />
                                    New Hire
                                        </SmallButton>
                                    </Can>
                                )
                            }
                        </FlexEndRow>
                    </Can>
                </DataGridToolbar>
                <DataGrid
                    domLayout="normal"
                    rowData={rowData}
                    gridOptions={gridOptions}
                    gridStatus={this.state.gridStatus}
                />
            </PageWrap>
        );
    }
}

export default withRouter(EmployeeIndex);
