import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import CacheBuster from './CacheBuster';

// Routing
import { Layout } from './components/Layout';

import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';

// Permissions
import { AbilityContext } from './components/Can';
import { ApiRoutes, AppNavPaths } from './components/Common';
import defineAbilityFor from './AppAbility';
import { util } from './components/Util';

// Style
import './style/App.scss';

// Components
import BillingIndex from './components/billing/BillingIndex';
import BillingIssuesIndex from './components/billingIssues/BillingIssuesIndex';
import BillingChargeCategoryIndex from './components/billingChargeCategory/BillingChargeCategoryIndex';
import BillingChargeIndex from './components/billingCharge/BillingChargeIndex';
import BillingRateIndex from './components/billing/BillingRateIndex';
import InvoiceIndex from './components/invoice/InvoiceIndex';
import InvoiceGenerationIndex from './components/invoiceGeneration/InvoiceGenerationIndex';
import ChargeViewIndex from './components/chargeView/ChargeViewIndex';
import ChargeTypeForm from './components/chargeType/ChargeTypeForm';
import ChargeTypeIndex from './components/chargeType/ChargeTypeIndex';
import ChargeTypeGroupForm from './components/chargeTypeGroup/ChargeTypeGroupForm';
import ChargeTypeGroupIndex from './components/chargeTypeGroup/ChargeTypeGroupIndex';
import ContractForm from './components/contracts/ContractForm';
import ChargeTypeUnitIndex from './components/chargeTypeUnit/ChargeTypeUnitIndex';
import CompanyBillingGroupIndex from './components/companyBillingGroup/CompanyBillingGroupIndex';
import CompanyBillingGroupForm from './components/companyBillingGroup/CompanyBillingGroupForm';
import PayScheduleIndex from './components/paySchedule/PayScheduleIndex';
import PayScheduleForm from './components/paySchedule/PayScheduleForm';
import ContractIndex from './components/contracts/ContractIndex';
import ContractStatusIndex from './components/contractStatus/ContractStatusIndex';
import EmployeeForm from './components/employee/EmployeeForm';
import EmployeeIndex from './components/employee/EmployeeIndex';
import EmployeeAccountStatusIndex from './components/employeeAccountStatus/EmployeeAccountStatusIndex';
import EquipmentIndex from './components/equipment/EquipmentIndex';
// import EquipmentStatusIndex from './components/equipmentStatus/EquipmentStatusIndex';
import EquipmentTypeIndex from './components/equipment/EquipmentTypeIndex';
// import EquipmentTypeStatusIndex from './components/equipmentTypeStatus/EquipmentTypeStatusIndex';
import GroupIndex from './components/group/GroupIndex';
import GroupForm from './components/group/GroupForm';
import UserGroupIndex from './components/userGroup/UserGroupIndex';
import UserGroupForm from './components/userGroup/UserGroupForm';
import RoleIndex from './components/role/RoleIndex';
import RoleForm from './components/role/RoleForm';
import PermissionIndex from './components/permission/PermissionIndex';
import PermissionForm from './components/permission/PermissionForm';
import JobIndex from './components/job/JobIndex';
import JobForm from './components/job/JobForm';
import OrganizationIndex from './components/organization/OrganizationIndex';
import OrganizationForm from './components/organization/OrganizationForm';
import LocationTypeIndex from './components/locationType/LocationTypeIndex';
import authService from './components/api-authorization/AuthorizeService';
import Dashboard from './Dashboard';
import DispatchBoard from './components/dispatch/DispatchBoard';
import AssignmentCalendar from './components/assignmentCalendar/AssignmentCalendar';
import TimeCharge from './components/timeCharge/TimeCharge';
import TimeOffRequest from './components/timeOffRequest/TimeOffRequest';
import Timesheet from './components/timesheet/TimesheetForm';
import MyTimesheets from './components/timesheet/MyTimesheets';
import TimesheetManagementIndex from './components/timesheetManagement/TimesheetManagementIndex';
import AssignmentConfirmation from './components/assignmentConfirmation/AssignmentConfirmation';
import CancelConfirmation from './components/cancelConfirmation/CancelConfirmation';
import PayrollIndex from './components/payroll/PayrollIndex';
import PayrollIndividualIndex from './components/payroll/PayrollIndividualIndex';
import PayrollManagementIndex from './components/payrollManagement/PayrollManagementIndex';
import NotFound from './components/api-authorization/NotFound';
import TenantIndex from './components/tenant/TenantIndex';
import TenantForm from './components/tenant/TenantForm';
import { ServerError } from './components/api-authorization/ServerError';
import { NotAuthorizedPage } from './components/status/StatusCodes';
import { NoPermission } from './components/api-authorization/NoPermission';
import ContractTemplateIndex from './components/contractTemplate/ContractTemplateIndex';
import ContractTemplateForm from './components/contractTemplate/ContractTemplateForm';
import DailyJobView from './components/job/DailyJobView';
import DailyRosterView from './components/employee/DailyRosterView';
import ComplianceTypeIndex from './components/complianceType/ComplianceTypeIndex';
import EquipmentComplianceIndex from './components/equipment/EquipmentComplianceIndex';
import EmployeeComplianceIndex from './components/employee/EmployeeComplianceIndex';
import SelfDispatchTimesheetForm from './components/timesheet/SelfDispatchTimesheetForm';
import ContactIndex from './components/contact/ContactIndex';
import ContactActivityIndex from './components/contactActivity/ContactActivityIndex';
import TimesheetDashboardIndex from './components/timesheetDashboard/TimesheetDashboardIndex';
import QuickBooksInventoryItemsIndex from './components/QuickBooksInventoryItems/QuickBooksInventoryItemsIndex';
import QuickBooksInventoryItemsForm from './components/QuickBooksInventoryItems/QuickBooksInventoryItemForm';
import LongTermStatusIndex from './components/longTermStatus/LongTermStatusIndex';
import DailyAttendanceEventIndex from './components/dailyAttendanceEvent/DailyAttendanceEventIndex';
import JobAssignmentCancellationTypeIndex from './components/jobAssignmentCancellationType/JobAssignmentCancellationTypeIndex';
import ActiveEmployeeListIndex from './components/employee/ActiveEmployeeListIndex';
import CollectedReportingIndex from './components/collectedReporting/CollectedReportingIndex';
import AdminToolIndex from './components/Admin/AdminToolIndex';
import MoveTimesheetToJobTool from './components/Admin/MoveTimesheetToJob';
import AddChargeTypeToTimesheetEquipment from './components/Admin/AddChargeTypeToTimesheetEquipment';
import SwapContractChargeType from './components/Admin/SwapContractChargeType';
import RejectBillingPayroll from './components/Admin/RejectBillingPayroll';
import AddJobCity from './components/Admin/AddJobCity';
import USCountyGroupsIndex from './components/uscounties/USCountyGroupsIndex';
import USCountyGroupForm from './components/uscounties/USCountyGroupForm';
import SyncTimesheetContractBilling from './components/Admin/SyncTimesheetContractBilling';
import RemoveCharges from './components/Admin/RemoveCharges';

class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);
        this.state = {
            permissions: [],
            tenant: {},
            ready: false,
        };

        this.layoutRef = React.createRef();
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();

        // https://stackoverflow.com/a/45373907
        this.unlisten = this.props.history.listen((location, action) => {
            if (this.layoutRef && this.layoutRef.current) {
                this.layoutRef.current.setFormOpened(false);
            }
        });
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
        this.unlisten();
    }

    checkUserHasPermission = (permission) => {
    // 2022-09-26 - M. Nicol - Refactored to reduce number of operations.

        const { permissions } = this.state;

        // Example: location.view -> ["location", "view"] -> category: location, action: view
        const [category, action] = (permission ?? '').split('.');

        if (!permissions || !category || !action) return false;

        const hasPerm = !!permissions.find(
            (p) => p.permission === category && p.action === action,
        );
        return hasPerm;
    };

    populateState = async () => {
        const isAuthenticated = await authService.isAuthenticated();

        // When auth'd, populate the permissions.
        // Used in ability permissions context app-wide, starting with Routes below.
        if (isAuthenticated) {
            const [permissions] = await Promise.all([
                util.fetch.js(ApiRoutes.auth.permissions()),
            ]);
            this.setState({ permissions, ready: true });
        } else {
            this.setState({ permissions: null, ready: true });
        }
    };

    render() {
        return (
            <CacheBuster>
                {({
                    loading,
                    isLatestVersion,
                    refreshCacheAndReload,
                    currentVersion,
                }) => {
                    if (loading) {
                        return null;
                    }

                    if (!loading && !isLatestVersion) {
                        refreshCacheAndReload(currentVersion);
                    }
                    const { ready, permissions } = this.state;
                    if (ready) {
                        return (
                            <AbilityContext.Provider
                                value={defineAbilityFor(permissions)}
                            >
                                <Layout
                                    permissions={permissions}
                                    permissionChecker={
                                        this.checkUserHasPermission
                                    }
                                    version={currentVersion}
                                >
                                    <Switch>
                                        <AuthorizeRoute
                                            exact
                                            path="/"
                                            component={Dashboard}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="selfdispatchtimesheet"
                                            action="create"
                                            path={`${AppNavPaths.SelfDispatchTimesheetNew}`}
                                            component={
                                                SelfDispatchTimesheetForm
                                            }
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="selfdispatchtimesheet"
                                            action="view"
                                            path={`${AppNavPaths.SelfDispatchTimesheet}/:id`}
                                            component={
                                                SelfDispatchTimesheetForm
                                            }
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="timesheet"
                                            action="view"
                                            path={`${AppNavPaths.EmployeeTimesheets}`}
                                            component={MyTimesheets}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="nonflaggingtimesheet"
                                            action="create"
                                            path={`${AppNavPaths.NonFlaggingTimesheetNew}`}
                                            component={
                                                SelfDispatchTimesheetForm
                                            }
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="nonflaggingtimesheet"
                                            action="view"
                                            path={`${AppNavPaths.NonFlaggingTimesheet}/:id`}
                                            component={
                                                SelfDispatchTimesheetForm
                                            }
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="billing"
                                            action="view"
                                            path={`${AppNavPaths.Billing}`}
                                            component={BillingIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="billing"
                                            action="view"
                                            path={`${AppNavPaths.BillingChargeCategory}`}
                                            component={
                                                BillingChargeCategoryIndex
                                            }
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="billing"
                                            action="view"
                                            path={`${AppNavPaths.BillingCharge}`}
                                            component={BillingChargeIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="billing"
                                            action="view"
                                            path={`${AppNavPaths.BillingRate}`}
                                            component={BillingRateIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="chargetype"
                                            action="view"
                                            path={`${AppNavPaths.ChargeType}/:id`}
                                            component={ChargeTypeForm}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="chargetype"
                                            action="create"
                                            path={`${AppNavPaths.ChargeTypeNew}`}
                                            component={ChargeTypeForm}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="chargetype"
                                            action="view"
                                            path={`${AppNavPaths.ChargeTypes}`}
                                            component={ChargeTypeIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="chargetypeunit"
                                            action="view"
                                            path={`${AppNavPaths.ChargeTypeUnits}`}
                                            component={ChargeTypeUnitIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="chargetype"
                                            action="create"
                                            path={`${AppNavPaths.ChargeTypeGroupNew}`}
                                            component={ChargeTypeGroupForm}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="chargetype"
                                            action="view"
                                            path={`${AppNavPaths.ChargeTypeGroup}/:id`}
                                            component={ChargeTypeGroupForm}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="chargetype"
                                            action="view"
                                            path={`${AppNavPaths.ChargeTypeGroups}`}
                                            component={ChargeTypeGroupIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="applicationusercompliance"
                                            action="view"
                                            path={`${AppNavPaths.EmployeeCompliances}`}
                                            component={EmployeeComplianceIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="equipmentcompliance"
                                            action="view"
                                            path={`${AppNavPaths.EquipmentCompliances}`}
                                            component={EquipmentComplianceIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="compliancetype"
                                            action="view"
                                            path={`${AppNavPaths.ComplianceType}`}
                                            component={ComplianceTypeIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="longtermstatus"
                                            action="view"
                                            path={`${AppNavPaths.LongTermStatuses}`}
                                            component={LongTermStatusIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="dailyattendanceevent"
                                            action="view"
                                            path={`${AppNavPaths.DailyAttendanceEvents}`}
                                            component={
                                                DailyAttendanceEventIndex
                                            }
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="location_contact"
                                            action="view"
                                            path={`${AppNavPaths.Contacts}`}
                                            component={ContactIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="contract"
                                            action="view"
                                            path={`${AppNavPaths.Contract}/:id`}
                                            component={ContractForm}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="contract"
                                            action="create"
                                            path={`${AppNavPaths.ContractNew}`}
                                            component={ContractForm}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="contract"
                                            action="view"
                                            path={`${AppNavPaths.Contracts}`}
                                            component={ContractIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="contract_template"
                                            action="view"
                                            path={`${AppNavPaths.ContractTemplate}/:id`}
                                            component={ContractTemplateForm}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="contract_template"
                                            action="view"
                                            path={`${AppNavPaths.ContractTemplateIndex}`}
                                            component={ContractTemplateIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="contract_template"
                                            action="create"
                                            path={`${AppNavPaths.ContractTemplateNew}`}
                                            component={ContractTemplateForm}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="contractstatus"
                                            action=""
                                            path={`${AppNavPaths.ContractStatuses}`}
                                            component={ContractStatusIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="employee"
                                            action="view"
                                            path={`${AppNavPaths.Employee}/:id`}
                                            component={EmployeeForm}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="employee"
                                            action="create"
                                            path={`${AppNavPaths.EmployeeNew}`}
                                            component={EmployeeForm}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="employee"
                                            action="view"
                                            path={`${AppNavPaths.Employees}`}
                                            component={EmployeeIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="employee_daily_roster"
                                            action="view"
                                            path={`${AppNavPaths.EmployeeDailyRoster}`}
                                            component={DailyRosterView}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="employeeaccountstatus"
                                            action="view"
                                            path={`${AppNavPaths.EmployeeAccountStatuses}`}
                                            component={
                                                EmployeeAccountStatusIndex
                                            }
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="equipment"
                                            action="view"
                                            path={`${AppNavPaths.Equipment}`}
                                            component={EquipmentIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="equipmenttype"
                                            action="view"
                                            path={`${AppNavPaths.EquipmentTypes}`}
                                            component={EquipmentTypeIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="group"
                                            action="view"
                                            path={`${AppNavPaths.Groups}`}
                                            component={GroupIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="group"
                                            action="view"
                                            path={`${AppNavPaths.Group}/:id`}
                                            component={GroupForm}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="group"
                                            action="create"
                                            path={`${AppNavPaths.GroupNew}`}
                                            component={GroupForm}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="invoice"
                                            action="view"
                                            path={`${AppNavPaths.Invoice}`}
                                            component={InvoiceIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="invoice"
                                            action="view"
                                            path={`${AppNavPaths.InvoiceGeneration}`}
                                            component={InvoiceGenerationIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="billing_issues"
                                            action="view"
                                            path={`${AppNavPaths.BillingIssues}`}
                                            component={BillingIssuesIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="user_group"
                                            action="view"
                                            path={`${AppNavPaths.UserGroups}`}
                                            component={UserGroupIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="user_group"
                                            action="view"
                                            path={`${AppNavPaths.UserGroup}/:id`}
                                            component={UserGroupForm}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="user_group"
                                            action="create"
                                            path={`${AppNavPaths.UserGroupNew}`}
                                            component={UserGroupForm}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="role"
                                            action="view"
                                            path={`${AppNavPaths.Roles}`}
                                            component={RoleIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="role"
                                            action="view"
                                            path={`${AppNavPaths.Role}/:id`}
                                            component={RoleForm}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="role"
                                            action="create"
                                            path={`${AppNavPaths.RoleNew}`}
                                            component={RoleForm}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="permission"
                                            action="view"
                                            path={`${AppNavPaths.Permissions}`}
                                            component={PermissionIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="permission"
                                            action="view"
                                            path={`${AppNavPaths.Permission}/:id`}
                                            component={PermissionForm}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="permission"
                                            action="create"
                                            path={`${AppNavPaths.PermissionNew}`}
                                            component={PermissionForm}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="job"
                                            action="view"
                                            path={`${AppNavPaths.Jobs}`}
                                            component={JobIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="job"
                                            action="view"
                                            path={`${AppNavPaths.Job}/:id`}
                                            component={JobForm}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="job"
                                            action="create"
                                            path={`${AppNavPaths.JobNew}/:contractId`}
                                            component={JobForm}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="job_daily_view"
                                            action="view"
                                            path={`${AppNavPaths.JobView}`}
                                            component={DailyJobView}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="location"
                                            action="view"
                                            path={`${AppNavPaths.Organizations}`}
                                            component={OrganizationIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="location"
                                            action="view"
                                            path={`${AppNavPaths.Organization}/:id`}
                                            component={OrganizationForm}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="location"
                                            action="create"
                                            path={`${AppNavPaths.OrganizationNew}`}
                                            component={OrganizationForm}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="locationtype"
                                            action="view"
                                            path={`${AppNavPaths.LocationTypes}`}
                                            component={LocationTypeIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="dispatch"
                                            action="edit"
                                            path={`${AppNavPaths.Dispatch}`}
                                            component={DispatchBoard}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="timesheet"
                                            action="view"
                                            path={`${AppNavPaths.AssignmentCalendar}`}
                                            component={AssignmentCalendar}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="timesheet"
                                            action="view"
                                            path={`${AppNavPaths.AssignmentConfirmation}/:id`}
                                            component={AssignmentConfirmation}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="timesheet"
                                            action="view"
                                            path={`${AppNavPaths.CancelConfirmation}/:id`}
                                            component={CancelConfirmation}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="dispatch_review"
                                            action="view"
                                            path={`${AppNavPaths.TimesheetManagement}`}
                                            component={TimesheetManagementIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="charge_view"
                                            action="view"
                                            path={`${AppNavPaths.ChargeView}`}
                                            component={ChargeViewIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="tenant"
                                            action="edit"
                                            path={`${AppNavPaths.Tenants}`}
                                            component={TenantIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="tenant"
                                            action="edit"
                                            path={`${AppNavPaths.Tenant}/:id`}
                                            component={TenantForm}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="timesheet"
                                            action="view"
                                            path={[`${AppNavPaths.Timesheet}/:id`, `${AppNavPaths.Timesheet}/:id/:assignmentId`]}
                                            component={Timesheet}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="timesheet"
                                            action="view"
                                            path={`${AppNavPaths.TimeCharges}`}
                                            component={TimeCharge}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="timesheet"
                                            action="view"
                                            path={`${AppNavPaths.TimeOffRequests}`}
                                            component={TimeOffRequest}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="timesheet"
                                            action="view"
                                            path={`${AppNavPaths.TimeSheetDashboard}`}
                                            component={TimesheetDashboardIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="payroll"
                                            action="view"
                                            path={`${AppNavPaths.Payroll}`}
                                            component={PayrollIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="payroll"
                                            action="viewindividual"
                                            path={`${AppNavPaths.PayrollIndividual}`}
                                            component={PayrollIndividualIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="payroll"
                                            action="view"
                                            path={`${AppNavPaths.PayrollManagement}`}
                                            component={PayrollManagementIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="quickBooksInventoryItem"
                                            action="create"
                                            path={`${AppNavPaths.QuickBooksInventoryItem}`}
                                            component={
                                                QuickBooksInventoryItemsForm
                                            }
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="quickBooksInventoryItem"
                                            action="view"
                                            path={`${AppNavPaths.QuickBooksInventoryItem}/:id`}
                                            component={
                                                QuickBooksInventoryItemsForm
                                            }
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="quickBooksInventoryItem"
                                            action="view"
                                            path={`${AppNavPaths.QuickBooksInventoryItems}`}
                                            component={
                                                QuickBooksInventoryItemsIndex
                                            }
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="contactactivity"
                                            action="view"
                                            path={`${AppNavPaths.ContactActivities}`}
                                            component={ContactActivityIndex}
                                        />
                                        <Route
                                            exact
                                            path={AppNavPaths.Unauthorized}
                                            component={NotAuthorizedPage}
                                        />
                                        <Route
                                            path={
                                                ApplicationPaths.ApiAuthorizationPrefix
                                            }
                                            component={ApiAuthorizationRoutes}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            path={`${AppNavPaths.NotFound}`}
                                            component={NotFound}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            path={`${AppNavPaths.ServerError}`}
                                            component={ServerError}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            path={`${AppNavPaths.NoPermission}`}
                                            component={NoPermission}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="jobassignmentcancellationtype"
                                            action="view"
                                            path={`${AppNavPaths.JobAssignmentCancellationTypes}`}
                                            component={
                                                JobAssignmentCancellationTypeIndex
                                            }
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="jobassignmentcancellationtype"
                                            action="view"
                                            path={`${AppNavPaths.CompanyBillingGroup}`}
                                            component={CompanyBillingGroupIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="jobassignmentcancellationtype"
                                            action="create"
                                            path={`${AppNavPaths.CompanyBillingGroupNew}/:id`}
                                            component={CompanyBillingGroupForm}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="jobassignmentcancellationtype"
                                            action="create"
                                            path={`${AppNavPaths.CompanyBillingGroupNew}`}
                                            component={CompanyBillingGroupForm}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="jobassignmentcancellationtype"
                                            action="view"
                                            path={`${AppNavPaths.PaySchedule}`}
                                            component={PayScheduleIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="jobassignmentcancellationtype"
                                            action="create"
                                            path={`${AppNavPaths.PayScheduleNew}/:id`}
                                            component={PayScheduleForm}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="jobassignmentcancellationtype"
                                            action="create"
                                            path={`${AppNavPaths.PayScheduleNew}`}
                                            component={PayScheduleForm}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="active_employee_list"
                                            action="view"
                                            path={AppNavPaths.ActiveEmployees}
                                            component={ActiveEmployeeListIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="collected_reporting"
                                            action="view"
                                            path={
                                                AppNavPaths.CollectedReporting
                                            }
                                            component={CollectedReportingIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="admintool"
                                            action="view"
                                            path={`${AppNavPaths.AdminTool}`}
                                            component={AdminToolIndex}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="admintool"
                                            action="view"
                                            path={`${AppNavPaths.AdminTool}/1`}
                                            component={MoveTimesheetToJobTool}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="admintool"
                                            action="view"
                                            path={`${AppNavPaths.AdminTool}/4`}
                                            component={AddChargeTypeToTimesheetEquipment}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="admintool"
                                            action="view"
                                            path={`${AppNavPaths.AdminTool}/5`}
                                            component={AddJobCity}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="admintool"
                                            action="view"
                                            path={`${AppNavPaths.AdminTool}/6`}
                                            component={RejectBillingPayroll}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="admintool"
                                            action="view"
                                            path={`${AppNavPaths.AdminTool}/7`}
                                            component={SwapContractChargeType}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="admintool"
                                            action="view"
                                            path={`${AppNavPaths.AdminTool}/8`}
                                            component={SyncTimesheetContractBilling}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="admintool"
                                            action="view"
                                            path={`${AppNavPaths.AdminTool}/9`}
                                            component={RemoveCharges}
                                        />
                                        <AuthorizeRoute
                                            exact
                                            perm="county_group"
                                            action="view"
                                            path={`${AppNavPaths.USCountyGroups}`}
                                            component={USCountyGroupsIndex}
                                        />

                                        <AuthorizeRoute
                                            exact
                                            perm="county_group"
                                            action="create"
                                            path={`${AppNavPaths.USCountyGroupNew}`}
                                            component={USCountyGroupForm}
                                        />

                                        <AuthorizeRoute
                                            exact
                                            perm="county_group"
                                            action="edit"
                                            path={`${AppNavPaths.USCountyGroup}/:id`}
                                            component={USCountyGroupForm}
                                        />

                                        <AuthorizeRoute component={NotFound} />
                                    </Switch>
                                </Layout>
                            </AbilityContext.Provider>
                        );
                    }
                    return <div />;
                }}
            </CacheBuster>
        );
    }
}

export default withRouter(App);
